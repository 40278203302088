import { Field } from "formik";
import PropTypes from "prop-types";
import React from "react";
import { MySelect } from "../../_components";
import { formEnum } from "../../_helpers/formEnum";
import { quantityUnitsEnum } from "../../_helpers/quantityUnits";

export const StrengthAndForm = ({
  setCustomMed,
  strengthOptions,
  customMed,
  values,
  setFieldTouched,
  setFieldValue,
  errors,
  touched,
  disableFields,
}) => {
  const [fieldDisabled, setFieldDisabled] = React.useState(disableFields);
  function onChangeStrength(e, setFieldValue) {
    setFieldValue("strength", e.target.value);
    setCustomMed(true);
    setFieldDisabled(false);
  }

  return (
    <div className="form-group">
      {/* For custom med - if no strengths are available in values.medName then it is custom */}
      {strengthOptions?.length || !customMed ? (
        <MySelect
          name="strengthAndForm"
          label={
            <h6 className="mb-0">
              Strength and Form <span className="text-danger">*</span>
            </h6>
          }
          value={values.strengthAndForm}
          onChange={(name, val) => {
            const isNewStrength = values.strengthAndForm?.value !== val?.value;

            if (isNewStrength) {
              setFieldValue(name, val);
              setFieldDisabled(false);

              const quantityUnits = quantityUnitsEnum.map((unit) => ({
                value: unit.value,
                label: unit.abbr,
                description: unit.label,
              }));
              if (val?.preferredQuantityUnit) {
                setFieldValue(
                  "doseType",
                  quantityUnits.find(
                    (unit) => unit.value === val.preferredQuantityUnit
                  )
                );
              } else if (val?.formId) {
                let selectedForm = formEnum.find(
                  (form) => form.value === val.formId
                );
                if (selectedForm?.preferredUnitId) {
                  setFieldValue(
                    "doseType",
                    quantityUnits.find(
                      (unit) => unit.value === selectedForm.preferredUnitId
                    )
                  );
                }
              } else {
                setFieldValue("doseType", null);
              }
            }
          }}
          onBlur={setFieldTouched}
          error={errors.strengthAndForm}
          touched={touched.strengthAndForm}
          disabled={
            !values.medName || (fieldDisabled && values.strengthAndForm)
          }
          placeholder="Med Strength and Form"
          options={strengthOptions}
        />
      ) : (
        <div className="form-row">
          <div className="form-group col-4 pr-0">
            <label htmlFor="strength">
              Strength <span className="text-danger">*</span>
            </label>
            <Field
              id="strength"
              name="strength"
              onChange={(e) => onChangeStrength(e, setFieldValue)}
              onBlur={setFieldTouched}
              readOnly={fieldDisabled && values.strength}
              type="number"
              min={0}
              className="form-control"
            ></Field>
          </div>
          <div className="form-group col-3 mr-6 mt-1">
            <MySelect
              name="unit"
              value={values.unit}
              onChange={(name, val) => {
                setFieldValue(name, val);
                setFieldDisabled(false);
                setFieldValue("doseType", val);
              }}
              disabled={fieldDisabled && values.unit}
              onBlur={setFieldTouched}
              error={errors.unit}
              touched={touched.unit}
              options={quantityUnitsEnum.map((unit) => ({
                value: unit.value,
                label: unit.abbr,
                description: unit.label,
              }))}
              label=" "
              placeholder=""
            />
          </div>

          <div className="form-group col-4 pr-0">
            <MySelect
              name="form"
              value={values.form}
              onChange={(name, val) => {
                setFieldValue(name, val);
                setFieldDisabled(false);
              }}
              disabled={fieldDisabled && values.form}
              onBlur={setFieldTouched}
              error={errors.form}
              touched={touched.form}
              options={formEnum}
              label={
                <h6 className="mb-0">
                  Form <span className="text-danger">*</span>
                </h6>
              }
              placeholder=""
            />
          </div>
        </div>
      )}
    </div>
  );
};

StrengthAndForm.propTypes = {
  setCustomMed: PropTypes.func,
  strengthOptions: PropTypes.array,
  customMed: PropTypes.bool,
  values: PropTypes.object,
  setFieldTouched: PropTypes.func,
  setFieldValue: PropTypes.func,
  errors: PropTypes.object,
  touched: PropTypes.object,
  disableFields: PropTypes.bool,
};
