import classnames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import {
  CustomInput,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  InputGroup
} from "reactstrap";
import { ReactComponent as ChevronDown } from "../images/chevron_down_small.svg";
import PropTypes from "prop-types";

const CustomCreatableDropdown = ({
  id,
  name,
  value,
  onChange,
  placeholder,
  options,
  disabled,
  prn,
  setPRN,
  setFieldValue,
  frequencyPrn,
  onOpen,
  classname,
  onSelect,
  createable,
  noSaveButton
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [selected, setSelected] = useState(value); // [value, setValue
  const [selectedOption, setSelectedOption] = useState(null);
  const [tempPRN, setTempPRN] = useState(prn);
  const [custom, setCustom] = useState("");
  const [inputVal, setInputVal] = useState("");
  const toggle = () => {
    if (!dropdownOpen && onOpen) onOpen();

    setDropdownOpen((prevState) => !prevState);
  };

  //   if click outside of dropdown, close dropdown
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        document.getElementById(name) &&
        !document.getElementById(name).contains(event.target) &&
        document.getElementById(id) &&
        !document.getElementById(id).contains(event.target)
      ) {
        setDropdownOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [id, name]);

  const handleKeyDown = (e, option) => {
    // if the key is enter
    if (e.key === "Enter")
      // simulate click on custom input when user presses enter
      document.getElementById(option.label).click();
  };

  const handleInputClick = (e) => {
    e.stopPropagation();
    if (!dropdownOpen) {
      setDropdownOpen(true);
    }
  };

  const handleRadioChange = (info, option) => {
    // if prn reason, set prn to true
    if (name === "prnReason") {
      setTempPRN(true);
    }
    setSelectedOption(option);
    if (onSelect) {
      onSelect(option);
      // close dropdown when user selects an option
      toggle();
    }
  };
  const searchReasons = (e) => {
    const inputValue = e.target.value.trim().toLowerCase();

    setInputVal(inputValue);
    setSelected(null);
    setCustom("");

    // Filter the options and headers based on the input value when the user types
    const filtered = options.reduce((acc, section) => {
      const sectionLabel = section.label.toLowerCase();
      const sectionOptions = section.options.filter(
        (option) =>
          option.label.toLowerCase().includes(inputValue) ||
          option.description?.toLowerCase().includes(inputValue)
      );

      if (sectionOptions.length > 0) {
        acc.push({
          label: sectionLabel,
          options: sectionOptions
        });
      }

      return acc;
    }, []);

    setFilteredOptions(filtered);

    // If the current value is not in the filtered options, set value to null
    // if (
    //   !filtered.find((section) =>
    //     section.options.find((option) => option.label === value?.label)
    //   )
    // ) {
    //   if (onChange) onChange(name, { value: 0, label: "" });
    // }

    // If the custom value is not in the filtered options, add it
    if (
      createable &&
      inputValue !== "" &&
      !filtered.find((section) =>
        section.options.find(
          (option) => option.label.toLowerCase() === inputValue
        )
      )
    ) {
      setFilteredOptions((state) => [
        ...state,
        {
          label: "Custom",
          options: [{ label: inputValue, value: inputValue }]
        }
      ]);
    }
  };

  //   if !custom set filtered options to options
  useEffect(() => {
    if (!custom) {
      setFilteredOptions(options);
    }
  }, [custom, options]);

  const handleSave = () => {
    if (name === "prnReason") {
      setPRN(tempPRN);
      if (!tempPRN) {
        setCustom("");
        onChange(name, undefined);
        setFieldValue("prnReasonOther", null);
        toggle();
        return;
      }
    }
    onChange(name, selectedOption);

    // if label and value are the same, this is a custom reason, set prnReasonOther to the label
    if (selectedOption.value === selectedOption.label && name === "prnReason") {
      setFieldValue("prnReasonOther", selectedOption.label);
    } else if (name === "prnReason") {
      setFieldValue("prnReasonOther", null);
    }
    toggle();
  };

  useEffect(() => {
    let label = "";

    // else set selected to value?.label
    if (value?.label) {
      label += value?.label;
    }
    if (value && name.startsWith("frequency") && value?.description) {
      label += ` (${value?.description})`;
    }
    if (!dropdownOpen) {
      setSelected(label);
      setInputVal("");
      setFilteredOptions(options);
    }
    if (!value && !prn) setSelectedOption(null);
  }, [dropdownOpen, name, options, placeholder, prn, value]);

  const isSaveBtnDisabled = useMemo(() => {
    if (name === "prnReason") {
      return tempPRN === prn && selectedOption?.value === value?.value;
    }
    if (selectedOption?.value === value?.value) {
      return true;
    }
    if (selectedOption) {
      return !selectedOption?.value;
    }
    return !selectedOption;
  }, [selectedOption, prn, tempPRN, value, name]);

  // This will update the selectedOption when the dropdown is open
  useEffect(() => {
    setSelectedOption(value);
    if (onSelect) onSelect(value);
    setTempPRN(prn);
  }, [value, prn, onSelect]);

  return (
    <div className={classnames("position-relative")}>
      <Dropdown
        isOpen={dropdownOpen}
        toggle={() => {}}
        disabled={disabled}
        direction="down"
      >
        <DropdownToggle
          tag="span"
          data-toggle="dropdown"
          data-testid={`${name}-toggle`}
          aria-expanded={dropdownOpen}
          id={id}
          tabIndex="0"
          className={classnames(
            {disabled: disabled},
            "dropdown-indicator form-control custom-dropdown d-flex align-items-center p-0"
          )}
          onClick={() => {
            toggle();
            // focus on input
            if (document.getElementById(`${id}-input`))
              document.getElementById(`${id}-input`).focus();
            // set the input value to "" when dropdown is opened
          }}
          onKeyDown={(e) => {
            // if key is enter or down arrow toggle dropdown
            if (e.key === "Enter") {
              toggle();
            }
            //   if menu is closed and down arrow is pressed, open dropdown
            if (!dropdownOpen && e.key === "ArrowDown") {
              toggle();
            }
          }}
        >
          <input
            type="text"
            placeholder={placeholder}
            onChange={searchReasons}
            value={selected || inputVal}
            className={classnames("overflow-hidden")}
            id={`${id}-input`}
            onClick={handleInputClick}
            disabled={disabled}
          />{" "}
          <ChevronDown
            className="ml-neg-1-5rem glyph-dark"
            aria-label="chevron down"
          />
        </DropdownToggle>

        <DropdownMenu
          id={name}
          data-testid={name}
          className={classname || name}
          right={name === "prnReason"}
        >
          {name === "prnReason" && (
            <InputGroup className="d-flex flex-column py-1">
              <DropdownItem
                tag="span"
                className="px-3 py-0"
                onKeyDown={(e) => {
                  // if the key is enter or shift
                  if (e.key === "Enter" || e.key === "Shift")
                    // simulate click on custom input when user presses enter
                    document.getElementById("scheduledMed").click();
                }}
              >
                <CustomInput
                  id="scheduledMed"
                  tabIndex={0}
                  name="prn"
                  type="radio"
                  label="No"
                  onChange={(e) => {
                    setSelectedOption(null);
                    setTempPRN(false);
                  }}
                  disabled={frequencyPrn}
                  checked={!tempPRN}
                />
              </DropdownItem>
              <DropdownItem
                tag="span"
                className="px-3 py-0"
                onKeyDown={(e) => {
                  // if the key is enter or shift
                  if (e.key === "Enter" || e.key === "Shift")
                    // simulate click on custom input when user presses enter
                    document.getElementById("prnMed").click();
                }}
              >
                <CustomInput
                  id="prnMed"
                  tabIndex={0}
                  name="prn"
                  type="radio"
                  label="PRN"
                  onChange={(e) => {
                    setSelectedOption({ value: 0, label: "" });
                    setTempPRN(true);
                    setCustom("");
                  }}
                  checked={tempPRN}
                />
              </DropdownItem>
            </InputGroup>
          )}
          <div className="scrollable-dropdown-menu w-100">
            {filteredOptions.map((category, idx) => (
              <div key={category?.label}>
                <DropdownItem header className="text-secondary small px-3 pt-4">
                  {category?.label.toUpperCase()}
                </DropdownItem>
                <div>
                  <InputGroup className="d-flex flex-column py-1">
                    {category.options?.map((option, idx) => {
                      return (
                        <DropdownItem
                          tag="span"
                          key={option.value + option.label}
                          className="px-3 py-0 text-wrap"
                          onKeyDown={(e) => handleKeyDown(e, option)}
                        >
                          <CustomInput
                            type="radio"
                            id={`${id}-${option.label}`}
                            data-testid={`${id}-${option.label}`}
                            name={name}
                            value={option.label}
                            onChange={(e) => handleRadioChange(e, option)}
                            label={
                              name.startsWith("frequency") &&
                              option?.description
                                ? `${option.label} (${option.description})`
                                : option.label
                            }
                            checked={
                              // if dropdown is open, temproarily set selectedOption to show radio button checked otherwise we use saved value
                              dropdownOpen
                                ? selectedOption?.value === option?.value
                                : value?.value === option?.value &&
                                  (prn || name !== "prnReason")
                            }
                          ></CustomInput>
                        </DropdownItem>
                      );
                    })}
                  </InputGroup>
                </div>
              </div>
            ))}
            {!filteredOptions.length ? (
              <DropdownItem
                className={classnames("px-3 py-3 text-dark")}
                disabled
              >
                No results match your search
              </DropdownItem>
            ) : null}
          </div>
          <div className="border-top d-flex justify-content-around pt-3">
            {/* add dropdown item clear button to clear the form */}

            <DropdownItem
              className="btn-link text-secondary btn-dropdown  mr-4"
              disabled={!selectedOption}
              onClick={() => {
                setSelectedOption(null);
                setTempPRN(false);
                setCustom("");
                if (onChange) onChange(name, undefined);
                else if (onSelect) onSelect(undefined);
                if (selected) toggle();
              }}
            >
              Clear
            </DropdownItem>

            {!noSaveButton && (
              <DropdownItem
                className="btn-primary btn btn-dropdown text mx-4"
                onClick={handleSave}
                data-testid="save-button"
                disabled={isSaveBtnDisabled}
              >
                Save
              </DropdownItem>
            )}
          </div>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

CustomCreatableDropdown.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number
  ]),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  prn: PropTypes.bool,
  setPRN: PropTypes.func,
  setFieldValue: PropTypes.func,
  frequencyPrn: PropTypes.bool,
  onOpen: PropTypes.func,
  onSelect: PropTypes.func,
  classname: PropTypes.string,
  createable: PropTypes.bool,
  noSaveButton: PropTypes.bool
};

export { CustomCreatableDropdown };
