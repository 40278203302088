import {
  AnnotationDirective,
  AnnotationsDirective,
  Category,
  ChartAnnotation,
  ChartComponent,
  DataLabel,
  Inject,
  Legend,
  RangeColorSettingDirective,
  RangeColorSettingsDirective,
  ScatterSeries,
  SeriesCollectionDirective,
  SeriesDirective,
  StripLine,
  Tooltip,
} from "@syncfusion/ej2-react-charts";
import React, { useEffect, useState } from "react";
import { UseTabletMediaQuery } from "../_helpers";
import PropTypes from "prop-types";
import classnames from "classnames";

const gradientStyle = {
  height: "12px",
  background: "linear-gradient(to right, #d73838, #fcb422, #8bce00)",
  marginTop: "38px",
};

function CreateArrowAnnotation() {
  return <div className="text-secondary mt-3 font-size-normal">▼</div>;
}

function GradientStripLine() {
  return (
    <div style={gradientStyle}>
      <div className="d-flex justify-content-between text-secondary pt-3 small font-weight-bold">
        <span>0%</span>
        <span>100%</span>
      </div>
    </div>
  );
}
const AdherenceChart = ({ data, average }) => {
  const [chartData, setChartData] = useState([]);
  const [chartHeight, setChartHeight] = useState(0);
  const [leftMargin, setLeftMargin] = useState(0);

  useEffect(() => {
    if (average < 80) {
      setLeftMargin(average);
    } else setLeftMargin(80);
  }, [average]);
  const is768Width = UseTabletMediaQuery();

  function chartTemplate(args) {
    let className = "";
    if (args.point.colorValue > 95) {
      className = "ml-neg-42px";
    } else if (args.point.y < 6 && args.point.y >= 1) {
      className = "padding-0-0-0-30px";
    } else if (parseInt(args.point.y) > average) {
      className = "padding-0-0-0-42px";
    } else {
      className = "padding-0-45-0-5px";
    }

    return (
      <div id="templateWrap" className={className}>
        <div>{parseInt(args.point.y)}%</div>
      </div>
    );
  }
  const template = chartTemplate;
  const marker = {
    width: 10,
    height: 10,
    visible: true,
    // fill: "Red",
    dataLabel: {
      visible: true,

      template: template,
      position: "Middle",
      font: {
        size: "14px",
        fontFamily: "Effra",
        color: "#1f2f36",
      },
    },
  };
  let gradient = GradientStripLine;

  // Ignore Sonar Cloud recommendations about this. This needs to be like this in order to show the text annotation properly.
  // With your change it was not showing the average.
  let arrowAnnotation = CreateArrowAnnotation;

  const containsMoreThanOneObjectWithLabel = (array, label) => {
    let count = 0;
    for (const object of array) {
      if (object.name === label) {
        count++;
      }
    }
    return count > 1;
  };
  useEffect(() => {
    setChartHeight(parseInt(data.length * 70));
  }, [data]);

  useEffect(() => {
    setChartData([]);
    data
      .sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return 1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      })
      .forEach((element, idx) => {
        //Preparing text for chart - setting length and also adding asterisk for paused/deleted meds
        let text;
        if (is768Width && element.name.length > 12)
          text = element.name.substring(0, 12) + "...";
        else if (element.name.length > 22)
          text = element.name.substring(0, 22) + "...";
        else text = element.name;
        // if text is already a label in the chartData array, add element.strength
        if (
          containsMoreThanOneObjectWithLabel(data, text) &&
          element.strength
        ) {
          text += ", " + element.strength?.substring(0, 8);
        }

        if (!element.isActive) {
          text += "*";
        }

        const adhChartValue = Math.round(
          (element.medicationsTaken /
            (element.medicationsTaken +
              element.medicationsSkipped +
              element.medicationsMissed)) *
            100
        );
        //Updating state array with the averages and index for each med
        setChartData((state) => [
          ...state,
          { value: adhChartValue || 0.001, label: text },
        ]);
      });
  }, [data, is768Width]);

  return (
    <div className="pt-4 pl-6">
      <div
        className={classnames({}, "text-secondary small mb-neg-10px")}
        style={{ marginLeft: `${leftMargin}%` }}
      >
        {average}% Average Adherence
      </div>
      <ChartComponent
        width="98%"
        height={`${chartHeight}`}
        titleStyle={{ fontFamily: "Effra" }}
        id="adh-chart"
        primaryYAxis={{
          valueType: "Double",
          labelType: "n0",
          labelPlacement: "OnTicks",
          minimum: 0,
          maximum: 100,
          interval: 100,
          visible: false,
          stripLines: [
            {
              start: average,
              end: average,
              size: 2,
              sizeType: "Pixel",
              dashArray: "10,5",
              color: "#8c8c8c",
            },
          ],
        }}
        selectionMode="Point"
        primaryXAxis={{
          valueType: "Category",
          labelPlacement: "OnTicks",
          labelStyle: {
            size: "14px",
            fontFamily: "Effra",
          },
          majorTickLines: {
            width: 0,
          },

          majorGridLines: {
            width: 1,
          },
          lineStyle: { width: 0 },
          // stripLines: [
          //   {
          //     // start: 15,
          //     // end: 100,
          //     start:
          //       chartData.length > 1
          //         ? chartData.length - 1.5
          //         : chartData.length - 1,
          //     end:
          //       chartData.length > 1
          //         ? chartData.length - 0.4
          //         : chartData.length - 0.55,
          //     color: "transparent",

          //     zIndex: "Over",
          //     text: isIE ? `` : "100%",
          //     startFromAxis: false,
          //     opacity: 1,
          //     visible: true,
          //     verticalAlignment: "Start",
          //     horizontalAlignment: "End",
          //     textStyle: {
          //       color: "#8c8c8c",
          //       fontWeight: "bold"
          //     }
          //   },
          //   {
          //     start:
          //       chartData.length > 1
          //         ? chartData.length - 1.5
          //         : chartData.length - 0.6,
          //     end:
          //       chartData.length > 1
          //         ? chartData.length - 0.4
          //         : chartData.length - 0.55,
          //     color: "transparent",
          //     zIndex: "Over",
          //     text: isIE ? "" : "0%",
          //     startFromAxis: false,
          //     opacity: 1,
          //     visible: true,
          //     verticalAlignment: "Start",
          //     horizontalAlignment: "Start",
          //     textStyle: {
          //       color: "#8c8c8c",
          //       fontWeight: "bold"
          //     }
          //   }
          // ]
        }}
        isTransposed={true}
        chartArea={{ border: { width: 0, color: "transparent" } }}
        legendSettings={{
          mode: "Range",
          visible: false,
          toggleVisibility: false,
        }}
      >
        <Inject
          services={[
            ScatterSeries,
            Legend,
            DataLabel,
            Category,
            Tooltip,
            StripLine,
            ChartAnnotation,
          ]}
        />
        <AnnotationsDirective>
          <AnnotationDirective
            content={gradient}
            id="gradient"
            coordinateUnits="Point"
            x="text"
            y={0}
          />

          <AnnotationDirective
            content={arrowAnnotation}
            id="avg-adh"
            coordinateUnits="Point"
            x="text"
            region="Series"
            y={average}
          />
        </AnnotationsDirective>

        <SeriesCollectionDirective>
          <SeriesDirective
            dataSource={[...chartData, { value: undefined, label: "text" }]}
            xName="label"
            type="Scatter"
            yName="value"
            name="adh-chart"
            marker={marker}
          />
        </SeriesCollectionDirective>
        <RangeColorSettingsDirective>
          <RangeColorSettingDirective
            start={0}
            end={50}
            label="0-50%"
            colors={["#d73838"]}
          ></RangeColorSettingDirective>
          <RangeColorSettingDirective
            start={51}
            end={80}
            label="51-80%"
            colors={["#fcb422"]}
          ></RangeColorSettingDirective>
          <RangeColorSettingDirective
            start={81}
            label="81-100%"
            end={100}
            colors={["#8bce00"]}
          ></RangeColorSettingDirective>
        </RangeColorSettingsDirective>
      </ChartComponent>
      {/* <GradientStripLine /> */}
    </div>
  );
};

AdherenceChart.propTypes = {
  data: PropTypes.array.isRequired,
  average: PropTypes.number.isRequired,
};

export { AdherenceChart };
