import React, { useState } from "react";
import { CustomInput } from "reactstrap";
import { accountService } from "../_services";
import { useAlertContext } from "../context/alertContext";
import logo from "../images/ED-Hortizontal_2-color_Logo.png";
import { ReactComponent as LinkOutIcon } from "../images/link-out.svg";
import PropTypes from "prop-types";

const AcceptTerms = ({ setShowTermsPage }) => {
  const [submitting, setSubmitting] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const { alertMethods } = useAlertContext();

  const handleChange = (e) => {
    if (e.target.checked) {
      setTermsChecked(true);
    } else setTermsChecked(false);
  };

  const handleSubmit = () => {
    accountService
      .acceptTerms()
      .then(() => {
        setShowTermsPage(false);
      })
      .catch((e) => {
        setSubmitting(false);
        alertMethods.error("Something went wrong. Pleae try again.");
      });
  };

  const openTerms = () => {
    try {
      window.open("https://www.everydose.ai/terms-provider/", "_blank");
    } catch (e) {
      alertMethods.error(
        "Popup blocked by your browser. If the issue persists, please refresh the page and try again"
      );
    }
  };

  return (
    <div className="d-flex flex-column align-items-center smart-container accept-terms">
      <div className="d-flex justify-content-center mt-7 mb-5">
        <img src={logo} alt="logo" className="w-12rem" />
      </div>{" "}
      <div className="card bg-white align-self-center">
        <div className="card-body">
          <h3 className="text-center mb-6 mt-4">Accept Terms of Use</h3>
          <div className="pb-4 mx-6 text-center">
            Before using EveryDose, you must review and accept our latest Terms
            of Use. Questions? Contact us at{" "}
            <span className="d-inline-flex align-items-center">
              <a
                className="btn btn-link py-0"
                href="mailto:clientsupport@everydose.ai"
              >
                clientsupport@everydose.ai
              </a>
            </span>
          </div>
          <div className="d-flex flex-column align-items-center">
            <button
              type="button"
              className="btn btn-primary w-16rem"
              onClick={openTerms}
            >
              Click to read Terms of Use
              <LinkOutIcon
                className="ml-2 mb-1 fill-white"
                aria-label="link-out"
              />
            </button>

            <div className="d-flex mx-5 pt-5 pb-4">
              <CustomInput
                className="gray-checkmark"
                onChange={handleChange}
                type="checkbox"
                id="accept-terms"
                name="accept-terms"
              />
              <label
                className="text-secondary"
                htmlFor="accept-terms"
              >
                I have read and accept the Terms of Use
              </label>
            </div>
          </div>
        </div>
      </div>
      <button
        className="btn btn-primary w-16rem"
        type="button"
        disabled={!termsChecked || submitting}
        onClick={() => {
          setSubmitting(true);
          handleSubmit();
        }}
      >
        {submitting && (
          <span className="spinner-border spinner-border-sm mr-1"></span>
        )}
        Submit
      </button>
    </div>
  );
};
AcceptTerms.propTypes = {
  setShowTermsPage: PropTypes.func,
};

export { AcceptTerms };
