import React from "react";
import PropTypes from "prop-types";
import { administrationRoutes } from "../../_helpers/administrativeRoute";
import { ReactComponent as Exclamation } from "../../images/glyph-exclamation.svg";
import { getOrdinalNumber } from "../../_helpers";

function verbReason(str) {
  if (
    str &&
    (str.startsWith("to ") ||
      str.startsWith("for ") ||
      str.startsWith("until ") ||
      str.startsWith("while "))
  ) {
    return true;
  } else {
    return !str;
  }
}
const InstructionText = ({
  doseType,
  dose,
  frequency,
  prn,
  prnReason,
  exclamation,
  medScheduleTimes,
  daysOn,
  daysOff,
  totalNumberOfCycles
}) => {
  // find if dose values inside the medScheduleTimes array are different from each other
  const doseValues = medScheduleTimes.map((item) => item.dose);
  const doseValuesUnique = [...new Set(doseValues)];
  const doseValuesDifferent = doseValuesUnique.length > 1;
  let freqDescription =
    !frequency?.description && frequency?.value && frequency?.value !== "Cycle"
      ? ` ${frequency.value}`
      : "";

  if (frequency?.label === "Cycle") {
    // if daysOn, daysOff, or totalNumberOfCycles, add ( to the description
    if (daysOn || daysOff || totalNumberOfCycles > 0) {
      freqDescription += " (";
    }
    // if daysOn is not 0, add daysOn to the description

    if (daysOn) {
      freqDescription += `${daysOn} days on`;
    }
    // if daysOff is not 0, add daysOff to the description
    if (daysOff) {
      freqDescription += `, ${daysOff} days off`;
    }
    // if totalNumberOfCycles is not 0, add totalNumberOfCycles to the description
    if (totalNumberOfCycles > 0) {
      freqDescription += `, repeat ${totalNumberOfCycles} times`;
    }
    // if daysOn, daysOff, or totalNumberOfCycles, add ) to the description
    if (daysOn || daysOff || totalNumberOfCycles > 0) {
      freqDescription += ")";
    }
  }

  const exclamationComponent = exclamation ? (
    <Exclamation className="mr-1 mb-1 glyph-grooveblue" />
  ) : null;

  if (doseValuesDifferent) {
    return (
      <span>
        {exclamationComponent}
        {frequency?.description ? ` ${frequency.description}` : ""}
        {freqDescription}
        {medScheduleTimes.map((item, idx) => (
          <span
            key={`${item.time}-${item.dose}-${item.timeOfDay?.value}-${idx}`}
          >
            {getOrdinalNumber(idx)} dose: Take {item.dose}
            {/* if not the last item, add a "; " */}
            {idx !== medScheduleTimes.length - 1 ? "; " : ""}
          </span>
        ))}
      </span>
    );
  } else
    return (
      <span>
        {exclamation ? exclamationComponent : ""}
        Take{dose ? ` ${dose} ${doseType?.label ? doseType.label : ""}` : ""}
        {dose && frequency?.description && ","}
        {frequency?.description &&
        frequency?.value >= 200 &&
        frequency?.value < 327
          ? ` ${frequency.description}`
          : ""}
        {frequency?.description &&
        (frequency?.value < 200 || frequency?.value >= 327)
          ? ` ${frequency.description.toLowerCase()}`
          : ""}
        {freqDescription}
        {prn && (!frequency?.value || frequency?.value < 1000)
          ? " as needed"
          : ""}
        {verbReason(prnReason?.label) ? "" : " for"}
        {prnReason?.label ? ` ${prnReason.label}` : ``}
      </span>
    );
};

const RouteText = ({ route, exclamation }) => {
  const [routeDesc, setRouteDesc] = React.useState("");
  const findRoute =
    // search route enum to find the route description by value
    administrationRoutes.find((item) => item.value === route);

  React.useEffect(() => {
    if (findRoute) setRouteDesc(findRoute.instructionText);
  }, [findRoute]);

  return (
    <span>
      {routeDesc ? (
        <>
          {exclamation && (
            <Exclamation className="mr-1 mb-1 glyph-grooveblue" />
          )}
          {routeDesc}
        </>
      ) : (
        <></>
      )}
    </span>
  );
};

const CustomInstructionText = ({ instructionText, exclamation }) => {
  return (
    <span>
      {exclamation && <Exclamation className="mr-1 mb-1 glyph-grooveblue" />}
      {instructionText}
    </span>
  );
};
export { InstructionText, RouteText, CustomInstructionText };

InstructionText.propTypes = {
  doseType: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  frequency: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  prn: PropTypes.bool,
  prnReason: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  exclamation: PropTypes.bool,
  medScheduleTimes: PropTypes.array,
  daysOn: PropTypes.number,
  daysOff: PropTypes.number,
  totalNumberOfCycles: PropTypes.number
};

RouteText.propTypes = {
  route: PropTypes.number,
  exclamation: PropTypes.bool
};

CustomInstructionText.propTypes = {
  instructionText: PropTypes.string,
  exclamation: PropTypes.bool
};
