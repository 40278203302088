import classnames from "classnames";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import {
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  TabContent,
  TabPane
} from "reactstrap";
import {
  DateOfBirthField,
  FormikValidate,
  LockScreen,
  MySelect,
  SmartInviteForm
} from "../_components";
import {
  cantSubmitInvite,
  inviteGenderOptions,
  inviteNavItems,
  languageOptions,
  smartInviteValidationSchema
} from "../_helpers";
import { patientInviteService } from "../_services";
import { useAlertContext } from "../context/alertContext";
import { useSmartContext } from "../context/smartContext";
import { ReactComponent as Logo } from "../images/EDlogo.svg";
import { TabOutreach, TabTask } from "../components/patient";
import { usePatientContext } from "../context/patientContext";
import { PatientNav } from "../home/patientPages";
import PropTypes from "prop-types";

const SmartInvite = ({
  users,
  taskFeatureEnabled,
  outreachFeatureEnabled,
  tenantFeatures,
  setTasksActive,
  inviteFeatureEnabled
}) => {
  const { alertMethods } = useAlertContext();
  const { setPatientInviteStatus, patientId, patientName } = useSmartContext();
  const { state } = usePatientContext();
  const { patient } = state;
  const navigate = useNavigate();
  const mounted = React.useRef(false);
  const [missingDoB, setMissingDoB] = useState(false);
  const [meds, setMeds] = useState([]);
  const [nav, setNav] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  // const [hasDuplicates, setHasDuplicates] = useState(false);
  const [activePage, setActivePage] = useState(0);

  const togglePage = (page) => {
    if (page === 1 && taskFeatureEnabled) {
      setTasksActive(true);
    }
    setActivePage(page);
  };
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    mounted.current = true; // Will set it to true on mount ...
    return () => {
      mounted.current = false;
    }; // ... and to false on unmount
  }, []);

  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    gender: "",
    email: "",
    phoneNumber: "",
    emailCheckbox: false,
    phoneCheckbox: false,
    neitherCheckbox: false,
    language: languageOptions[0]
  });

  useEffect(() => {
    //hide pagination on first tab
    if (activeTab !== "1") setNav(true);
    if (activeTab === "1") setNav(false);
  }, [activeTab]);

  const getInvite = React.useCallback(
    (patientId) => {
      patientInviteService
        .getInvite(patientId)
        .then((response) => {
          if (!response.dateOfBirth) {
            setMissingDoB(true);
          }
          let formattedNo;
          if (response.phoneNumber) {
            if (response.phoneNumber.startsWith("+")) {
              formattedNo = response.phoneNumber;
            } else {
              formattedNo = "+1" + response.phoneNumber;
            }
          }
          let gender = inviteGenderOptions.find(
            (elem) => elem.value === response.gender
          );
          const idx = inviteGenderOptions.indexOf(gender);
          if (mounted.current) {
            setInitialValues({
              firstName: response.firstName,
              lastName: response.lastName,
              dateOfBirth: response.dateOfBirth,
              gender: inviteGenderOptions[idx],
              email: response.email ? response.email : "",
              phoneNumber: formattedNo ? formattedNo : "",
              emailCheckbox: response.email ? true : false,
              phoneCheckbox: response.phoneNumber ? true : false,
              neitherCheckbox: false,
              language: languageOptions[0]
            });

            setMeds(response.medications);
          }
          setLoading(false);
        })
        .catch((e) => {
          if (e.error === "Patient does not exist") {
            setPatientInviteStatus(7);
            sessionStorage.setItem("invitationStatus", JSON.stringify(7));
            navigate("/smart/400");
          }
          console.log("error", e);
        });
    },
    [navigate, setPatientInviteStatus]
  );

  const syncMedications = React.useCallback(() => {
    setLoading(true);
    setMeds([]);

    setLoading(true);
    patientInviteService
      .smartSyncMed(patientId)
      .then(() => {
        if (mounted.current) {
          getInvite(patientId);
        }
      })
      .catch((e) => {
        navigate("/smart/403");
      });
  }, [getInvite, navigate, patientId]);

  function onSubmit(fields, { setStatus, setSubmitting, resetForm }) {
    let dob = new Date(fields.dateOfBirth);
    let userTimezoneOffset = dob.getTimezoneOffset() * 60000;
    // let plans = meds
    //   .filter((med) => med.selectedPlan?.medicationPlanSteps)
    //   .map((item) => item.selectedPlan);
    let medications = meds.map((med) => {
      if (med.medicationApprovalStatus) return med;
      else return { ...med, medicationApprovalStatus: 1 };
    });
    // {medications:
    // meds.filter(
    //   (med) => !med.selectedPlan || !med.selectedPlan.medicationPlanSteps
    // );
    // ,
    // medicationPlans: plans
    // };

    //structuring data to send to API
    const inviteData = {};
    inviteData.medications = medications;
    inviteData.firstName = fields.firstName;
    inviteData.lastName = fields.lastName;
    inviteData.medications = medications;
    //Not sending phone or email to API if box isn't checked
    if (fields.emailCheckbox) {
      inviteData.emailInvitationEnabled = true;
      inviteData.email = fields.email;
    } else {
      inviteData.emailInvitationEnabled = false;
      inviteData.email = "";
    }
    if (fields.phoneCheckbox) {
      inviteData.phoneInvitationEnabled = true;
      inviteData.phoneNumber = fields.phoneNumber;
    } else {
      inviteData.phoneInvitationEnabled = false;
      inviteData.phoneNumber = "";
    }

    inviteData.gender = fields.gender.value;
    inviteData.dateOfBirth = new Date(
      dob.getTime() - userTimezoneOffset
    ).toISOString();
    inviteData.language = fields.language.value;

    patientInviteService
      .smartInvite(inviteData, patientId)
      .then(() => {
        if (setPatientInviteStatus) {
          setPatientInviteStatus(1);
          sessionStorage.setItem("invitationStatus", JSON.stringify(1));
        }
        alertMethods.success(
          "<div class=''>Success!</div>Patient has been invited to join EveryDose",
          { keepAfterRouteChange: true }
        );
        fields.phoneNumber = "";
        fields.email = "";
        setSubmitting(false);
        resetForm();
        setSuccess(true);
      })
      .catch((error) => {
        setSubmitting(false);

        alertMethods.error(
          "<div class=''>An Error Occurred</div>" + error.message
        );
      });
  }

  return (
    <div>
      <PatientNav
        activeTab={activePage}
        toggleTab={togglePage}
        items={inviteNavItems}
      />
      <TabContent activeTab={activePage}>
        <TabPane tabId={0}>
          {!inviteFeatureEnabled ? (
            <Row>
              <Col sm="12">
                <LockScreen
                  feature="Patient Invitations"
                  type={
                    tenantFeatures?.["PatientInvitations"]
                      ? "user-permissions"
                      : ""
                  }
                />
              </Col>
            </Row>
          ) : (
            <div className="container">
              <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={smartInviteValidationSchema}
                onSubmit={onSubmit}
                validateOnChange={true}
              >
                {({
                  errors,
                  touched,
                  isSubmitting,
                  isValid,
                  values,
                  setFieldValue,
                  setFieldTouched
                }) => {
                  return (
                    <div className="d-flex flex-column align-items-center smart-container">
                      <Pagination
                        size="sm"
                        role="tablist"
                        aria-label="Page navigation example"
                        className={classnames(
                          { "d-none": !nav },
                          { pagination: nav }
                        )}
                      >
                        <PaginationItem className="import-circles">
                          <PaginationLink
                            className={classnames({
                              active: activeTab === "2"
                            })}
                            onClick={() => {
                              toggle("2");
                            }}
                          >
                            1
                          </PaginationLink>
                        </PaginationItem>
                        <PaginationItem className="import-circles">
                          <PaginationLink
                            className={classnames({
                              active: activeTab === "3"
                            })}
                            onClick={() => {
                              toggle("3");
                            }}
                          >
                            2
                          </PaginationLink>
                        </PaginationItem>
                      </Pagination>
                      <FormikValidate>
                        <Form
                          data-testid="smart-invite-form"
                          className="smart-invite-form"
                        >
                          <TabContent activeTab={activeTab}>
                            <TabPane tabId="1" data-testid="tab1">
                              <div className="card bg-white align-self-center invite">
                                <div className="card-body ">
                                  <div className="d-flex justify-content-center pb-3">
                                    <Logo
                                      alt="EveryDose logo"
                                      className="height-40"
                                    />
                                  </div>
                                  <h3 className="text-center mb-6">
                                    Enroll Patient in EveryDose
                                  </h3>
                                  <div className="pb-4 mx-6">
                                    Let's get this patient signed up for the
                                    EveryDose medication management mobile app
                                    with two quick steps!
                                  </div>
                                  <div className="form-row d-none">
                                    <div className="form-group col-6">
                                      <label htmlFor="firstName">
                                        First Name *
                                      </label>
                                      <Field
                                        id="firstName"
                                        name="firstName"
                                        placeholder="Patient's First Name"
                                        type="text"
                                        className={classnames(
                                          {
                                            "is-invalid":
                                              errors.firstName &&
                                              touched.firstName
                                          },
                                          "form-control"
                                        )}
                                      />
                                      <ErrorMessage
                                        name="firstName"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                    <div className="form-group col-6">
                                      <label htmlFor="lastName">
                                        Last Name *
                                      </label>
                                      <Field
                                        id="lastName"
                                        name="lastName"
                                        placeholder="Patient's Last Name"
                                        type="text"
                                        className={classnames(
                                          {
                                            "is-invalid":
                                              errors.lastName &&
                                              touched.lastName
                                          },
                                          "form-control"
                                        )}
                                      />
                                      <ErrorMessage
                                        name="lastName"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                  </div>

                                  <div className="form-row d-none">
                                    <div className="form-group col-6">
                                      <label htmlFor="dateOfBirth">
                                        Date of Birth *
                                      </label>
                                      <DateOfBirthField
                                        isRequired={true}
                                        id="dateOfBirth"
                                        name="dateOfBirth"
                                        value={values.dateOfBirth}
                                        onChange={setFieldValue}
                                        onBlur={setFieldTouched}
                                        className="form-control"
                                      />
                                    </div>
                                    <div className="form-group col-6">
                                      <MySelect
                                        name="gender"
                                        label="Gender"
                                        options={inviteGenderOptions}
                                        value={values.gender}
                                        onChange={setFieldValue}
                                        onBlur={setFieldTouched}
                                        error={errors.gender}
                                        touched={touched.gender}
                                      ></MySelect>
                                      <ErrorMessage
                                        name="gender"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                  </div>
                                  <div className="form-row justify-content-center mt-4">
                                    <button
                                      type="button"
                                      //   disabled={isSubmitting || !isValid || !dirty}
                                      className="btn btn-primary w-50"
                                      onClick={() => {
                                        syncMedications();
                                        toggle("2");
                                      }}
                                    >
                                      {isSubmitting && (
                                        <span className="spinner-border spinner-border-sm mr-1"></span>
                                      )}
                                      Get Started
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex flex-column align-items-center mt-4">
                                <div className="text-secondary">
                                  Does this patient already have the app?
                                </div>
                                <Link to="/smart/mobile-link">
                                  Click here to link their profile
                                </Link>
                              </div>
                              {/* <div className="card bg-white align-self-center invite">
                        <div className="d-flex card-body py-4 justify-content-between align-items-center">
                          <div>
                            MedGuide Only{" "}
                            <Exclamation
                              className="glyph-grooveblue"
                              alt="exclamation"
                            />
                          </div>
                          <Lock alt="lock" className="glyph-grooveblue" />
                        </div>
                      </div>
                      <div className="card bg-white align-self-center invite">
                        <div className="d-flex card-body py-4 justify-content-between align-items-center">
                          <div>
                            RPM Device Only{" "}
                            <Exclamation
                              className="glyph-grooveblue"
                              alt="exclamation"
                            />
                          </div>

                          <Lock alt="lock" className="glyph-grooveblue" />
                        </div>
                      </div> */}
                            </TabPane>
                            <SmartInviteForm
                              version="ehr"
                              meds={meds}
                              setMeds={setMeds}
                              syncMedications={syncMedications}
                              loading={loading}
                              toggle={toggle}
                              isSubmitting={isSubmitting}
                              values={values}
                              setFieldValue={setFieldValue}
                              errors={errors}
                              touched={touched}
                              isValid={isValid}
                              cantSubmitInvite={cantSubmitInvite}
                              setFieldTouched={setFieldTouched}
                              missingDoB={missingDoB}
                            />
                          </TabContent>
                        </Form>
                      </FormikValidate>
                    </div>
                  );
                }}
              </Formik>

              {/* <MyToast alerts={alerts} setAlerts={setAlerts} /> */}
              {success && (
                <Navigate
                  to={`/smart/manage-invitations/manage/${patientId}`}
                />
              )}
            </div>
          )}
        </TabPane>
        <TabPane tabId={1}>
          {!taskFeatureEnabled ? (
            <Row>
              <Col sm="12">
                <LockScreen
                  feature={"Tasks"}
                  type={tenantFeatures?.["Tasks"] ? "user-permissions" : ""}
                />
              </Col>
            </Row>
          ) : (
            <TabTask
              patient={{ ...patient, name: patientName }}
              patientId={patientId}
              patientGroups={[]}
              users={users}
              patientExists={patientId ? true : false}
            />
          )}
        </TabPane>
        <TabPane tabId={2}>
          {!outreachFeatureEnabled ? (
            <Row>
              <Col sm="12">
                <LockScreen
                  feature="Outreach"
                  type={tenantFeatures?.["Outreach"] ? "user-permissions" : ""}
                />
              </Col>
            </Row>
          ) : (
            <TabOutreach
              patient={{ ...patient, name: patientName }}
              patientId={patientId}
              patientGroups={[]}
              users={users}
              patientExists={patientId}
            />
          )}
        </TabPane>
      </TabContent>
    </div>
  );
};

SmartInvite.propTypes = {
  users: PropTypes.array,
  taskFeatureEnabled: PropTypes.bool,
  outreachFeatureEnabled: PropTypes.bool,
  tenantFeatures: PropTypes.object,
  setTasksActive: PropTypes.func,
  inviteFeatureEnabled: PropTypes.bool
};

export { SmartInvite };
