import classnames from "classnames";
import { ErrorMessage, Field, FieldArray } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { MedLookupField, MyMultiSelect, MySelect } from "../_components";
import { administrationRoutes } from "../_helpers/administrativeRoute";
import {
  andOrOptions,
  compareOptions,
  riskOptions,
  trueFalseOptions
} from "../_helpers/constants";
import { UseMobileOrTabletMediaQuery } from "../_helpers/media-queries";
import { therapeuticCategories } from "../_helpers/therapeuticCategories";
import { medicationsService } from "../_services";
import { useAlertContext } from "../context/alertContext";
import { ReactComponent as GlyphMinus } from "../images/glyph-circle-minus.svg";
import { ReactComponent as PlusIcon } from "../images/glyph-plus.svg";
import TherapeuticCategoriesDropdown from "./TherapeuticCategories";

const riskProfileOptionsFindCallBack = (val) => (item) =>
  item.value === Number(val);

const Criteria = ({
  values,
  setFieldValue,
  setFieldTouched,
  criteriaOptions,
  setInitialValues,
  riskProfileOptions,
  selectedItem,
  errors,
  touched
}) => {
  const { alertMethods } = useAlertContext();
  const [activeIngredientOptions, setActiveIngredientOptions] = useState([]);
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf("MSIE ");
  const isIE = msie > 0 || !!/Trident.*rv:11\./.exec(navigator.userAgent);

  const sortedRoutes = administrationRoutes
    .sort((a, b) => {
      return a.label.localeCompare(b.label);
    })
    .map((item) => {
      return {
        label: item.description,
        value: item.value
      };
    });

  const sortedCriteriaOptions = criteriaOptions.sort((a, b) => {
    return a.label.localeCompare(b.label);
  });

  const [isLoading, setIsLoading] = useState(false);

  const fetchActiveIngredients = async () => {
    if (!activeIngredientOptions.length) {
      setIsLoading(true);
      try {
        const response = await medicationsService.activeIngredients();
        const options = response.map((item) => {
          return {
            label: item,
            value: item
          };
        });
        setActiveIngredientOptions(options);
      } catch (error) {
        setIsLoading(false);
        alertMethods.error(
          "Something went wrong. Refresh the page and try again."
        );
        // Optionally, you can set an error state or show a message to the user
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (selectedItem?.id) {
      let criteria = [];
      // Type of criteria (number of meds, medication, favorite patient, etc.)
      let compare = [];
      // The compare operater (=, >, <, etc.)
      let choice = [];
      // The med name, number of meds, true, false, etc.
      let andor = [];
      // and/or
      selectedItem.filters.forEach((filter, idx) => {
        // To prefill the form, we have to find which items were selected for each filter
        criteria.push(
          criteriaOptions.find((item) => item.value === filter.filterType)
        );
        compare.push(
          compareOptions.find((item) => item.value === filter.operationType)
        );
        if (filter.filterType > 1 && filter.filterType < 6) {
          if (filter.filterValue.length > 1) {
            choice.push(filter.filterValue);
            // do something
          } else choice.push(filter.filterValue[0]);
        } else if (filter.filterType === 1) {
          choice.push(
            filter.filterValue.map((item) => {
              return {
                value: item,
                label: item
              };
            })
          );
        } else if (filter.filterType === -1) {
          choice.push(
            filter.filterValue.map((val) => {
              return riskProfileOptions.find(
                riskProfileOptionsFindCallBack(val)
              );
            })
          );
        } else if (filter.filterType === 0 || filter.filterType === 6) {
          choice.push(
            Object.entries(filter.filterValueDetails).map(([key, value]) => ({
              value: key,
              label: value
            }))
          );
        } else {
          choice.push(
            trueFalseOptions.find(
              (item) => item.label === filter.filterValue[0]
            )
          );
        }
        andor.push(
          andOrOptions.find((item) => item.value === filter.filterOperator)
        );
      });

      setInitialValues({
        listName: selectedItem.name,
        description: selectedItem.description,
        listCriteria: criteria.map((item, index) => {
          return {
            criteria: item,
            compare: compare[index],
            choice: choice[index],
            andor: andor[index],
            id: index
          };
        }),

        groups: selectedItem.groups,
        default: selectedItem.isDefault,

        severity: riskOptions.find(
          (item) => item.value === selectedItem.severity
        )
      });
    } else {
      setInitialValues({
        listName: "",
        description: "",
        listCriteria: [
          {
            id: 0,
            andor: { value: 0, label: "AND" },
            criteria: "",
            compare: "",
            choice: ""
          }
        ],
        groups: [],
        severity: ""
      });
    }
  }, [selectedItem, riskProfileOptions, setInitialValues, criteriaOptions]);

  const is768Width = UseMobileOrTabletMediaQuery();

  const getMySelectOptions = (index) => {
    if (
      values.listCriteria[index].criteria?.value >= 3 &&
      values.listCriteria[index].criteria?.value <= 5
    )
      return compareOptions;
    else if (values.listCriteria[index].criteria?.value === -1)
      return compareOptions.filter((item) => item.value <= 1);
    else
      return compareOptions.filter((item) => {
        return item.value === 0;
      });
  };

  return (
    <>
      <h6 className=" mt-5">Filter Options</h6>
      <p>Include patient who meet the following criteria: </p>
      <FieldArray
        name="listCriteria"
        render={(arrayHelpers) => (
          <div>
            <div className="">
              {values.listCriteria?.map((criterion, index) => {
                return (
                  <div className="form" key={criterion.id}>
                    <div className="mt-5 d-flex justify-content-between px-1">
                      {index === 0 ? (
                        <div className="mx-lg-3 pb-lg-0 pb-3">
                          <div className="form-control w-7rem bg-light">IF</div>
                        </div>
                      ) : (
                        <div
                          className={classnames(
                            { "w-5": is768Width },
                            { "w-5.5": !is768Width },
                            "ml-lg-3 mx-4"
                          )}
                        >
                          <MySelect
                            placeholder=""
                            name={`listCriteria.${index}.andor`}
                            data-testid={`listCriteria.${index}.andor`}
                            value={values.listCriteria[index].andor}
                            // error={errors.listCriteria[index]?.andor}
                            //If has the same value as And, we just display it differently for the first criteria
                            options={andOrOptions}
                            onChange={setFieldValue}
                            onBlur={setFieldTouched}
                          ></MySelect>
                        </div>
                      )}

                      {is768Width && index > 0 && (
                        <button
                          type="button"
                          className="btn btn-link text-danger mr-2"
                          onClick={() => arrayHelpers.remove(index)}
                        >
                          Remove Filter
                        </button>
                      )}
                    </div>
                    <div className="border-left ml-2">
                      <div className="d-flex flex-lg-row flex-column mt-4 align-items-center">
                        <div className="col-1"></div>
                        <label
                          htmlFor={`listCriteria.${index}.criteria`}
                          className="align-self-center col-lg-3 col-12 pl-lg-5 pl-3"
                        >
                          Criteria {index + 1}:
                        </label>
                        <div className="col-lg-7  pr-lg-0 pr-4 mr-2  ml-0">
                          <MySelect
                            placeholder="Select Category"
                            name={`listCriteria.${index}.criteria`}
                            data-testid={`listCriteria.${index}.criteria`}
                            value={values.listCriteria[index].criteria}
                            options={sortedCriteriaOptions
                              // filter out the criteria that are already selected
                              .filter(
                                (item) =>
                                  !values.listCriteria
                                    .map((item) => item.criteria?.value)
                                    .includes(item.value)
                              )}
                            onChange={(name, val) => {
                              setFieldValue(name, val);
                              setFieldValue(`listCriteria.${index}.compare`, {
                                value: 0,
                                label: "="
                              });
                              if (
                                (val.value < 0 && val.value > -5) ||
                                val.value > 2
                              ) {
                                setFieldValue(
                                  `listCriteria.${index}.choice`,
                                  ""
                                );
                              } else {
                                setFieldValue(
                                  `listCriteria.${index}.choice`,
                                  []
                                );
                              }
                            }}
                            onBlur={setFieldTouched}
                          />
                        </div>
                        <ErrorMessage
                          name={`criteria.${index}`}
                          component="div"
                          className="invalid-feedback"
                        />
                        {!is768Width && index > 0 && (
                          <div className="col-1">
                            <button
                              type="button"
                              className="close minus-btn"
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              <GlyphMinus
                                className="glyph-danger"
                                aria-label="delete-criteria"
                              />
                            </button>
                          </div>
                        )}
                      </div>
                      <div
                        className={classnames(
                          { "d-flex": !isIE || !is768Width },
                          "flex-column flex-lg-row mt-4"
                        )}
                      >
                        <div className="col-1"></div>
                        <div
                          className={classnames(
                            { "mb-0 border-left ml-6 mb-4": is768Width },
                            "col-lg-3 col-6 pl-lg-3"
                          )}
                        >
                          <label
                            htmlFor={`listCriteria.${index}.compare`}
                            className={classnames({ "w-75": is768Width })}
                          >
                            <MySelect
                              placeholder=""
                              data-testid={`listCriteria.${index}.compare`}
                              name={`listCriteria.${index}.compare`}
                              defaultValue={compareOptions[0]}
                              value={values.listCriteria[index].compare}
                              options={
                                // to allow all the compare options only when the criteria is 3 || 4 || 5
                                getMySelectOptions(index)
                              }
                              onChange={(name, val) => {
                                if (val.value === 8) {
                                  setFieldValue(
                                    `listCriteria.${index}.choice`,
                                    ["not set", "not set"]
                                  );
                                } else {
                                  setFieldValue(
                                    `listCriteria.${index}.choice`,
                                    ""
                                  );
                                }

                                setFieldValue(name, val);
                              }}
                              onBlur={setFieldTouched}
                            ></MySelect>
                          </label>
                        </div>
                        <div className="col-lg-7 pr-lg-0 pr-4 mr-2 ml-0">
                          {/* criteria values should only be between 3 and 5 */}
                          {values.listCriteria[index].criteria?.value >= 3 &&
                            values.listCriteria[index].criteria?.value <= 5 && (
                              <>
                                {/* if the value of choice[index] is an array, show two number fields  */}
                                {Array.isArray(
                                  values.listCriteria[index].choice
                                ) ? (
                                  <div className="form-row criteria-row">
                                    <div className="col-5">
                                      <Field
                                        className={classnames(
                                          {
                                            "is-invalid":
                                              errors.listCriteria?.[index]
                                                ?.choice &&
                                              touched.listCriteria?.[index]
                                                ?.choice
                                          },
                                          "form-control"
                                        )}
                                        type="number"
                                        data-testid={`listCriteria.${index}.choice.0`}
                                        name={`listCriteria.${index}.choice.0`}
                                        placeholder={"Enter Val"}
                                        onChange={(e) => {
                                          setFieldValue(
                                            `listCriteria.${index}.choice.0`,
                                            e.target.value
                                          );
                                        }}
                                        onBlur={(e) => {
                                          setFieldTouched(
                                            `listCriteria.${index}.choice.0`,
                                            e.target.value
                                          );
                                        }}
                                        min="0"
                                        max={
                                          values.listCriteria[index].choice[1]
                                            ? Number(
                                                values.listCriteria[index]
                                                  .choice[1]
                                              ) - 1
                                            : 120
                                        }
                                      />
                                    </div>
                                    <span className="mt-3"> and </span>

                                    <div className="col-5">
                                      <Field
                                        className={classnames(
                                          {
                                            "is-invalid":
                                              errors.listCriteria?.[index]
                                                ?.choice &&
                                              // both choice fields should be touched to show the error
                                              touched.listCriteria?.[index]
                                                ?.choice
                                          },
                                          "form-control"
                                        )}
                                        type="number"
                                        data-testid={`listCriteria.${index}.choice.1`}
                                        name={`listCriteria.${index}.choice.1`}
                                        error={
                                          errors.listCriteria?.[index]?.choice
                                        }
                                        placeholder={"Enter Val"}
                                        onChange={(e) => {
                                          setFieldValue(
                                            `listCriteria.${index}.choice.1`,
                                            e.target.value
                                          );
                                        }}
                                        onBlur={(e) => {
                                          setFieldTouched(
                                            `listCriteria.${index}.choice.1`,
                                            e.target.value
                                          );
                                        }}
                                        min={
                                          values.listCriteria[index].choice[0]
                                            ? Number(
                                                values.listCriteria[index]
                                                  .choice[0]
                                              ) + 1
                                            : 1
                                        }
                                        max="120"
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <Field
                                    className={classnames(
                                      {
                                        "is-invalid":
                                          errors.listCriteria?.[index]
                                            ?.choice &&
                                          touched.listCriteria?.[index]?.choice
                                      },
                                      "form-control"
                                    )}
                                    type="number"
                                    data-testid={`listCriteria.${index}.choice`}
                                    name={`listCriteria.${index}.choice`}
                                    placeholder={"Enter Value"}
                                    onChange={(e) => {
                                      setFieldValue(
                                        `listCriteria.${index}.choice`,
                                        e.target.value
                                      );
                                    }}
                                    min="0"
                                    max="120"
                                  />
                                )}
                                <ErrorMessage
                                  name={`listCriteria.${index}.choice`}
                                  component="div"
                                  className=""
                                >
                                  {(message) => {
                                    if (message !== "This field is required.")
                                      return (
                                        <div className="invalid-feedback show">
                                          {message}
                                        </div>
                                      );
                                    else return <></>;
                                  }}
                                </ErrorMessage>
                              </>
                            )}
                          {values.listCriteria[index].criteria?.value === 0 && (
                            <>
                              <MedLookupField
                                label={false}
                                name={`listCriteria.${index}.choice`}
                                val={values.listCriteria[index].choice}
                                setFieldValue={setFieldValue}
                                setFieldTouched={setFieldTouched}
                                setInitialValues={setInitialValues}
                                isMulti={true}
                                error={
                                  errors.listCriteria?.[index]?.choice &&
                                  touched.listCriteria?.[index]?.choice
                                }
                              />{" "}
                            </>
                          )}
                          {values.listCriteria[index].criteria?.value === 6 && (
                            <MyMultiSelect
                              placeholder="Select Route(s)"
                              data-testid={`listCriteria.${index}.choice`}
                              name={`listCriteria.${index}.choice`}
                              value={values.listCriteria[index].choice}
                              options={sortedRoutes}
                              onChange={setFieldValue}
                              onBlur={setFieldTouched}
                              isLoading={isLoading}
                              error={
                                errors.listCriteria?.[index]?.choice &&
                                touched.listCriteria?.[index]?.choice
                              }
                            ></MyMultiSelect>
                          )}
                          {!values.listCriteria[index].criteria?.value &&
                            values.listCriteria[index].criteria?.value !==
                              0 && (
                              <div
                                className="form-control bg-gray"
                                data-testid={`listCriteria.${index}.choice`}
                              >
                                Select a Criteria
                              </div>
                            )}
                          {values.listCriteria[index].criteria?.value < -1 ? (
                            <MySelect
                              placeholder="Select"
                              data-testid={`listCriteria.${index}.choice`}
                              name={`listCriteria.${index}.choice`}
                              value={values.listCriteria[index].choice}
                              options={trueFalseOptions}
                              onChange={setFieldValue}
                              onBlur={setFieldTouched}
                            ></MySelect>
                          ) : (
                            <></>
                          )}
                          {values.listCriteria[index].criteria?.value ===
                            -1 && (
                            <MyMultiSelect
                              placeholder="Select Risk Profile(s)"
                              data-testid={`listCriteria.${index}.choice`}
                              name={`listCriteria.${index}.choice`}
                              value={values.listCriteria[index].choice}
                              options={riskProfileOptions}
                              onChange={setFieldValue}
                              onBlur={setFieldTouched}
                              isLoading={isLoading}
                              error={
                                errors.listCriteria?.[index]?.choice &&
                                touched.listCriteria?.[index]?.choice
                              }
                            ></MyMultiSelect>
                          )}

                          {values.listCriteria[index].criteria?.value === 1 && (
                            <MyMultiSelect
                              placeholder="Select Active Ingredient(s)"
                              data-testid={`listCriteria.${index}.choice`}
                              name={`listCriteria.${index}.choice`}
                              value={values.listCriteria[index].choice}
                              options={activeIngredientOptions}
                              onChange={setFieldValue}
                              onBlur={setFieldTouched}
                              onMenuOpen={() => fetchActiveIngredients()}
                              isLoading={isLoading}
                              error={
                                errors.listCriteria?.[index]?.choice &&
                                touched.listCriteria?.[index]?.choice
                              }
                            ></MyMultiSelect>
                          )}
                          {values.listCriteria[index].criteria?.value === 2 && (
                            <TherapeuticCategoriesDropdown
                              placeholder="Select"
                              value={values.listCriteria[index].choice}
                              name={`listCriteria.${index}.choice`}
                              onBlur={setFieldTouched}
                              error={
                                errors.listCriteria?.[index]?.choice &&
                                touched.listCriteria?.[index]?.choice
                              }
                              onChange={setFieldValue}
                              options={therapeuticCategories}
                            />
                          )}
                        </div>

                        <div className="col-1"></div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div>
              <button
                type="button"
                className="btn btn-link my-5"
                onClick={(e, i) => {
                  let lastCriteria =
                    values.listCriteria[values.listCriteria.length - 1];
                  arrayHelpers.push({
                    id: lastCriteria?.id + 1 || 0,
                    criteria: "",
                    compare: "",
                    choice: "",
                    andor: ""
                  });
                }}
              >
                <PlusIcon aria-label="plus" /> Add Another Criteria
              </button>
            </div>
          </div>
        )}
      />
    </>
  );
};

Criteria.propTypes = {
  values: PropTypes.object,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  criteriaOptions: PropTypes.array,
  setInitialValues: PropTypes.func,
  riskProfileOptions: PropTypes.array,
  selectedItem: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object
};

export { Criteria };
