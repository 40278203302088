import classnames from "classnames";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import {
  CloseButton,
  MyDropdown,
  MyPopover,
  NewGTable,
  RowExpander,
  TaskForm
} from "../_components";
import {
  convertUTCTimeToLocalTime,
  taskTypeOptions,
  findType
} from "../_helpers";
import {
  UseMobileMediaQuery,
  UseMobileOrTabletMediaQuery
} from "../_helpers/media-queries";
import { taskService } from "../_services";
import { useUserContext } from "../context/userContext";
import { useAlertContext } from "../context/alertContext";
import { ReactComponent as CompleteCircleCheck } from "../images/circle_filled_check.svg";
import { ReactComponent as CircleCheck } from "../images/circle_unfilled_check.svg";
import { ReactComponent as GlyphMenu } from "../images/glyph_menu_2.svg";
import { ReactComponent as GlyphPopup } from "../images/glyph_popup.svg";
import PropTypes from "prop-types";

const Break = ({ is768Width }) => {
  return <> {is768Width ? <>&nbsp;&nbsp; |&nbsp;&nbsp;</> : <br></br>}</>;
};
Break.propTypes = {
  is768Width: PropTypes.bool
};

const StatusCell = ({ row, value, updateStatus }) => {
  const [tempVal, setTempVal] = useState(!!value);
  const checkboxRef = useRef(null);
  const updateTempVal = () => {
    setTempVal(!tempVal);
  };
  const handleCheckboxClick = () => {
    updateTempVal();
    checkboxRef.current.blur();
  };

  return (
    <label className="g-table-circle-check">
      {tempVal ? (
        <>
          <input
            ref={checkboxRef}
            type="checkbox"
            data-testid={`complete-${row.original.id}`}
            defaultChecked={true}
            name={row.original.id}
            onChange={(e) => {
              updateStatus(row.original, 0);
              handleCheckboxClick();
            }}
          />
          <CompleteCircleCheck
            className="glyph-complete"
            aria-label="checked"
          />
        </>
      ) : (
        <>
          <input
            ref={checkboxRef}
            type="checkbox"
            data-testid={`complete-${row.original.id}`}
            defaultChecked={false}
            name={row.original.id}
            onChange={(e) => {
              updateStatus(row.original, 1);
              handleCheckboxClick();
            }}
          />
          <CircleCheck className="glyph-incomplete" aria-label="unchecked" />
        </>
      )}
    </label>
  );
};
StatusCell.propTypes = {
  row: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  value: PropTypes.number,
  updateStatus: PropTypes.func
};

const TitleCell = ({ row, value, is768Width, isMobileWidth }) => {
  return (
    <div className="">
      {row.original.description ? (
        <>
          <button
            aria-describedby="task-description"
            id={`title-${row.id}`}
            className="sentvia-popover btn btn-link pl-0 text-dark overflow-anywhere"
          >
            {value}
            <GlyphPopup className="ml-3 glyph-dark" aria-label="popup" />
          </button>
          <MyPopover
            target={`title-${row.id}`}
            title={"Task Description"}
            id="title"
            text={row.original.description}
            placement="bottom-end"
            dynamicPlacement={true}
          />
        </>
      ) : (
        <div className="overflow-anywhere">{value}</div>
      )}
    </div>
  );
};
TitleCell.propTypes = {
  row: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  value: PropTypes.string,
  is768Width: PropTypes.bool,
  isMobileWidth: PropTypes.bool
};

const UserNameCell = ({ value, row, isMobileWidth, user, userId }) => {
  return (
    <div className="d-flex justify-content-between align-items-center">
      {userId === user?.userId ? <div>Me</div> : <div>{value || "--"}</div>}
      {isMobileWidth && <RowExpander {...row} />}
    </div>
  );
};
UserNameCell.propTypes = {
  value: PropTypes.string,
  row: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isMobileWidth: PropTypes.bool,
  user: PropTypes.object,
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

const PatientNameCell = ({ row, value, isMobileWidth }) => {
  const patientId = row.original?.patientId;
  const isMobileAppConnected = row.original?.isMobileAppConnected;

  return (
    <div>
      {value || patientId ? (
        <div className="d-flex justify-content-between">
          <Link
            className="btn btn-link"
            to={
              isMobileAppConnected
                ? `/patients/${patientId}`
                : `/invite/manage-invitations/manage/${patientId}`
            }
            state={{
              linkBack: "/tasks",
              backText: "to Task List"
            }}
          >
            {value}
          </Link>
          <div className="d-flex justify-content-between align-items-center">
            {isMobileWidth && <RowExpander {...row} />}
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-between">
          <div>--</div>
        </div>
      )}
    </div>
  );
};
PatientNameCell.propTypes = {
  row: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  value: PropTypes.string,
  isMobileWidth: PropTypes.bool
};

const GroupCell = ({ value, row, isMobileWidth, is768Width }) => {
  return (
    <div className="d-flex justify-content-between align-items-center">
      {value?.length > 20 ? <>{value.slice(0, 20)}</> : <>{value || "--"}</>}
      {is768Width && !isMobileWidth && <RowExpander {...row} />}
    </div>
  );
};
GroupCell.propTypes = {
  row: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  value: PropTypes.string,
  is768Width: PropTypes.bool,
  isMobileWidth: PropTypes.bool
};

const TaskTypeCell = ({ value, typeOptions }) => {
  let val = typeOptions.find((option) => Number(option.value) === value);
  return <>{val?.label}</>;
};
TaskTypeCell.propTypes = {
  value: PropTypes.number,
  typeOptions: PropTypes.array
};

const DueDateCell = ({ row, value, is768Width, isSmartSession }) => {
  return (
    <div>
      {row.original.dueTime && row.original.dueTime !== "00:00:00" ? (
        <>
          <Moment utc format="M/D/YYYY">
            {value}
          </Moment>
          <Break is768Width={is768Width && !isSmartSession} />
          <>{convertUTCTimeToLocalTime(row.original.dueTime)}</>
        </>
      ) : (
        <Moment format="M/D/YYYY">{value.slice(0, -1)}</Moment>
      )}
    </div>
  );
};
DueDateCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({ dueTime: PropTypes.string })
  }),
  value: PropTypes.string,
  is768Width: PropTypes.bool,
  isSmartSession: PropTypes.bool
};

const ActionsCell = ({
  cancelTask,
  toggleModal,
  setSelectedItem,
  user,
  row
}) => {
  const createItems = React.useCallback(
    (row) => {
      const items = [
        {
          name: "Edit Task",
          link: "",
          onClick: () => {
            toggleModal(true);
            setSelectedItem(row);
          },
          class: "text-primary"
        },
        {
          name: "Cancel Task",
          link: "",
          onClick: () => {
            cancelTask(row);
          },
          class: "text-danger"
        }
      ];
      if (row?.userName !== "Former User" && row?.createdBy !== user?.name)
        items.shift();
      return items;
    },
    [cancelTask, setSelectedItem, toggleModal, user]
  );
  return (
    <>
      {row.status !== 1 && (
        <div className="text-right my-neg-8px">
          <MyDropdown
            details={{
              name: "row menu",
              glyph: <GlyphMenu aria-label="menu" className="glyph-gray" />,
              items: createItems(row)
            }}
            // container="body"
            direction="right"
          />
        </div>
      )}
    </>
  );
};
ActionsCell.propTypes = {
  cancelTask: PropTypes.func,
  toggleModal: PropTypes.func,
  setSelectedItem: PropTypes.func,
  user: PropTypes.object,
  row: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

const TableAction = ({ toggleAdd, version }) => (
  <button
    onClick={() => {
      toggleAdd();
      const btn = document.getElementById("create-task-btn");
      btn.blur();
    }}
    data-testid="create-task-btn"
    id="create-task-btn"
    className={classnames({ "mb-4": version }, "btn btn-primary mr-4 mr-lg-0")}
  >
    Create Task
  </button>
);
TableAction.propTypes = {
  toggleAdd: PropTypes.func,
  version: PropTypes.string
};

const TaskTable = ({
  toggleAdd,
  version,
  fetchData,
  tasks,
  totalCount,
  totalPages,
  loading,
  pageHeader,
  groups,
  tableState,
  updateTableState,
  cancelPatientTask,
  updatePatientTask,
  patient,
  showGroupsColumn,
  users,
  headerTabs
}) => {
  const isMobileWidth = UseMobileMediaQuery();
  const is768Width = UseMobileOrTabletMediaQuery();
  const [modal, setModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [hideColumnIds, setHideColumnIds] = useState([]);
  const [visibleColumns, setVisibleColumns] = useState([]);
  const { userState } = useUserContext();
  const { user } = userState;
  const { alertMethods } = useAlertContext();
  const mounted = React.useRef(false);

  useEffect(() => {
    mounted.current = true; // Will set it to true on mount ...
    return () => {
      mounted.current = false;
    }; // ... and to false on unmount
  }, []);

  const toggleModal = React.useCallback(() => {
    setModal(!modal);
  }, [modal]);

  useEffect(() => {
    if (user?.isSmartSession) setHideColumnIds([]);
    else if (version) {
      setHideColumnIds(["type", "dueDate", "actions"]);
    } else setHideColumnIds(["type", "dueDate", "actions", "patientName"]);
  }, [user?.isSmartSession, version]);

  const updateStatus = React.useCallback(
    (row, status) => {
      let fields = row;
      fields.status = status;

      taskService
        .editTask(row.id, fields)
        .then(() => {
          if (mounted.current) {
            status === 1
              ? alertMethods.success("Task has been marked as complete")
              : alertMethods.success("Task has been marked as pending");
            if (updatePatientTask) {
              updatePatientTask({ id: row.id, status: status });
            }
          }
        })
        .catch(() => {
          if (mounted.current) {
            status === 1
              ? alertMethods.error("Task could not be marked as complete")
              : alertMethods.error("Task could not be marked as pending");
          }
        });
    },
    [alertMethods, updatePatientTask]
  );

  const cancelTask = React.useCallback(
    (row) => {
      taskService
        .deleteTask(row?.id)
        .then(() => {
          if (mounted.current) {
            alertMethods.success("Task has been cancelled.");
            fetchData(tableState);
            if (cancelPatientTask) {
              cancelPatientTask(row.id);
            }
          }
        })
        .catch((e) => {
          if (mounted.current) {
            alertMethods.error("Task could not be cancelled.");
          }
        });
    },
    [alertMethods, cancelPatientTask, fetchData, tableState]
  );

  const columnCellRenders = useCallback(
    (type = "") =>
      (props) => {
        switch (type) {
          case "status":
            return (
              <StatusCell
                row={props.row}
                value={props.value}
                updateStatus={updateStatus}
              />
            );
          case "title":
            return (
              <TitleCell
                row={props.row}
                value={props.value}
                is768Width={is768Width}
                isMobileWidth={isMobileWidth}
              />
            );
          case "userName":
            return (
              <UserNameCell
                value={props.value}
                userId={props.row.original.userId}
                user={user}
                row={props.row}
                isMobileWidth={isMobileWidth}
                is768Width={is768Width}
              />
            );
          case "patientName":
            return (
              <PatientNameCell
                row={props.row}
                value={props.value}
                isMobileWidth={isMobileWidth}
              />
            );
          case "group":
            return (
              <GroupCell
                value={props.value}
                row={props.row}
                isMobileWidth={isMobileWidth}
                is768Width={is768Width}
              />
            );
          case "type":
            return (
              <TaskTypeCell value={props.value} typeOptions={taskTypeOptions} />
            );
          case "dueDate":
            return (
              <DueDateCell
                row={props.row}
                value={props.value}
                is768Width={is768Width}
                isSmartSession={user?.isSmartSession}
              />
            );
          case "actions":
            return (
              <ActionsCell
                cancelTask={cancelTask}
                toggleModal={toggleModal}
                setSelectedItem={setSelectedItem}
                user={user}
                row={props}
              />
            );
          default:
            return null;
        }
      },
    [cancelTask, is768Width, isMobileWidth, toggleModal, updateStatus, user]
  );

  columnCellRenders.propTypes = {
    type: PropTypes.string,
    props: PropTypes.shape({
      row: PropTypes.object,
      value: PropTypes.any
    })
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Status",
        disableSortBy: true,
        accessor: "status",
        Cell: columnCellRenders("status")
      },
      {
        Header: "Title",
        disableSortBy: false,
        accessor: "title",
        Cell: columnCellRenders("title")
      },
      {
        Header: "Assigned To",
        disableSortBy: false,
        accessor: "userName",
        Cell: columnCellRenders("userName")
      },
      {
        Header: () => "Patient",
        disableSortBy: false,
        accessor: "patientName",
        Cell: columnCellRenders("patientName")
      },
      {
        Header: () => "Group",
        disableSortBy: true,
        accessor: "group",
        Cell: columnCellRenders("group")
      },

      {
        Header: "Task Type",
        accessor: "type",
        disableSortBy: true,
        Cell: columnCellRenders("type")
      },
      {
        Header: "Due Date",
        disableSortBy: false,
        accessor: "dueDate",
        Cell: columnCellRenders("dueDate")
      },
      {
        Header: "",
        id: "actions",
        disableSortBy: true,
        accessor: columnCellRenders("actions")
      }
    ],
    [columnCellRenders]
  );

  //hide groups column for smart user
  useEffect(() => {
    if (user?.isSmartSession) {
      columns.splice(4, 1);
    }
  }, [columns, user?.isSmartSession]);

  useEffect(() => {
    if (version && !showGroupsColumn) {
      setVisibleColumns(
        columns.filter(
          (col) => col.accessor !== "group" && col.accessor !== "patientName"
        )
      );
    } else if (version && showGroupsColumn) {
      setVisibleColumns(
        columns.filter((col) => col.accessor !== "patientName")
      );
    } else if (!showGroupsColumn && !version) {
      setVisibleColumns(columns.filter((col) => col.accessor !== "group"));
    } else {
      setVisibleColumns(columns);
    }
  }, [columns, showGroupsColumn, version]);

  const renderRowSubComponent = React.useCallback(
    ({ row }) => (
      <>
        {isMobileWidth && (
          <>
            <tr>
              <td className="border-0 pt-0"></td>
              <td className="border-0 pt-0">
                <div className="text-left">
                  {version !== "patient" && <div>Patient</div>}
                  {showGroupsColumn && <div>Group</div>}
                  <div>Task Type</div>
                  <div>Due Date</div>
                </div>
              </td>
              <td className="border-0 pt-0">
                <div>
                  {version !== "patient" && (
                    <>
                      {row.values?.patientName ? (
                        <Link
                          className="btn btn-link p-0"
                          to={`/patients/${row.original.patientId}`}
                          state={{
                            linkBack: "/tasks",
                            backText: "to Task List"
                          }}
                        >
                          {row.values.patientName}
                        </Link>
                      ) : (
                        <div>--</div>
                      )}
                    </>
                  )}
                  {showGroupsColumn && <div>{row.values.group || "--"}</div>}
                  <div>{findType(row.values?.type) || "--"}</div>
                  <div>
                    <div>
                      <Moment format="M/D/YYYY">{row.values?.dueDate}</Moment>
                      &nbsp;&nbsp;
                      {row.original.dueTime !== "00:00:00" &&
                        "|\u00a0\u00a0" +
                          convertUTCTimeToLocalTime(row.original?.dueTime)}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={3} className="border-0 pt-0">
                <div className="d-flex justify-content-center">
                  {row.original.status !== 1 && (
                    <div className="d-flex">
                      {row.original.createdBy === user?.name ||
                      row.original.userName === "Former User" ? (
                        <button
                          className="btn btn-link text-primary pl-0 mr-3"
                          onClick={() => {
                            toggleModal(true);
                            setSelectedItem(row.original);
                          }}
                        >
                          Edit Task
                        </button>
                      ) : (
                        <></>
                      )}
                      <button
                        className="btn btn-link text-danger pl-0"
                        onClick={() => {
                          cancelTask(row.original);
                        }}
                      >
                        Cancel Task
                      </button>
                    </div>
                  )}
                </div>
              </td>
            </tr>
          </>
        )}
        {is768Width && !isMobileWidth && (
          <tr>
            <td className="border-0 pt-0"></td>
            <td className="border-0 pt-2">
              <div className="d-flex">
                <div className="text-nowrap"> Due Date</div>
                &nbsp;&nbsp; &nbsp;&nbsp;
                <div>
                  <Moment utc format="M/D/YYYY">
                    {row.values?.dueDate}
                  </Moment>
                  &nbsp;&nbsp;
                  {row.original.dueTime &&
                    row.original.dueTime !== "00:00:00" &&
                    "|\u00a0\u00a0" +
                      convertUTCTimeToLocalTime(row.original?.dueTime)}
                </div>
              </div>
              <div>
                {row.original.status !== 1 && (
                  <div className="d-flex">
                    {row.original.createdBy === user?.name ||
                    row.original.userName === "Former User" ? (
                      <button
                        className="btn btn-link text-primary pl-0 mr-3"
                        onClick={() => {
                          toggleModal(true);
                          setSelectedItem(row.original);
                        }}
                      >
                        Edit Task
                      </button>
                    ) : (
                      <></>
                    )}
                    <button
                      className="btn btn-link text-danger pl-0 "
                      onClick={() => {
                        cancelTask(row.original);
                      }}
                    >
                      Cancel Task
                    </button>
                  </div>
                )}
              </div>
            </td>
            <td className="border-0 pt-0">
              <div className="d-flex align-items-center">
                {version !== "patient" && (
                  <div className="font-weight-bold">
                    Patient &nbsp;&nbsp; &nbsp;&nbsp;
                  </div>
                )}
                {version !== "patient" && (
                  <>
                    {row.values?.patientName ? (
                      <Link
                        className="btn btn-link"
                        to={`/patients/${row.original.patientId}`}
                        state={{
                          linkBack: "/tasks",
                          backText: "to Task List"
                        }}
                      >
                        {row.values.patientName}
                      </Link>
                    ) : (
                      <div>--</div>
                    )}
                  </>
                )}
              </div>
              <div className="d-flex">
                <div className="font-weight-bold">Task Type</div> &nbsp;&nbsp;
                &nbsp;&nbsp;
                {findType(row.values.type)}
              </div>
            </td>
          </tr>
        )}
      </>
    ),
    [
      cancelTask,
      is768Width,
      isMobileWidth,
      showGroupsColumn,
      toggleModal,
      user?.name,
      version
    ]
  );

  const headerFilters = [
    {
      options: [
        { value: "0", label: "General" },
        { value: "1", label: "Call" },
        { value: "2", label: "Monitor" },
        { value: "3", label: "Patient Action" }
      ],
      column: "type",
      name: "Task Type",
      multi: true
    }
  ];

  return (
    <div>
      <div
        id="task-table"
        className={classnames({ "smart-widths": !showGroupsColumn && version })}
      >
        <NewGTable
          type="tasks"
          columns={visibleColumns}
          data={tasks}
          emptyColSpan={"7alt"}
          fetchData={fetchData}
          pageCount={totalPages}
          totalCount={totalCount}
          headerTabs={headerTabs}
          loading={loading}
          tableState={tableState}
          updateTableState={updateTableState}
          // check for filters
          emptyMsg={"You have no tasks matching the selected filters."}
          headerFilters={!version ? headerFilters : null}
          tableButton={
            !version && <TableAction toggleAdd={toggleAdd} version={version} />
          }
          mobileTableButton={
            // Add props to table action compoenent so it can toggle the modal and the padding is correct
            version &&
            is768Width && (
              <TableAction toggleAdd={toggleAdd} version={version} />
            )
          }
          rowExpands={is768Width}
          pageHeader={pageHeader}
          noSearch={true}
          hideColumnIds={hideColumnIds}
          mobileHideColumnIds={
            version && !showGroupsColumn
              ? ["type", "dueDate", "actions"]
              : ["group"]
          }
          renderRowSubComponent={renderRowSubComponent}
          mobileCarousel={true}
          version={version}
        />
      </div>
      <Modal
        isOpen={modal}
        toggle={toggleModal}
        className="center-modal scroll-modal"
      >
        <ModalHeader
          toggle={toggleModal}
          close={<CloseButton toggle={toggleModal} />}
        >
          Edit Task
        </ModalHeader>
        <ModalBody>
          <TaskForm
            version="edit"
            selectedItem={selectedItem}
            groups={groups}
            toggleAdd={toggleModal}
            toggleEdit={toggleModal}
            fetchData={fetchData}
            patient={patient}
            users={users}
            tableState={tableState}
          />
        </ModalBody>
      </Modal>
    </div>
  );
};

TaskTable.propTypes = {
  toggleAdd: PropTypes.func,
  version: PropTypes.string,
  fetchData: PropTypes.func,
  tasks: PropTypes.array,
  totalCount: PropTypes.number,
  totalPages: PropTypes.number,
  loading: PropTypes.bool,
  filters: PropTypes.array,
  search: PropTypes.string,
  setFrontEndFilter: PropTypes.func,
  filteredTasks: PropTypes.array,
  frontEndFilter: PropTypes.string,
  pageHeader: PropTypes.object,
  groups: PropTypes.array,
  tableState: PropTypes.object,
  updateTableState: PropTypes.func,
  cancelPatientTask: PropTypes.func,
  updatePatientTask: PropTypes.func,
  patient: PropTypes.object,
  showGroupsColumn: PropTypes.bool,
  users: PropTypes.array
};

export { TaskTable };
