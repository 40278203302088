import classnames from "classnames";
import update from "immutability-helper";
import PropTypes from "prop-types";
import React, { useEffect, useState, lazy } from "react";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Link } from "react-router-dom";
import {
  useAsyncDebounce,
  useExpanded,
  useFilters,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable
} from "react-table";
import { Row, Table } from "reactstrap";
import {
  EmptyTable,
  HeaderActions,
  HeaderFilters,
  HeaderMenu,
  HeaderTabs,
  MoveableRow,
  MyTooltip,
  TablePagination,
  TableSearch
} from "../_components";
import {
  UseMobileMediaQuery,
  UseMobileOrTabletMediaQuery
} from "../_helpers/media-queries";
import { ReactComponent as Down } from "../images/chevron_down_small.svg";
import { ReactComponent as Up } from "../images/chevron_up_small.svg";
import { ReactComponent as Download } from "../images/download.svg";
import { ReactComponent as Info } from "../images/glyph_info.svg";
import { HeaderDropdown } from "./HeaderDropdown";
import { TableSkeletons } from "./TableSkeletons";
import SyncActionBtn from "./SyncActionBtn";
// lazy load dndprovider, which is not the default export
const DndProvider = lazy(() =>
  import("react-dnd").then((module) => ({ default: module.DndProvider }))
);

const NoDataTable = ({
  noData,
  page,
  loading,
  emptyColSpan,
  emptyImg,
  otherEmptyMessage,
  emptyMsg,
  isMobileWidth
}) => {
  return (
    <>
      {noData ? (
        <EmptyTable
          page={page}
          loading={loading}
          emptyColSpan={emptyColSpan}
          emptyImg={emptyImg}
          noPatientsMsg={<CallToActionNoPatients />}
        />
      ) : (
        <EmptyTable
          page={page}
          loading={loading}
          emptyColSpan={emptyColSpan}
          emptyImg={emptyImg}
          otherEmptyMessage={otherEmptyMessage}
          emptyMsg={emptyMsg}
        />
      )}
      {loading && (
        <TableSkeletons
          isMobileWidth={isMobileWidth}
          emptyColSpan={emptyColSpan}
        />
      )}
    </>
  );
};
NoDataTable.propTypes = {
  noData: PropTypes.bool,
  page: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  loading: PropTypes.bool,
  emptyColSpan: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  emptyImg: PropTypes.object,
  otherEmptyMessage: PropTypes.string,
  emptyMsg: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  isMobileWidth: PropTypes.bool
};

const TableHeader = ({
  headerGroup,
  isMobileWidth,
  headerFilters,
  filtersArr,
  handleFilterChange,
  rightAlignedTableAction,
  clearAllFilters,
  isSyncActionBtn,
  onDataChange
}) => {
  return (
    <>
      {headerGroup.headers.map((column, i) => (
        <th {...column.getHeaderProps()}>
          {column.canSort && !isMobileWidth && (
            <span
              {...column.getSortByToggleProps()}
              tabIndex="0"
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === "Shift")
                  column.toggleSortBy();
              }}
              className="focusable-text"
            >
              {column.render("Header")}
              {column.isSortedDesc || !column.isSorted ? (
                <Down
                  data-testid="sort-down"
                  aria-label="sort-down"
                  className="ml-2 h-7px"
                />
              ) : (
                <Up
                  className="ml-2"
                  aria-label="sort-up"
                  data-testid="sort-up"
                />
              )}
            </span>
          )}
          {(!column.canSort || isMobileWidth) && (
            <span
              aria-label="header"
              className="d-flex justify-content-start align-items-center"
            >
              {column.render("Header")}
              {i === headerGroup.headers.length - 1 && isMobileWidth && (
                <>
                  <HeaderFilters
                    headerFilters={headerFilters}
                    handleFilterChange={handleFilterChange}
                    rightAlignedTableAction={rightAlignedTableAction}
                    clearAllFilters={clearAllFilters}
                    filtersArr={filtersArr}
                  />
                  {isSyncActionBtn && (
                    <SyncActionBtn fetchData={onDataChange} />
                  )}
                </>
              )}
            </span>
          )}
        </th>
      ))}
    </>
  );
};
TableHeader.propTypes = {
  headerGroup: PropTypes.object,
  isMobileWidth: PropTypes.bool,
  headerFilters: PropTypes.array,
  filtersArr: PropTypes.array,
  handleFilterChange: PropTypes.func,
  clearAllFilters: PropTypes.func,
  rightAlignedTableAction: PropTypes.func,
  isSyncActionBtn: PropTypes.bool,
  onDataChange: PropTypes.func
};

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <div>
        <label className="g-table-checkbox">
          <input
            type="checkbox"
            ref={resolvedRef}
            {...rest}
            aria-label="checkbox"
          />
          <span className="checkmark"></span>
        </label>
      </div>
    );
  }
);
IndeterminateCheckbox.propTypes = {
  indeterminate: PropTypes.bool
};

const pageOptions = [
  { value: 25, label: "25 items per page" },
  { value: 50, label: "50 items per page" },
  { value: 75, label: "75 items per page" }
];
const mobilePageOptions = [
  { value: 25, label: "25 per page" },
  { value: 50, label: "50 per page" },
  { value: 75, label: "75 per page" }
];

const smallPageOptions = [
  { value: 5, label: "5 per page" },
  { value: 10, label: "10 per page" },
  { value: 20, label: "20 per page" }
];

const SelectionCell = ({ row, selectConditionColumn }) => {
  const type = row.original.function ? "User" : "Patient";

  if (
    !selectConditionColumn ||
    (!row.original.isInvitationAccepted &&
      (row.original.invitationStatus === 3 ||
        row.original.invitationStatus === 1 ||
        row.original.invitationStatus === 4 ||
        row.original.status === 3 ||
        row.original.status === 1))
  ) {
    return <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />;
  } else if (
    row.original.status === 4 &&
    row.original.statusDescription === "Withdrawn"
  ) {
    return (
      <div>
        <Info
          aria-label="withdrawn-invitation"
          id={`info-${row.original.id}-${row.original.userId}`}
          href="#"
        />
        <MyTooltip
          target={`info-${row.original.id}-${row.original.userId}`}
          text={`${type} cannot be selected as the invitation has been withdrawn`}
        />
      </div>
    );
  } else if (row.original.status >= 6) {
    return (
      <div>
        <Info
          aria-label="disconnected-revoked-invitation"
          id={`info-${row.original.id}-${row.original.userId}`}
          href="#"
        />
        <MyTooltip
          target={`info-${row.original.id}-${row.original.userId}`}
          text={`Patient cannot be selected as the invitation has been ${row.original.status === 6 ? "disconnected" : "revoked"
            }`}
        />
      </div>
    );
  } else {
    return (
      <div>
        <Info
          aria-label="accepted-invitation"
          id={`info-${row.original.id}-${row.original.userId}`}
          href="#"
        />
        <MyTooltip
          target={`info-${row.original.id}-${row.original.userId}`}
          text={`${type} cannot be selected as they have already accepted`}
        />
      </div>
    );
  }
};

SelectionCell.propTypes = {
  row: PropTypes.object,
  selectConditionColumn: PropTypes.string
};

const CallToActionNoPatients = () => {
  return (
    <div>
      There are no active patients.
      <div className="text-center large align-items-baseline d-flex justify-content-center">
        <span className="font-weight-normal">To get started,&nbsp; </span>
        <Link to="/invite/patient/manual" className="btn btn-link p-0">
          Invite a patient
        </Link>
      </div>
    </div>
  );
};

const NewGTable = ({
  columns,
  data,
  fetchData,
  loading,
  pageCount: controlledPageCount,
  totalCount,
  headerFilters,
  headerTabs,
  headerActions,
  emptyMsg,
  emptyImg,
  otherEmptyMessage,
  setSelectedRows,
  selectedRows,
  selectConditionColumn,
  emptyColSpan,
  searchMessage,
  hideColumnIds,
  mobileHideColumnIds,
  renderRowSubComponent,
  dataChange,
  noSearch,
  setPatientListId,
  setList,
  moveableRows,
  records,
  setRecords,
  tableButton,
  setVisibleTable,
  type,
  id,
  columnsToHide,
  mobileAction,

  mobileCarousel,
  leftSearch,
  rightAlignedTableAction,
  visibleTable,
  rowExpands,
  patientListId,
  pageHeader,
  secondaryTableButtons,
  tableHeader,
  mobileTableButton,
  updateTableState,
  tableState,
  downloadReport,
  downloadReportTooltipText,
  onCard,
  defaultPageSize,
  hideColumnWidth,
  asterisk,
  headerMenuOptions,
  headerMenuText,
  isSyncActionBtn,
  setDataChange,
  noPagination
}) => {
  const is768Width = UseMobileOrTabletMediaQuery();
  const isMobileWidth = UseMobileMediaQuery();

  // if there is no hideColumnWidth, set it to is768Width
  if (!hideColumnWidth) {
    hideColumnWidth = is768Width;
  }

  const getRowId = React.useCallback((row) => {
    if (row) {
      return row.id || row.patientId || row.userId;
    }
  }, []);
  const mounted = React.useRef(false);
  React.useEffect(() => {
    mounted.current = true; // Will set it to true on mount ...
    return () => {
      mounted.current = false;
    }; // ... and to false on unmount
  }, []);

  // Use the state and functions returned from useTable to build your UI
  const tableInstance = useTable(
    {
      columns,
      data: records || data,
      getRowId,
      initialState: {
        pageIndex: tableState?.pageIndex || 1,
        pageSize: tableState?.pageSize || defaultPageSize || 50,
        sortBy: tableState?.sortBy || [],
        filters: tableState?.filters || []
      },
      manualSortBy: true,
      autoResetSortBy: false,
      manualPagination: true,
      autoResetPage: false,
      manualFilters: true,
      autoResetFilters: false,
      autoResetGroupBy: false,
      pageCount: controlledPageCount + 1
    },

    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (headerActions) {
        hooks.visibleColumns.push((cols) => [
          {
            id: "selection",
            Header: "",
            Cell: (props) => {
              return (
                <SelectionCell
                  row={props.row}
                  selectConditionColumn={selectConditionColumn}
                />
              );
            }
          },
          ...cols
        ]);
      }
    }
  );

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    selectedFlatRows,
    page,
    gotoPage,
    toggleAllRowsSelected,
    toggleHideColumn,
    setPageSize,
    state: { pageIndex, pageSize, sortBy }
  } = tableInstance;

  // State for table componenets
  const [searchInput, setSearchInput] = useState(tableState?.searchInput || "");
  const [searchFieldVal, setSearchFieldVal] = useState(
    tableState?.searchInput || ""
  );
  const [headerMenuVal, setHeaderMenuVal] = useState(
    tableState?.headerMenuVal || (headerMenuOptions && headerMenuOptions[0])
  );
  const [allRowsSelected, setAllRowsSelected] = useState(false);
  const [filterUsed, setFilterUsed] = useState(false);

  const [activeTab, setActiveTab] = useState(0);
  const [noData, setNoData] = useState(false);
  const [filtersArr, setFiltersArr] = useState(
    tableState?.filters
      ? tableState.filters
      : headerFilters?.map((fltr) => {
        return { id: fltr.column, value: [] };
      })
  );

  useEffect(() => {
    if (mounted.current && updateTableState) {
      updateTableState({
        activeTab: activeTab
      });
    }
  }, [activeTab, updateTableState]);
  
  //Check if there are no patients at all
  useEffect(() => {
    if (
      !data.length &&
      !loading &&
      headerTabs &&
      headerTabs[activeTab]?.name === "All Patients" &&
      !searchInput &&
      !filterUsed &&
      headerMenuVal?.label !== "All Invitations"
    ) {
      setNoData(true);
    } else setNoData(false);
  }, [
    activeTab,
    data,
    filterUsed,
    headerMenuOptions,
    headerMenuVal,
    headerTabs,
    loading,
    searchInput
  ]);

  //If a filter has been applied and saved in table state, apply that same filter
  useEffect(() => {
    if (tableState?.tab && headerTabs)
      setActiveTab(
        headerTabs.indexOf(
          headerTabs.find((item) => item.id === tableState.tab)
        )
      );
  }, [tableState, headerTabs]);

  // If a header menu option has been applied and saved in table state, apply that same option
  useEffect(() => {
    if (tableState?.headerMenuVal) {
      setHeaderMenuVal(
        headerMenuOptions.find((item) => item === tableState.headerMenuVal)
      );
    }
  }, [headerMenuOptions, tableState]);

  //If selected rows are reset from the table also uncheck the select all checkbox
  useEffect(() => {
    if (selectedRows && !selectedRows.length) setAllRowsSelected(false);
  }, [selectedRows]);

  // Hiding columns and showing them as width changes
  useEffect(() => {
    if (hideColumnIds && hideColumnWidth) {
      hideColumnIds.forEach((item) => toggleHideColumn(item, true));
    }
    if (mobileHideColumnIds && isMobileWidth && hideColumnIds) {
      hideColumnIds.forEach((item) => toggleHideColumn(item, true));
      mobileHideColumnIds.forEach((item) => toggleHideColumn(item, true));
    }
    if (
      !isMobileWidth &&
      hideColumnWidth &&
      hideColumnIds &&
      mobileHideColumnIds
    ) {
      hideColumnIds.forEach((item) => toggleHideColumn(item, true));
      mobileHideColumnIds.forEach((item) => toggleHideColumn(item, false));
    }
    if (
      !isMobileWidth &&
      hideColumnWidth &&
      !hideColumnIds &&
      mobileHideColumnIds
    ) {
      mobileHideColumnIds.forEach((item) => toggleHideColumn(item, false));
    }
    if (hideColumnIds && !hideColumnWidth && !isMobileWidth) {
      mobileHideColumnIds.forEach((item) => toggleHideColumn(item, false));
      hideColumnIds.forEach((item) => toggleHideColumn(item, false));
    }
  }, [
    is768Width,
    isMobileWidth,
    hideColumnIds,
    mobileHideColumnIds,
    toggleHideColumn,
    hideColumnWidth
  ]);

  //Fetch data when filters, sorts, searches are applied
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      let sortStr = null;
      if (sortBy.length > 0) {
        sortStr = `${sortBy[0].id}.`;
        sortStr = sortBy[0].desc ? `${sortStr}DESC` : `${sortStr}ASC`;
      }
      fetchData({
        searchInput,
        pageIndex,
        pageSize,
        sortStr,
        filtersArr,
        headerMenuVal,
        activeTab
      });
    }
    return function cleanup() {
      mounted = false;
    };
  }, [
    fetchData,
    searchInput,
    pageIndex,
    pageSize,
    sortBy,
    filtersArr,
    dataChange,
    headerMenuVal,
    activeTab
  ]);

  // If filtersArr changes, update table state context
  useEffect(() => {
    if (mounted.current && updateTableState) {
      updateTableState({
        filters: filtersArr
      });
    }
  }, [filtersArr, updateTableState]);

  // If search input changes, update table state context
  useEffect(() => {
    if (mounted.current && updateTableState) {
      updateTableState({
        searchInput
      });
    }
  }, [searchInput, updateTableState]);

  // If page index changes, update table state context
  useEffect(() => {
    if (mounted.current && updateTableState) {
      updateTableState({
        pageIndex
      });
    }
  }, [pageIndex, updateTableState]);

  useEffect(() => {
    if (mounted.current && updateTableState) {
      updateTableState({
        pageSize
      });
    }
  }, [pageSize, updateTableState]);

  // If sort by changes, update table state context
  useEffect(() => {
    if (mounted.current && updateTableState) {
      updateTableState({
        sortBy
      });
    }
  }, [sortBy, updateTableState]);

  // If header menu value changes, update table state context
  useEffect(() => {
    if (mounted.current && updateTableState) {
      updateTableState({
        headerMenuVal: headerMenuVal
      });
    }
  }, [headerMenuVal, updateTableState]);

  //Sometimes we have to use selected rows in other components (like when resending/withdrawing invitations)
  useEffect(() => {
    let selected;
    if (selectConditionColumn) {
      selected = selectedFlatRows
        .map((row) => row.original)
        .filter((item) => !item[selectConditionColumn])
        .filter(
          (item) =>
            item.status === 1 ||
            item.status === 3 ||
            item.invitationStatus === 1 ||
            item.invitationStatus === 3 ||
            item.invitationStatus === 4
        );
    } else selected = selectedFlatRows;

    if (setSelectedRows) setSelectedRows(selected);
  }, [selectedFlatRows, setSelectedRows, selectConditionColumn]);

  // Other functions called on actions (not as side effects)
  const moveRow = (dragIndex, hoverIndex) => {
    const dragRecord = records[dragIndex];
    if (dragRecord) {
      setRecords(
        update(records, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRecord]
          ]
        })
      );
    }
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  //Unselect when navigating between pages
  const handlePageClick = (pg) => {
    if (mounted.current) {
      if (allRowsSelected) setAllRowsSelected(false);
      const selected = pg.currentPage;
      gotoPage(selected);
    }
  };

  const clearAllFilters = () => {
    setFiltersArr(
      headerFilters?.map((fltr) => {
        return { id: fltr.column, value: [] };
      })
    );
  };

  const handleFilterChange = (name, option, isMulti) => {
    if (isMulti) {
      setFiltersArr(
        filtersArr.map((fltr) => {
          if (fltr.id === name) {
            if (fltr.value.indexOf(option.value) < 0) {
              gotoPage(1);
              setFilterUsed(true);
              return { ...fltr, value: fltr.value.concat(option.value) };
            } else {
              return {
                ...fltr,
                value: fltr.value.filter((val) => val !== option.value)
              };
            }
          } else return fltr;
        })
      );
    } else {
      setFiltersArr(
        filtersArr.map((fltr) => {
          if (fltr.id === name) {
            gotoPage(1);
            setFilterUsed(true);
            return { ...fltr, value: [option.value] };
          } else return fltr;
        })
      );
    }
  };

  const handleTabChange = (name, i) => {
    gotoPage(1);
    toggle(i);
    if (allRowsSelected) setAllRowsSelected(false);
    if (name === "subscribed" || name === "unsubscribed") {
      setList(name);
    } else if (setPatientListId) {
      setPatientListId(name);
    }
  };

  const handleSearchChange = useAsyncDebounce((value) => {
    if (mounted.current) {
      gotoPage(1);
      handlePageClick({ selected: 0 });
      setSearchInput(value);
      if (allRowsSelected) setAllRowsSelected(false);
    }
  }, 300);

  // handle header menu change
  const handleHeaderMenuChange = (name, option) => {
    gotoPage(1);
    setHeaderMenuVal(option);
  };

  const handleToggleAllRows = () => {
    setAllRowsSelected(!allRowsSelected);
    toggleAllRowsSelected(!allRowsSelected);
  };

  // Render the UI for your table
  return (
    <>
      {/* Elements sometimes appear above the table card for mhandleFilterChangeobile/tablet width tables */}
      <div
        className={classnames(
          "px-lg-0 d-flex flex-wrap justify-content-between"
        )}
      >
        <div className="d-flex align-items-baseline">
          {pageHeader}
          {headerMenuOptions && (
            <HeaderMenu
              options={headerMenuOptions}
              value={headerMenuVal}
              handleHeaderMenuChange={handleHeaderMenuChange}
              headerMenuText={headerMenuText}
            />
          )}
        </div>
        <div
          className={classnames({
            "d-flex align-self-end mb-4 mx-lg-0 mx-4": pageHeader
          })}
        >
          {/* Search appears above table if mobile or tablet width */}
          <TableSearch
            searchMessage={searchMessage}
            searchFieldVal={searchFieldVal}
            setSearchFieldVal={setSearchFieldVal}
            handleSearchChange={handleSearchChange}
            leftSearch={leftSearch}
            mobileAction={mobileAction}
            is768Width={is768Width}
            noSearch={noSearch}
          />
          {tableButton ? (
            <div className={classnames({ "w-100": isMobileWidth })}>
              {secondaryTableButtons?.map((btn, i) => (
                <span key={btn.key}>{btn}</span>
              ))}
              {tableButton}
            </div>
          ) : (
            <></>
          )}
          {downloadReport ? (
            <>
              <button
                className="btn btn-small bg-white ml-3 btn-outline-graydark1"
                onClick={() => {
                  let sortStr = null;
                  if (sortBy.length > 0) {
                    sortStr = `${sortBy[0].id}.`;
                    sortStr = sortBy[0].desc
                      ? `${sortStr}DESC`
                      : `${sortStr}ASC`;
                  }
                  downloadReport({
                    searchInput,
                    sortStr,
                    filtersArr,
                    headerMenuVal
                  });
                }}
                id="create-report"
                tabIndex="0"
              >
                <Download
                  aria-label="create-report"
                  className="glyph-gray h-25px w-25px"
                />
              </button>
              <MyTooltip
                target="create-report"
                text={downloadReportTooltipText || "Create PDF of table"}
              />
            </>
          ) : (
            <></>
          )}
        </div>
        {mobileTableButton && (
          <div className="ml-auto mr-4 mb-2">{mobileTableButton}</div>
        )}

        {/* Right now this is only for task table but I assume other tables could have something here as welll */}
      </div>

      <div className={classnames({ card: !onCard }, "table bg-white")}>
        <Row
          className={classnames(
            " py-3 align-items-center",
            "mr-0 ml-0",
            {
              "d-none": type === "admin"
            },
            { "d-none": type === "no-header" },
            { "ml-3": id === "manage-invites" || id === "user-invites" }
          )}
        >
          {tableHeader}
          {(headerTabs?.length > 5 || isMobileWidth) &&
            headerTabs &&
            !mobileCarousel && (
              <HeaderDropdown
                headerTabs={headerTabs}
                patientListId={patientListId}
                setVisibleTable={setVisibleTable}
                setList={setList}
                handleTabChange={handleTabChange}
                activeTab={activeTab}
                visibleTable={visibleTable}
              />
            )}
          {((headerTabs?.length <= 5 && !isMobileWidth) || mobileCarousel) && (
            <HeaderTabs
              activeTab={activeTab}
              headerTabs={headerTabs}
              handleTabChange={handleTabChange}
              is768Width={is768Width}
              isMobileWidth={isMobileWidth}
              mobileCarousel={mobileCarousel}
              updateTableState={updateTableState}
            />
          )}
          <HeaderActions
            headerActions={headerActions}
            allRowsSelected={allRowsSelected}
            handleToggleAllRows={handleToggleAllRows}
            data={data}
            selectedFlatRows={selectedFlatRows}
            selectedRows={selectedRows}
            selectConditionColumn={selectConditionColumn}
          />
          {!isMobileWidth && (
            <div className="d-flex ml-auto align-items-center mr-4">
              <HeaderFilters
                headerFilters={headerFilters}
                filtersArr={filtersArr}
                handleFilterChange={handleFilterChange}
                clearAllFilters={clearAllFilters}
                rightAlignedTableAction={rightAlignedTableAction}
              />
              {isSyncActionBtn && (
                <SyncActionBtn fetchData={() => setDataChange(!dataChange)} />
              )}
            </div>
          )}
        </Row>
        <div className="g-table-fix-head mb-2">
          {moveableRows ? (
            <DndProvider backend={HTML5Backend}>
              <Table className="g-table" {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      <th>
                        <span></span>
                      </th>

                      <TableHeader
                        headerGroup={headerGroup}
                        isMobileWidth={isMobileWidth}
                        headerFilters={headerFilters}
                        handleFilterChange={handleFilterChange}
                        rightAlignedTableAction={rightAlignedTableAction}
                        clearAllFilters={clearAllFilters}
                        filtersArr={filtersArr}
                        isSyncActionBtn={isSyncActionBtn}
                        onDataChange={() => setDataChange(!dataChange)}
                      />
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <React.Fragment key={row.getRowProps().key}>
                        {moveableRows && (
                          <MoveableRow
                            index={i}
                            row={row}
                            data={records}
                            moveRow={moveRow}
                            {...row.getRowProps()}
                            renderRowSubComponent={renderRowSubComponent}
                            is768Width={is768Width}
                            isMobileWidth={isMobileWidth}
                          />
                        )}
                      </React.Fragment>
                    );
                  })}
                  <NoDataTable
                    noData={noData}
                    page={page}
                    loading={loading}
                    emptyColSpan={emptyColSpan}
                    emptyImg={emptyImg}
                    otherEmptyMessage={otherEmptyMessage}
                    emptyMsg={emptyMsg}
                    isMobileWidth={isMobileWidth}
                  />
                  {/* If and only if there are no patients at all, show this empty call to action  */}
                </tbody>
              </Table>
            </DndProvider>
          ) : (
            <Table className="g-table" {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    <TableHeader
                      headerGroup={headerGroup}
                      isMobileWidth={isMobileWidth}
                      headerFilters={headerFilters}
                      handleFilterChange={handleFilterChange}
                      rightAlignedTableAction={rightAlignedTableAction}
                      clearAllFilters={clearAllFilters}
                      filtersArr={filtersArr}
                      isSyncActionBtn={isSyncActionBtn}
                      onDataChange={() => setDataChange(!dataChange)}
                    />
                  </tr>
                ))}
              </thead>
              <tbody>
                {page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <React.Fragment key={row.getRowProps().key}>
                      <tr>
                        {row.cells.map((cell, idx) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>

                      {/* /*
                If the row is in an expanded state, render a row with a
                column that fills the entire length of the table.
               */}
                      {row.isExpanded && rowExpands ? (
                        // <tr>
                        <>
                          {/*
                      Inside it, call our renderRowSubComponent function. In reality,
                      you could pass whatever you want as props to
                      a component like this, including the entire
                      table instance. But for this example, we'll just
                      pass the row
                    */}
                          {renderRowSubComponent({ row })}
                        </>
                      ) : null}
                    </React.Fragment>
                  );
                })}
                {/* If and only if there are no patients at all, show this empty call to action  */}
                <NoDataTable
                  noData={noData}
                  page={page}
                  loading={loading}
                  emptyColSpan={emptyColSpan}
                  emptyImg={emptyImg}
                  otherEmptyMessage={otherEmptyMessage}
                  emptyMsg={emptyMsg}
                  isMobileWidth={isMobileWidth}
                />
              </tbody>
            </Table>
          )}
          <div className="text-secondary">{asterisk}</div>
        </div>
      </div>
      {!noPagination ? (
        <TablePagination
          totalCount={totalCount}
          pageIndex={pageIndex}
          pageSize={pageSize}
          page={page}
          pageOptions={defaultPageSize === 5 ? smallPageOptions : pageOptions}
          mobilePageOptions={
            defaultPageSize === 5 ? smallPageOptions : mobilePageOptions
          }
          setPageSize={setPageSize}
          gotoPage={gotoPage}
          isMobileWidth={defaultPageSize === 5 ? true : isMobileWidth}
          controlledPageCount={controlledPageCount}
          handlePageClick={handlePageClick}
          loading={loading}
          onCard={onCard}
        />
      ) : (
        <div className="text-secondary px-4 pt-4 pb-7">
          {totalCount > 0 && `Showing 1 - ${page.length} of ${totalCount}`}
        </div>
      )}
    </>
  );
};

NewGTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  fetchData: PropTypes.func,
  loading: PropTypes.bool,
  pageCount: PropTypes.number,
  totalCount: PropTypes.number,
  headerFilters: PropTypes.array,
  headerTabs: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  headerActions: PropTypes.array,
  emptyMsg: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  emptyImg: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  otherEmptyMessage: PropTypes.string,
  setSelectedRows: PropTypes.func,
  selectedRows: PropTypes.array,
  selectConditionColumn: PropTypes.string,
  emptyColSpan: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  searchMessage: PropTypes.string,
  hideColumnIds: PropTypes.array,
  mobileHideColumnIds: PropTypes.array,
  renderRowSubComponent: PropTypes.func,
  dataChange: PropTypes.bool,
  noSearch: PropTypes.bool,
  setPatientListId: PropTypes.func,
  setList: PropTypes.func,
  moveableRows: PropTypes.bool,
  records: PropTypes.array,
  setRecords: PropTypes.func,
  tableButton: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  setVisibleTable: PropTypes.func,
  type: PropTypes.string,
  columnsToHide: PropTypes.array,
  mobileAction: PropTypes.object,
  setFrontEndFilter: PropTypes.func,
  frontEndFilter: PropTypes.string,
  mobileCarousel: PropTypes.bool,
  leftSearch: PropTypes.bool,
  rightAlignedTableAction: PropTypes.object,
  visibleTable: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rowExpands: PropTypes.bool,
  patientListId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pageHeader: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  secondaryTableButtons: PropTypes.array,
  tableHeader: PropTypes.object,
  mobileTableButton: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  updateTableState: PropTypes.func,
  tableState: PropTypes.object,
  downloadReport: PropTypes.func,
  downloadReportTooltipText: PropTypes.string,
  onCard: PropTypes.bool,
  defaultPageSize: PropTypes.number,
  hideColumnWidth: PropTypes.bool,
  asterisk: PropTypes.string,
  headerMenuOptions: PropTypes.array,
  id: PropTypes.string,
  headerMenuText: PropTypes.string,
  isSyncActionBtn: PropTypes.bool,
  onDataChange: PropTypes.func
};

export { NewGTable };
