import React from "react";
import { ReactComponent as GlyphX } from "../images/glyph_x.svg";
import PropTypes from "prop-types";

const CloseButton = ({ toggle, color }) => {
  return (
    <button className="close ml-auto" onClick={toggle}>
      <GlyphX className={color} aria-label="close" />
    </button>
  );
};

CloseButton.propTypes = {
  toggle: PropTypes.func.isRequired,
  color: PropTypes.string
};

export { CloseButton };
