import { ErrorMessage, Field } from "formik";
import PropTypes from "prop-types";
import React from "react";
import { CustomTimeOfDayPicker } from "../../_components";
import { ReactComponent as GlyphMinus } from "../../images/glyph-circle-minus.svg";
import classNames from "classnames";

export const Dose = ({
  idx,
  setFieldTouched,
  setFieldValue,
  label,
  timeComponent,
  values
}) => {
  return (
    <>
      <div className="row align-items-center">
        <div className="col-10 d-flex align-items-baseline">
          {label && <label htmlFor="dose">Dose {idx + 1}:</label>}
          <span>&nbsp; Take &nbsp; </span>
          <Field
            data-testid="dose"
            id="dose"
            name="dose"
            onChange={(e) =>
              setFieldValue(
                `medicationScheduleTimes[${idx}].dose`,
                e.target.value
              )
            }
            onBlur={setFieldTouched}
            type="number"
            placeholder="__"
            min={0.5}
            step="any"
            max={9999}
            disabled={values.medicationScheduleTimes?.length === 1}
            className={classNames(
              "form-control-no-border bg-gray",
              {
                xs:
                  values.medicationScheduleTimes[idx].dose?.toString().length <=
                    2 && values.medicationScheduleTimes[idx].dose
              },
              {
                small:
                  !values.medicationScheduleTimes[idx].dose ||
                  values.medicationScheduleTimes[idx].dose?.toString()
                    .length === 2
              },
              {
                med:
                  values.medicationScheduleTimes[idx].dose?.toString()
                    .length === 3
              },
              {
                large:
                  values.medicationScheduleTimes[idx].dose?.toString()
                    .length === 4
              },
              {
                xl:
                  values.medicationScheduleTimes[idx].dose?.toString().length >=
                  5
              }
            )}
            value={values.medicationScheduleTimes[idx].dose}
          ></Field>
          {values.doseType?.label}&nbsp;&nbsp;
          {timeComponent && (
            <>
              <div>
                {values.medicationScheduleTimes[idx]?.partOfDay?.value < 3
                  ? "in the"
                  : "at"}
              </div>
              <CustomTimeOfDayPicker
                idx={idx}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                values={values}
              />
            </>
          )}
        </div>
        <div className="col-2">
          {/* if it's a frequency type cyle and it is not the first dose, add a delete button */}
          {values.frequency?.label === "Cycle" && (
            <button
              type="button"
              className="close minus-btn mt-neg-8px"
              onClick={() => {
                const newDoseTimes = values.medicationScheduleTimes.filter(
                  (dose, index) => index !== idx
                );
                setFieldValue("medicationScheduleTimes", newDoseTimes);
              }}
            >
              <GlyphMinus className="glyph-danger" aria-label="delete-dose" />
            </button>
          )}
        </div>
      </div>
      <ErrorMessage
        name={`medicationScheduleTimes[${idx}].time`}
        component="div"
        className="invalid-feedback"
      />
    </>
  );
};

Dose.propTypes = {
  idx: PropTypes.number,
  setFieldTouched: PropTypes.func,
  setFieldValue: PropTypes.func,
  label: PropTypes.bool,
  timeComponent: PropTypes.bool,
  values: PropTypes.object
};
