import classnames from "classnames";
import React, { useEffect, useState } from "react";
import {
  useExpanded,
  usePagination,
  useRowSelect,
  useTable
} from "react-table";
import { Row, Table } from "reactstrap";
import { EmptyTable, HeaderTabs } from "../_components";
import { useUserContext } from "../context/userContext";
import PropTypes from "prop-types";
import { isIE } from "../_helpers";

const MyStaticTable = ({
  columns,
  data,
  loading,
  errorMsg,
  emptyColSpan,
  smartColumnsToHide,
  className,
  mobileColumnsToHide,
  isMobileWidth,
  renderRowSubComponent,
  multiSelect,
  bulkUpdate,
  headerTabs,
  setMedlist,
  medications,
  tableAction,
  setSelectedRows,
  pagination,
  headerTabsDisabled,
  selectedRows,
  setClaimsData,
  dummyClaimsData,
  setMedData,
  activeTab,
  setActiveTab,
  print
}) => {
  // Use the state and functions returned from useTable to build your UI
  const tableInstance = useTable(
    { columns, data },
    useExpanded,
    usePagination,
    useRowSelect
  );
  const { userState } = useUserContext();
  const { user } = userState;
  const {
    getTableProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    toggleHideColumn,
    selectedFlatRows,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    toggleAllRowsSelected,
    state: { pageIndex }
  } = tableInstance;
  const [dataToMap, setDataToMap] = useState(rows);

  useEffect(() => {
    if (pagination) {
      setDataToMap(page);
    } else {
      setDataToMap(rows);
    }
  }, [page, rows, pagination]);

  useEffect(() => {
    if (!selectedRows) {
      toggleAllRowsSelected(false);
    }
  }, [selectedRows, toggleAllRowsSelected]);

  useEffect(() => {
    const selected = selectedFlatRows.map((row) => row.original);
    if (setSelectedRows) setSelectedRows(selected);
  }, [selectedFlatRows, setSelectedRows]);

  useEffect(() => {
    if (smartColumnsToHide && !user?.isSmartSession) {
      smartColumnsToHide.forEach((item) => toggleHideColumn(item, true));
    } else if (smartColumnsToHide && user?.isSmartSession) {
      smartColumnsToHide.forEach((item) => toggleHideColumn(item, false));
    }
    if (isMobileWidth && mobileColumnsToHide) {
      mobileColumnsToHide.forEach((item) => toggleHideColumn(item, true));
    } else if (!isMobileWidth && mobileColumnsToHide) {
      mobileColumnsToHide.forEach((item) => toggleHideColumn(item, false));
    }
  }, [
    isMobileWidth,
    user,
    mobileColumnsToHide,
    smartColumnsToHide,
    toggleHideColumn
  ]);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const handleTabChange = (name, i) => {
    toggle(i);
    if (name === "ACTIVE") {
      setMedlist(medications.filter((val) => val.status === 0));
    } else if (name === "PAUSED") {
      setMedlist(medications.filter((val) => val.status === 2));
    } else if (name === "DELETED") {
      setMedlist(medications.filter((val) => val.status === 1));
    } else if (name === "Measure") {
      setClaimsData(dummyClaimsData.find((d) => d.type === "Measure").data);
    } else if (name === "Medication") {
      setClaimsData(dummyClaimsData.find((d) => d.type === "Medication").data);
    } else if (name === "claims") {
      setMedData("claims");
    } else if (name === "mobileApp") {
      setMedData("mobileApp");
    }
  };

  // Render the UI for your table
  return (
    <div>
      <div className="bg-white">
        {headerTabs && (
          <Row className={classnames(" py-4 align-items-center", "mr-0 ml-0 ")}>
            <HeaderTabs
              activeTab={activeTab}
              headerTabs={headerTabs}
              handleTabChange={handleTabChange}
              isMobileWidth={isMobileWidth}
              headerTabsDisabled={headerTabsDisabled}
            />
            <div
              className={classnames("ml-auto d-flex", {
                "align-items-start": isIE
              })}
            >
              {print}
              {tableAction}
            </div>
          </Row>
        )}
        <div className="g-table-fix-head">
          <Table
            className={classnames(
              { "blue-outline": multiSelect || bulkUpdate },
              "g-table static"
            )}
            {...getTableProps()}
          >
            <thead
              className={classnames({
                "ddi-table-header": renderRowSubComponent
              })}
            >
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      <span className={className}>
                        {column.render("Header")}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="">
              {dataToMap.map((row, i) => {
                prepareRow(row);
                return (
                  <React.Fragment key={row.getRowProps().key}>
                    <tr
                      {...row.getRowProps()}
                      className={classnames({ expanded: row.isExpanded })}
                    >
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>

                    {row.isExpanded ? (
                      // <tr>
                      <>
                        {/*
                          Inside it, call our renderRowSubComponent function. In reality,
                          you could pass whatever you want as props to
                          a component like this, including the entire
                          table instance. But for this example, we'll just
                          pass the row
                        */}
                        {renderRowSubComponent({ row })}
                      </>
                    ) : // </tr>
                    null}
                  </React.Fragment>
                );
              })}

              <EmptyTable
                emptyImg={<div className="pt-4"></div>}
                page={page}
                loading={loading}
                emptyColSpan={emptyColSpan}
                emptyMsg={"Nothing to see here!"}
              />
              {loading && (
                <tr className="hover-none" data-testid="loading-table">
                  {emptyColSpan === 3 ? (
                    <td colSpan="3" className="text-center">
                      <div className="mt-4">
                        {[...Array(8)].map((e, i) => {
                          if (i % 2 === 0) {
                            return (
                              <div className="ph-item" key={i}>
                                <div className="ph-col-12">
                                  <div className="ph-row">
                                    <div className="ph-col-1 big"></div>
                                    <div className="ph-col-1 empty"></div>
                                    <div className="ph-col-6 big"></div>
                                    <div className="ph-col-2 empty"></div>
                                    <div className="ph-col-1 big"></div>
                                    <div className="ph-col-half empty"></div>
                                  </div>
                                </div>
                              </div>
                            );
                          } else {
                            return (
                              <div className="ph-item" key={i}>
                                <div className="ph-col-12">
                                  <div className="ph-row">
                                    <div className="ph-col-1 big"></div>
                                    <div className="ph-col-1 empty"></div>
                                    <div className="ph-col-5 big"></div>
                                    <div className="ph-col-real3 empty"></div>
                                    <div className="ph-col-half empty"></div>
                                    <div className="ph-col-1 big"></div>
                                    <div className="ph-col-half empty"></div>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </td>
                  ) : (
                    <td colSpan="6" className="text-center">
                      {[...Array(5)].map((e, i) => {
                        if (i % 2 === 0) {
                          return (
                            <div className="ph-item" key={i}>
                              <div className="ph-col-12">
                                <div className="ph-row">
                                  <div className="ph-col-15 big"></div>
                                  <div className="ph-col-1 empty"></div>
                                  <div className="ph-col-1 big"></div>
                                  <div className="ph-col-half empty"></div>
                                  <div className="ph-col-1 big"></div>
                                  <div className="ph-col-1 empty"></div>
                                  <div className="ph-col-1 big"></div>
                                  <div className="ph-col-half empty"></div>
                                  <div className="ph-col-2 big"></div>
                                  <div className="ph-col-half empty"></div>
                                  <div className="ph-col-2 big"></div>
                                </div>
                              </div>
                            </div>
                          );
                        } else {
                          return (
                            <div className="ph-item" key={i}>
                              <div className="ph-col-12">
                                <div className="ph-row">
                                  <div className="ph-col-2 big"></div>
                                  <div className="ph-col-half empty"></div>
                                  <div className="ph-col-1 big"></div>
                                  <div className="ph-col-half empty"></div>
                                  <div className="ph-col-15 big"></div>
                                  <div className="ph-col-half empty"></div>
                                  <div className="ph-col-1 big"></div>
                                  <div className="ph-col-half empty"></div>
                                  <div className="ph-col-15 big"></div>
                                  <div className="ph-col-1 empty"></div>
                                  <div className="ph-col-2 big"></div>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      })}
                    </td>
                  )}
                </tr>
                // ) : (
                //   <tr></tr>
              )}
            </tbody>
          </Table>
          {data.length === 0 && !loading && errorMsg ? (
            <div className="text-center py-5 px-3">
              <p>{errorMsg}</p>
            </div>
          ) : (
            <span></span>
          )}
        </div>
      </div>
      {data.length > 10 && pagination ? (
        <div className="d-flex align-items-center justify-content-center px-4">
          <div>
            <button
              className="btn btn-outline-dark btn-small p-1 w-2"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              {"<<"}
            </button>{" "}
            <button
              className="btn btn-outline-dark btn-small p-1 w-2"
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              {"<"}
            </button>{" "}
            <button
              className="btn btn-outline-dark btn-small p-1 w-2"
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              {">"}
            </button>{" "}
            <button
              className="btn btn-outline-dark btn-small p-1 w-2"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </button>{" "}
          </div>
          <span className="ml-auto">
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{" "}
          </span>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

MyStaticTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  loading: PropTypes.bool,
  errorMsg: PropTypes.string,
  emptyColSpan: PropTypes.number,
  smartColumnsToHide: PropTypes.array,
  className: PropTypes.string,
  mobileColumnsToHide: PropTypes.array,
  isMobileWidth: PropTypes.bool,
  renderRowSubComponent: PropTypes.func,
  multiSelect: PropTypes.bool,
  bulkUpdate: PropTypes.bool,
  headerTabs: PropTypes.array,
  setMedlist: PropTypes.func,
  medications: PropTypes.array,
  tableAction: PropTypes.node,
  setSelectedRows: PropTypes.func,
  pagination: PropTypes.bool,
  headerTabsDisabled: PropTypes.bool,
  selectedRows: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  setClaimsData: PropTypes.func,
  dummyClaimsData: PropTypes.array,
  setMedData: PropTypes.func,
  setActiveTab: PropTypes.func,
  activeTab: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export { MyStaticTable };
