import classnames from "classnames";
import React, { useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { timesOfDay } from "../_helpers";
import PropTypes from "prop-types";

export const CustomTimeOfDayPicker = ({
  idx,
  setFieldTouched,
  setFieldValue,
  values,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const isValuePresent = (value) => {
    return values.medicationScheduleTimes.some(
      (time) => time.partOfDay?.label === value
    );
  };
  const isActive = (value) => {
    return values.medicationScheduleTimes[idx]?.partOfDay === value;
  };

  const setTimeValue = useMemo(() => {
    if (values.medicationScheduleTimes[idx]?.time) {
      return new Date(
        values.medicationScheduleTimes[idx]?.time
      ).toLocaleTimeString([], {
        hour: "numeric",
        minute: "2-digit",
      });
    } else if (values.medicationScheduleTimes[idx]?.partOfDay) {
      return values.medicationScheduleTimes[idx].partOfDay.label;
    }
    return "Set time";
  }, [values, idx]);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle
        tag="span"
        data-toggle="dropdown"
        aria-expanded={dropdownOpen}
        tabIndex={0}
        className="btn btn-link py-0 ml-2 mt-neg-2px"
      >
        {setTimeValue}
      </DropdownToggle>
      <DropdownMenu
        container={
          document.getElementsByClassName("modal")[
            document.getElementsByClassName("modal").length - 1
          ]
        }
        id="time-dropdown"
        className="py-0"
      >
        <div className="d-flex">
          <div className="border-right">
            <DropdownItem header className="text-secondary small p-3">
              TIME OF DAY
            </DropdownItem>
            {timesOfDay.map((tod) => (
              <DropdownItem
                className={classnames(
                  {
                    active: isActive(tod),
                  },
                  "px-3 pb-1 pt-1 time-of-day-dropdown-item"
                )}
                key={tod.value}
                onClick={() => {
                  const fieldName = `medicationScheduleTimes[${idx}].partOfDay`;
                  const timeFieldName = `medicationScheduleTimes[${idx}].time`;

                  setFieldTouched(fieldName, true);
                  setFieldValue(fieldName, tod);
                  setFieldTouched(timeFieldName, true);
                  setFieldValue(timeFieldName, "");
                }}
                disabled={isValuePresent(tod.label) && !isActive(tod)}
              >
                {tod.label}
              </DropdownItem>
            ))}
          </div>
          {/* <DropdownItem divider /> */}
          <div>
            <DropdownItem header className="text-secondary small p-3">
              SPECIFIC TIME
            </DropdownItem>
            {/* <DropdownItem> */}
            <DatePicker
              onChange={(date) => {
                const fieldName = `medicationScheduleTimes[${idx}].time`;
                const todFieldName = `medicationScheduleTimes[${idx}].partOfDay`;
                setFieldTouched(fieldName, true);
                setFieldValue(fieldName, date);
                setFieldTouched(todFieldName, true);
                setFieldValue(todFieldName, "");
              }}
              id="time"
              name="time"
              inline
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={30}
              timeCaption="Time"
              dateFormat="h:mm aa"
              placeholderText={
                Number(values.medicationScheduleTimes[idx].dose) !==
                  values.doseType?.value &&
                values.medicationScheduleTimes[idx].dose
                  ? "Set time *"
                  : "Set time"
              }
              selected={values.medicationScheduleTimes[idx].time || ""}
              excludeTimes={values.medicationScheduleTimes.map(
                (time) => new Date(time.time)
              )}
            />
          </div>
        </div>
        {/* </DropdownItem> */}
      </DropdownMenu>
    </Dropdown>
  );
};

CustomTimeOfDayPicker.propTypes = {
  idx: PropTypes.number,
  setFieldTouched: PropTypes.func,
  setFieldValue: PropTypes.func,
  values: PropTypes.shape({
    medicationScheduleTimes: PropTypes.arrayOf(
      PropTypes.shape({
        partOfDay: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        time: PropTypes.oneOfType([
          PropTypes.instanceOf(Date),
          PropTypes.string,
        ]),
        dose: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      })
    ),
    doseType: PropTypes.oneOfType([
      PropTypes.shape({
        value: PropTypes.number,
      }),
      PropTypes.string,
      PropTypes.number,
    ]),
  }),
};
