import React from "react";
import {
  useAsyncDebounce,
  useMountedLayoutEffect,
  useRowSelect,
  useTable,
} from "react-table";
import { Waypoint } from "react-waypoint";
import { CustomInput, Table } from "reactstrap";
import {
  Use1280MediaQuery,
  UseMobileMediaQuery,
} from "../_helpers/media-queries";
import PropTypes from "prop-types";
import classnames from "classnames";

const Header = () => <div className="text-right">Select</div>;

const cellRenderer = (props, key = "") => {
  switch (key) {
    case "header":
      return <Header />;
    case "headerCell":
      return <HeaderCell row={props.row} />;
    default:
      return null;
  }
};

const IndeterminateCheckbox = React.forwardRef(
  ({ id, indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);
    return (
      <CustomInput
        id={id}
        className="dark-checkmark"
        type="checkbox"
        innerRef={resolvedRef}
        {...rest}
      />
    );
  }
);
IndeterminateCheckbox.propTypes = {
  id: PropTypes.string,
  indeterminate: PropTypes.bool,
};

const HeaderCell = ({ row }) => {
  return (
    <div className="text-right">
      <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} id={row.id} />
    </div>
  );
};
HeaderCell.propTypes = {
  row: PropTypes.object,
};

const MultiSelectTable = ({
  columns,
  data,
  getRowId,
  fetchData,
  totalPages,
  loading,
  selectedRows,
  emptyMsg,
  onSelectedRowsChange,
}) => {
  const is1280Width = Use1280MediaQuery();
  const isMobileWidth = UseMobileMediaQuery();

  // Use the state and functions returned from useTable to build your UI
  const tableInstance = useTable(
    {
      columns,
      data,
      getRowId,
      initialState: {
        pageIndex: 1,
        pageSize: 50,
        selectedRowIds: selectedRows,
      },
      autoResetSelectedRows: true,
    },
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        ...columns,
        // Let's make a column for selection
        {
          id: "selection",
          disableSortBy: true,
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: () => cellRenderer({}, "header"),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: (props) => cellRenderer(props, "headerCell"),
        },
      ]);
    }
  );

  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    state: { selectedRowIds },
  } = tableInstance;

  const [searchInput, setSearchInput] = React.useState("");
  const [searchFieldVal, setSearchFieldVal] = React.useState("");
  const [lastLoadedPage, setLastLoadedPage] = React.useState(1);

  React.useEffect(() => {
    fetchData({
      searchInput,
      pageIndex: 1,
      pageSize: 50,
      isLoadMore: false,
    });
  }, [fetchData, searchInput, is1280Width]);

  useMountedLayoutEffect(() => {
    onSelectedRowsChange?.(selectedRowIds);
  }, [onSelectedRowsChange, selectedRowIds]);

  const handleSearchChange = useAsyncDebounce((value) => {
    setSearchInput(value);
  }, 300);

  function onLeave() {
    // this is intentional
  }
  const loadMore = useAsyncDebounce(() => {
    if (lastLoadedPage < totalPages) {
      setLastLoadedPage(lastLoadedPage + 1);
      fetchData({
        searchInput,
        pageIndex: lastLoadedPage + 1,
        pageSize: 50,
        isLoadMore: true,
      });
    }
  }, 500);

  // Render the UI for your table
  return (
    <div>
      <div className="form-row">
        <div className="form-group has-search col-12">
          <span className="gylph-search form-control-feedback"></span>
          <input
            name="role"
            type="text"
            placeholder="Search"
            value={searchFieldVal || ""}
            onChange={(e) => {
              setSearchFieldVal(e.target.value);
              handleSearchChange(e.target.value);
            }}
            className="form-control form-control-sm search-input bg-grey"
          />
        </div>
      </div>
      <div className="">
        <div
          className={classnames(
            { "multiselectable-mobile": isMobileWidth },
            { multiselectable: !isMobileWidth },
            "g-table-fix-head mb-2"
          )}
        >
          {!loading && !data.length && emptyMsg ? (
            emptyMsg
          ) : (
            <Table className="g-table" {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        <span>{column.render("Header")}</span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps()}
                      // className="pointer"
                      // onClick={() => {
                      //   row.toggleRowSelected();
                      // }}
                      // tabIndex="0"
                      // onKeyPress={() => row.toggleRowSelected()}
                    >
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            onClick={() => {
                              if (cell.column.id !== "selection")
                                row.toggleRowSelected();
                            }}
                            className={
                              cell.column.id !== "selection" ? "pointer" : ""
                            }
                          >
                            {cell.render("Cell")}
                            {row.index + 1 === data.length ? (
                              <Waypoint
                                onEnter={loadMore}
                                onLeave={
                                  onLeave
                                  //this is intentional
                                }
                              />
                            ) : (
                              <span></span>
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
                {loading ? (
                  <tr className="hover-none">
                    <td colSpan="6" className="text-center">
                      {[...Array(5)].map((e, i) => (
                        <div className="ph-item" key={i}>
                          <div className="ph-col-12">
                            <div className="ph-row">
                              <div className="ph-col-4 big"></div>
                              <div className="ph-col-4 empty"></div>
                              <div className="ph-col-1 big"></div>
                              <div className="ph-col-2 empty"></div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </td>
                  </tr>
                ) : (
                  <tr></tr>
                )}
              </tbody>
            </Table>
          )}
        </div>
      </div>
    </div>
  );
};

MultiSelectTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  getRowId: PropTypes.func,
  fetchData: PropTypes.func,
  totalPages: PropTypes.number,
  loading: PropTypes.bool,
  selectedRows: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  emptyMsg: PropTypes.object,
  onSelectedRowsChange: PropTypes.func,
};

export { MultiSelectTable };
