import { ReactComponent as Sync } from "../images/sync_FILL.svg";
import PropTypes from "prop-types";

const SyncActionBtn = ({ fetchData }) => (
  <div className="d-flex align-items-center justify-content-end">
    <button
      className="btn btn-link ml-3 p-0"
      onClick={fetchData}
      type="button"
      data-testid="sync-btn"
    >
      <Sync className="glyph-gray-fill" alt="sync" aria-label="sync" />
    </button>
  </div>
);

SyncActionBtn.propTypes = {
  fetchData: PropTypes.func,
};

export default SyncActionBtn;
