import classnames from "classnames";
import React, { useEffect, useState } from "react";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import {
  Badge,
  Card,
  CardBody,
  CustomInput,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { PageHeader } from "../_components";
import PropTypes from "prop-types";
import { isIOS } from "../_helpers";

const InteractionCard = ({
  componentRef,
  interactions,
  filteredData,
  findColor,
}) => {
  return (
    <Card
      className="bg-white border-0"
      innerRef={componentRef}
      id="print-interactions"
    >
      <h3>Drug-to-Drug Interactions ({interactions.length})</h3>
      {filteredData.map((interaction, idx) => (
        <div
          key={`${interaction.drugName}-${idx}`}
          className="primary-underline py-5"
        >
          <div className="d-flex align-items-center">
            <Badge
              className="py-2 px-4 mb-2 mr-4"
              color={findColor(interaction.drugSeverityLevel)}
            >
              {interaction.drugSeverityLevel}
            </Badge>
            <h3>{interaction.drugName}</h3>
            <div className="text-secondary"></div>
          </div>
          <p></p>
          <h6 className="">
            What is the most important information I should know about{" "}
            {interaction.drugName}?
          </h6>
          <div>{interaction.drugDescription}</div>
        </div>
      ))}
    </Card>
  );
};
InteractionCard.propTypes = {
  componentRef: PropTypes.object,
  interactions: PropTypes.array,
  filteredData: PropTypes.array,
  findColor: PropTypes.func,
};

const PrintButton = ({ componentRef }) => {
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentType: "Drug Interactions Report",
  });

  return (
    <button className="btn btn-link" onClick={handlePrint}>
      Print
    </button>
  );
};

const InteractionsReport = ({
  setActiveTab,
  interactions,
  editedMedlist,
  getInteractions,
  version,
}) => {
  const [interactionsTab, setInteractionsTab] = useState("Consumer");
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState([
    "Major",
    "Minor",
    "Food",
    "Moderate",
  ]);
  const [filteredData, setFilteredData] = useState(
    interactions.sort((a, b) => b.severityLevel - a.severityLevel)
  );
  const componentRef = React.useRef();
  const toggle = (tab) => {
    setLoading(true);
    if (interactionsTab !== tab) {
      getInteractions(editedMedlist, tab, setLoading);
      setInteractionsTab(tab);
    }
  };

  useEffect(() => {
    if (!filters.includes("Food")) {
      setFilteredData(
        interactions
          .filter(
            (item) =>
              !item.isFoodInteraction ||
              filters.includes(item.drugSeverityLevel)
          )
          .filter((item) => filters.includes(item.drugSeverityLevel))
          .sort((a, b) => b.severityLevel - a.severityLevel)
      );
    } else {
      setFilteredData(
        interactions
          .filter(
            (item) =>
              filters.includes(item.drugSeverityLevel) || item.isFoodInteraction
          )
          .sort((a, b) => b.severityLevel - a.severityLevel)
      );
    }
  }, [interactions, filters]);

  const filterData = (val, severity) => {
    if (!val.target.checked) {
      setFilters((fltrs) => fltrs.filter((item) => item !== severity));
    } else {
      setFilters((fltrs) => fltrs.concat(severity));
    }
  };

  const createLabel = (severity) => {
    if (
      severity === "Food" &&
      interactions.filter((item) => item.isFoodInteraction).length
    ) {
      return `${severity} (${
        interactions.filter((item) => item.isFoodInteraction).length
      })`;
    } else if (
      interactions.filter((item) => item.drugSeverityLevel === severity).length
    ) {
      return `${severity} (${
        interactions.filter((item) => item.drugSeverityLevel === severity)
          .length
      })`;
    } else return `${severity}`;
  };

  const findColor = (severity) => {
    if (severity === "Major") return "danger";
    if (severity === "Moderate") return "warning";
    else return "success";
  };

  const isIOSDevice = isIOS();

  return (
    <div>
      {version !== "patient" ? (
        <PageHeader
          title="Drug Interactions Report"
          onBackClick={() => setActiveTab(0)}
          backText="to Interactions Checker"
        />
      ) : (
        <></>
      )}
      <div className="d-flex justify-content-center">
        <Card
          className="card bg-white align-self-center"
          style={
            version !== "patient"
              ? { width: "50rem" }
              : { width: "100%", border: 0, boxShadow: "none" }
          }
        >
          <CardBody>
            <div className="d-flex justify-content-between">
              <h3>Drug Interactions Report</h3>
              <div>
                {!isIOSDevice && interactions.length ? (
                  <ReactToPrint
                    trigger={() => <PrintButton componentRef={componentRef} />}
                    content={() => componentRef.current}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
            {version !== "patient" ? (
              <>
                <p>
                  {interactions.length}&nbsp;potential interactions found for
                  the following&nbsp;{editedMedlist?.length}&nbsp;drugs:
                </p>
                <ul>
                  {editedMedlist?.map((med) => (
                    <li key={med}>{med}</li>
                  ))}
                </ul>

                <button
                  className="btn btn-link pl-5"
                  onClick={() => setActiveTab(0)}
                >
                  Edit List
                </button>
              </>
            ) : (
              <></>
            )}
            <div className="pt-5">
              {version !== "patient" ? (
                <Nav tabs className="border-bottom border-dark">
                  <NavItem>
                    <NavLink
                      className={classnames(
                        {
                          active: interactionsTab === "Consumer",
                        },
                        "tab"
                      )}
                      onClick={() => {
                        toggle("Consumer");
                      }}
                    >
                      {loading && interactionsTab === "Consumer" && (
                        <span className="spinner-border spinner-border-sm mr-1"></span>
                      )}
                      Consumer
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames(
                        {
                          active: interactionsTab === "Provider",
                        },
                        "tab"
                      )}
                      onClick={() => {
                        toggle("Provider");
                      }}
                    >
                      {loading && interactionsTab === "Provider" && (
                        <span className="spinner-border spinner-border-sm mr-1"></span>
                      )}
                      Provider
                    </NavLink>
                  </NavItem>
                </Nav>
              ) : (
                <></>
              )}
              <TabContent activeTab={interactionsTab}>
                <div className="d-flex justify-content-between py-4 pr-7">
                  <CustomInput
                    type="checkbox"
                    className="blue-checkmark"
                    label={createLabel("Major")}
                    id="Major"
                    onChange={(val) => {
                      filterData(val, "Major");
                    }}
                    defaultChecked={true}
                  ></CustomInput>
                  <CustomInput
                    type="checkbox"
                    className="blue-checkmark"
                    label={createLabel("Moderate")}
                    onChange={(val) => {
                      filterData(val, "Moderate");
                    }}
                    id="Moderate"
                    defaultChecked={true}
                  ></CustomInput>
                  <CustomInput
                    type="checkbox"
                    className="blue-checkmark"
                    label={createLabel("Minor")}
                    onChange={(val) => {
                      filterData(val, "Minor");
                    }}
                    id="Minor"
                    defaultChecked={true}
                  ></CustomInput>
                  <CustomInput
                    type="checkbox"
                    className="blue-checkmark"
                    label={createLabel("Food")}
                    onChange={(val) => {
                      filterData(val, "Food");
                    }}
                    id="Food"
                    defaultChecked={true}
                  ></CustomInput>
                </div>

                <TabPane tabId="Consumer">
                  <InteractionCard
                    componentRef={componentRef}
                    interactions={interactions}
                    filteredData={filteredData}
                    findColor={findColor}
                  />
                </TabPane>
                {version !== "patient" ? (
                  <TabPane tabId="Provider">
                    <InteractionCard
                      componentRef={componentRef}
                      interactions={interactions}
                      filteredData={filteredData}
                      findColor={findColor}
                    />
                  </TabPane>
                ) : (
                  <></>
                )}
                <div className="d-flex justify-content-center py-5">
                  **END OF REPORT**
                </div>
              </TabContent>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};
InteractionsReport.propTypes = {
  setActiveTab: PropTypes.func,
  interactions: PropTypes.array,
  editedMedlist: PropTypes.array,
  getInteractions: PropTypes.func,
  version: PropTypes.string,
};

export { InteractionsReport };
