import { Field, Form, Formik } from "formik";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { FormGroup, Modal, ModalBody, ModalHeader } from "reactstrap";
import * as Yup from "yup";
import {
  CloseButton,
  MyGroupedFilter,
  MyReportSelect,
  MyReportsMultiSelect,
  lastTimeOptions,
  recordsOptions
} from "../_components";
import { useUserContext } from "../context/userContext";

const documentTypeOptions = [
  { value: 1, label: "PDF" },
  { value: 2, label: "XLSX" }
];

const audienceOptions = [
  { value: 0, label: "Patient-facing" },
  { value: 1, label: "Provider-facing" }
];

const accessLevelOptions = [
  { value: 1, label: "My Organization" },
  { value: 2, label: "My Group" },
  { value: 3, label: "Me" }
];

const dueDateOptions = [
  { value: 1, label: "All" },
  { value: 2, label: "Today" },
  { value: 3, label: "Future" },
  { value: 4, label: "Last 7 Days" }
];
const statusOptions = [
  { value: 1000, label: "All" },
  { value: 0, label: "Pending" },
  { value: 1, label: "Complete" }
];

const dateCreatedOptions = [
  { value: 1000, label: "All" },
  { value: 7, label: "Last 7 Days" },
  { value: 30, label: "Last 30 Days" },
  { value: 90, label: "Last 3 Months" }
];
const CreateReportModal = ({
  modal,
  toggle,
  associatedPerson,
  selectedReportType,
  initialValues,
  onSubmit,
  medOptions,
  metricsOptions,
  patientLists
}) => {
  const { userState } = useUserContext();
  const { user } = userState;
  const [accessOptions, setAccessOptions] = React.useState([]);

  useEffect(() => {
    if (user?.accessLevel === 1) {
      setAccessOptions(accessLevelOptions);
    } else if (user?.accessLevel === 2) {
      setAccessOptions(accessLevelOptions.slice(1));
    } else {
      setAccessOptions(accessLevelOptions.slice(2));
    }
  }, [user?.accessLevel]);

  // if sortBy is not null, then sortByOrder is required
  const schema = Yup.object().shape({
    sortByOrder: Yup.object()
      .nullable()
      .when("sortBy", {
        is: (val) => val,
        then: (schema) => schema.required("Sort By is required"),
        otherwise: (schema) => schema.optional()
      })
  });
  return (
    <Modal isOpen={modal} toggle={toggle} className="bg-gray">
      <ModalHeader
        close={<CloseButton toggle={toggle} color="glyph-gray" />}
        className="center-title"
      >
        {associatedPerson?.name}
      </ModalHeader>
      <ModalBody>
        <h4>{selectedReportType?.fullName}</h4>
        <div className="mb-3">
          {selectedReportType.types?.map((type, idx) => {
            if (idx === 0) return <span key={type}>{type} </span>;
            else return <span key={type}> or {type} </span>;
          })}{" "}
          {selectedReportType.description}{" "}
        </div>
        {/* <button
          className="btn btn-link"
          onClick={() => console.log(selectedReportType.label)}
        >
          View Example
        </button> */}
        {/* Formik form */}
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          enableReinitialize
          validationSchema={schema}
        >
          {({
            values,
            setFieldValue,
            setFieldTouched,
            isSubmitting,
            isValid
          }) => {
            return (
              <Form>
                {/* if fields.medications, show medication dropdown with medications as options organized into value - medicationId, label: medicationName */}
                {selectedReportType.fields?.medications && (
                  <FormGroup>
                    <MyReportsMultiSelect
                      placeholder=""
                      name="medications"
                      options={medOptions}
                      value={values.medications}
                      onChange={(name, val) => {
                        if (!val.length || val[val.length - 1].value === 0) {
                          setFieldValue(name, [
                            { value: 0, label: "All Meds" }
                          ]);
                        } else {
                          setFieldValue(
                            name,
                            val.filter((v) => v.value !== 0)
                          );
                        }
                      }}
                      onBlur={setFieldTouched}
                      isClearable={false}
                      label="Medications"
                      isSearchable={false}
                    />
                  </FormGroup>
                )}
                {selectedReportType.fields?.metrics && (
                  <FormGroup>
                    <MyReportsMultiSelect
                      placeholder=""
                      name="metrics"
                      options={metricsOptions}
                      value={values.metrics}
                      onChange={(name, val) => {
                        if (!val.length || val[val.length - 1].value === 0) {
                          setFieldValue(name, [
                            { value: 0, label: "All Metrics" }
                          ]);
                        } else {
                          setFieldValue(
                            name,
                            val.filter((v) => v.value !== 0)
                          );
                        }
                      }}
                      onBlur={setFieldTouched}
                      isClearable={false}
                      label="Metrics"
                      isSearchable={false}
                    />
                  </FormGroup>
                )}
                {/* if field.dateRange show the FilterDropdown component */}
                {selectedReportType.fields?.dateRange && (
                  <FormGroup>
                    <MyGroupedFilter
                      placeholder="Date Range"
                      name="dateRange"
                      options={[
                        {
                          label: "Number of Records",
                          options: recordsOptions
                        },
                        { label: "Time Frame", options: lastTimeOptions }
                      ]}
                      value={values.dateRange}
                      onChange={(name, val) => {
                        setFieldValue(name, val);
                      }}
                      onBlur={setFieldTouched}
                      isClearable={false}
                      label="Date Range"
                      isSearchable={false}
                    />
                  </FormGroup>
                )}
                {/* if fields.documentType show the report select */}
                {selectedReportType.fields?.reportFormat && (
                  <FormGroup>
                    <MyReportSelect
                      placeholder="Report Type"
                      name="documentType"
                      options={documentTypeOptions}
                      value={values.documentType}
                      onChange={(name, val) => {
                        setFieldValue(name, val);
                      }}
                      onBlur={setFieldTouched}
                      isClearable={false}
                      label="Report Type"
                      isSearchable={false}
                    />
                  </FormGroup>
                )}
                {/* if fields.leafletType or fields.interactionType show the type select */}
                {(selectedReportType.fields?.leafletType ||
                  selectedReportType.fields?.interactionType) && (
                  <FormGroup>
                    <MyReportSelect
                      placeholder={
                        selectedReportType.fields?.leafletType
                          ? "Leaflet Type"
                          : "Interaction Type"
                      }
                      name="patientFacing"
                      label={
                        selectedReportType.fields?.leafletType
                          ? "Leaflet Type"
                          : "Interaction Type"
                      }
                      options={audienceOptions}
                      value={values.patientFacing}
                      onChange={(name, val) => {
                        setFieldValue(name, val);
                      }}
                      onBlur={setFieldTouched}
                      isClearable={false}
                      isSearchable={false}
                    />
                  </FormGroup>
                )}
                {selectedReportType.fields?.assignedTo && (
                  <FormGroup>
                    <MyReportSelect
                      placeholder="Assigned To"
                      name="accessLevel"
                      options={accessOptions}
                      value={values.accessLevel}
                      onChange={(name, val) => {
                        setFieldValue(name, val);
                      }}
                      onBlur={setFieldTouched}
                      isClearable={false}
                      label="Assigned To"
                      isSearchable={false}
                    />
                  </FormGroup>
                )}
                {selectedReportType.fields.dateCreatedRange && (
                  <FormGroup>
                    <MyReportSelect
                      placeholder="Date Created"
                      name="dateCreatedRange"
                      options={dateCreatedOptions}
                      value={values.dateCreatedRange}
                      onChange={(name, val) => {
                        setFieldValue(name, val);
                      }}
                      onBlur={setFieldTouched}
                      isClearable={false}
                      label="Date Created"
                      isSearchable={false}
                    />
                  </FormGroup>
                )}
                {selectedReportType.fields?.dateDueRange && (
                  <FormGroup>
                    <MyReportSelect
                      placeholder="Date Due"
                      name="dateDueRange"
                      options={dueDateOptions}
                      value={values.dateDueRange}
                      onChange={(name, val) => {
                        setFieldValue(name, val);
                      }}
                      onBlur={setFieldTouched}
                      isClearable={false}
                      label="Date Due"
                      isSearchable={false}
                    />
                  </FormGroup>
                )}
                {selectedReportType.fields?.taskStatus && (
                  <FormGroup>
                    <MyReportSelect
                      placeholder="Task Status"
                      name="status"
                      options={statusOptions}
                      value={values.status}
                      onChange={(name, val) => {
                        setFieldValue(name, val);
                      }}
                      onBlur={setFieldTouched}
                      isClearable={false}
                      label="Status"
                      isSearchable={false}
                    />
                  </FormGroup>
                )}
                {selectedReportType.patientListIdRequired && (
                  <FormGroup>
                    <MyReportSelect
                      placeholder={"Select a Patient List"}
                      name={"patientListId"}
                      options={patientLists}
                      value={values.patientListId}
                      onChange={(name, val) => {
                        setFieldValue(name, val);
                      }}
                      onBlur={setFieldTouched}
                      isClearable={false}
                      label={"Patient List"}
                      isSearchable={false}
                    />
                  </FormGroup>
                )}
                {selectedReportType.sortBy && (
                  <FormGroup>
                    <div className="form row">
                      <div className="col-7">
                        <MyReportSelect
                          placeholder={"Sort By"}
                          name={"sortBy"}
                          options={selectedReportType.sortBy}
                          onChange={(name, val) => {
                            if (!val) {
                              // clear sortByOrder if sortBy is cleared
                              setFieldValue("sortByOrder", "");
                            }
                            setFieldValue(name, val?.value);
                          }}
                          onBlur={setFieldTouched}
                          isClearable={true}
                          label={"Sort By"}
                          isSearchable={false}
                        />
                      </div>
                      <div className="col-5 pl-0">
                        <MyReportSelect
                          placeholder={"Order"}
                          name={"sortByOrder"}
                          options={[
                            { value: "ASC", label: "Ascending" },
                            { value: "DESC", label: "Descending" }
                          ]}
                          onChange={(name, val) => {
                            setFieldValue(name, val);
                          }}
                          value={values.sortByOrder}
                          label="Order"
                          onBlur={setFieldTouched}
                          isClearable={false}
                          disabled={!values.sortBy}
                          isSearchable={false}
                        />
                      </div>
                    </div>
                  </FormGroup>
                )}
                {selectedReportType.fields?.filterBy && (
                  <>
                    {selectedReportType.fields.filterBy.map((filter) => (
                      <FormGroup key={filter.value}>
                        {filter.isMulti ? (
                          <MyReportsMultiSelect
                            placeholder={""}
                            name={filter?.value}
                            options={filter?.options}
                            value={values[filter?.value]}
                            onChange={(name, val) => {
                              setFieldValue(name, val);
                            }}
                            onBlur={setFieldTouched}
                            isClearable={filter.isClearable}
                            label={filter?.label}
                            isSearchable={false}
                          />
                        ) : (
                          <MyReportSelect
                            placeholder={filter?.label}
                            name={filter?.value}
                            options={filter?.options}
                            value={values[filter?.value]}
                            onChange={(name, val) => {
                              setFieldValue(name, val);
                            }}
                            onBlur={setFieldTouched}
                            isClearable={filter.isClearable}
                            label={filter?.label}
                            isSearchable={false}
                          />
                        )}{" "}
                      </FormGroup>
                    ))}
                  </>
                )}
                {selectedReportType?.fields.searchBy && (
                  <FormGroup>
                    <Field
                      type="text"
                      name="searchText"
                      placeholder="Search"
                      className="form-control bg-white border-white"
                    />
                  </FormGroup>
                )}
                <FormGroup className="d-flex justify-content-end">
                  <button
                    className="btn btn-primary"
                    type="submit"
                    disabled={!isValid}
                  >
                    {isSubmitting && (
                      <span className="spinner-border spinner-border-sm mr-1"></span>
                    )}
                    Create Report
                  </button>
                </FormGroup>
              </Form>
            );
          }}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

CreateReportModal.propTypes = {
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  associatedPerson: PropTypes.object,
  selectedReportType: PropTypes.object,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  medOptions: PropTypes.array,
  metricsOptions: PropTypes.array,
  patientLists: PropTypes.array
};

export { CreateReportModal };
