import classnames from "classnames";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import Select, { components } from "react-select";
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";

import { UseMobileOrTabletMediaQuery } from "../_helpers/media-queries";
import {
  btnLinkColourStyles,
  colourStyles,
  filterColourStyles,
  formInTableColourStyles,
  groupedColourStyles,
  lightColourStyles,
  lightNoBorderColourStyles,
  mobileFilterColourStyles,
  multiReportsColourStyles,
  readOnlyColourStyles,
  reportsColourStyles,
  smallBtnStyles
} from "../_helpers/select-styles";
import { ReactComponent as ChevronDown } from "../images/chevron_down_small.svg";
import { ReactComponent as ChevronRight } from "../images/chevron_right.svg";
import { MyCheckbox } from "./Checkbox";
import { Loader } from "./Loader";

// Custom components
const CustomControl = ({ children, innerProps, isFocused, selectProps }) => {
  const { value, label } = selectProps;
  const MAX_DISPLAY_COUNT = 3; // Maximum number of selected options to display
  let selectedOptions = [];
  if (value?.length) {
    selectedOptions = value.slice(0, MAX_DISPLAY_COUNT);
  }
  const controlStyles = {
    border: isFocused
      ? "2px solid var(--extra-light-primary)"
      : "2px solid white",
    cursor: "pointer"
  };
  return (
    <div
      className="d-flex form-control pr-0 pl-2 custom-control"
      {...innerProps}
      style={controlStyles}
    >
      <div className="margin-right-10px">{label}</div>
      <div className="d-flex ml-auto align-items-center">
        {selectedOptions?.map((option, idx) => {
          let label;
          // if label is longer than 10 characters, slice it and add ellipsis
          if (option.label?.length > 10) {
            label = option.label.slice(0, 10) + "...";
          } else {
            label = option.label;
          }

          if (idx === 0) return <span key={option.value}>{label} </span>;
          else return <span key={option.value}>,&nbsp;&nbsp;{label} </span>;
        })}
        {value?.length > MAX_DISPLAY_COUNT && (
          <span>{`... + ${value.length - MAX_DISPLAY_COUNT} more`}</span>
        )}
        {children}
      </div>
    </div>
  );
};
CustomControl.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  isFocused: PropTypes.bool,
  selectProps: PropTypes.object
};

const customValueRenderer = ({ data, selectProps }) => {
  return (
    <div className="d-flex custom-value-renderer">
      <span>{selectProps.label}</span>
      <span className="ml-auto">{selectProps.value?.label}</span>
    </div>
  );
};
customValueRenderer.propTypes = {
  data: PropTypes.object,
  selectProps: PropTypes.object
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      {/* Custom dropdown indicator */}
      <ChevronRight
        className="glyph-light-gray h-12px"
        aria-label="chevron right"
      />
    </components.DropdownIndicator>
  );
};
DropdownIndicator.propTypes = {
  props: PropTypes.object
};

const ChevronDropdownIndicator = (props) => {
  const { selectProps } = props;
  return (
    <>
      {selectProps?.readOnly ? (
        <></>
      ) : (
        <div className="px-3 dropdown-indicator">
          <ChevronDown
            className="ml-auto glyph-dark"
            aria-label="chevron down"
          />
        </div>
      )}
    </>
  );
};
ChevronDropdownIndicator.propTypes = {
  props: PropTypes.object
};

const MyAsyncCreatableSelect = (props) => {
  const {
    value,
    onChange,
    label,
    name,
    placeholder,
    cacheOptions,
    noOptionsMessage,
    onMenuOpen,
    isClearable,
    loadOptions,
    inputValue,
    onMenuClose,
    onFocus,
    blurInputOnSelect,
    onInputChange,
    isDisabled,
    defaultOptions
  } = props;
  const handleChange = (val) => {
    // this is going to call setFieldValue and manually update values.name
    onChange(name, val);
  };
  return (
    <div>
      <label htmlFor={name} id={`${name}-label`}>
        {label}
      </label>
      <AsyncCreatableSelect
        id={name}
        aria-label={name}
        components={{
          DropdownIndicator: ChevronDropdownIndicator
        }}
        aria-labelledby={`${name}-label`}
        placeholder={placeholder}
        cacheOptions={cacheOptions}
        loadOptions={loadOptions}
        value={value}
        isClearable={isClearable}
        noOptionsMessage={noOptionsMessage}
        onInputChange={onInputChange}
        defaultOptions={defaultOptions}
        onChange={handleChange}
        styles={colourStyles}
        className="select"
        inputValue={inputValue}
        onMenuClose={onMenuClose}
        onMenuOpen={onMenuOpen}
        onFocus={onFocus}
        isDisabled={isDisabled}
        blurInputOnSelect={blurInputOnSelect}
        formatCreateLabel={() => `+ Add a Custom Med`}
      />
    </div>
  );
};
MyAsyncCreatableSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onChange: PropTypes.func,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  name: PropTypes.string,
  placeholder: PropTypes.string,
  cacheOptions: PropTypes.bool,
  noOptionsMessage: PropTypes.func,
  onMenuOpen: PropTypes.func,
  isClearable: PropTypes.bool,
  loadOptions: PropTypes.func,
  inputValue: PropTypes.string,
  onMenuClose: PropTypes.func,
  onFocus: PropTypes.func,
  blurInputOnSelect: PropTypes.bool,
  onInputChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  defaultOptions: PropTypes.array
};

const MyAsyncSelect = (props) => {
  const {
    value,
    onChange,
    label,
    name,
    placeholder,
    cacheOptions,
    noOptionsMessage,
    isClearable,
    loadOptions,
    inputValue,
    onFocus,
    blurInputOnSelect,
    onInputChange,
    defaultOptions,
    isLoading,
    isMulti,
    error,
    setFieldTouched
  } = props;

  const handleChange = (val) => {
    // this is going to call setFieldValue and manually update values.name
    onChange(name, val);
  };
  return (
    <div>
      {label && (
        <label htmlFor={name} id={`${name}-label`}>
          {label}
        </label>
      )}
      <AsyncSelect
        id={name}
        aria-label={name}
        isSearchable
        aria-labelledby={`${name}-label`}
        placeholder={placeholder}
        cacheOptions={cacheOptions}
        loadOptions={loadOptions}
        value={value}
        isClearable={isClearable}
        isMulti={isMulti}
        noOptionsMessage={() =>
          isLoading ? <Loader selectVersion={true} /> : noOptionsMessage
        }
        onInputChange={onInputChange}
        defaultOptions={defaultOptions}
        onChange={handleChange}
        styles={colourStyles}
        className={classnames("select", { "invalid-input": error })}
        inputValue={inputValue}
        openMenuOnClick={true}
        onFocus={onFocus}
        onBlur={() => {
          if (setFieldTouched) setFieldTouched(name, true);
        }}
        blurInputOnSelect={blurInputOnSelect}
      />
    </div>
  );
};

MyAsyncSelect.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.array
  ]),
  onChange: PropTypes.func,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.object
  ]),
  name: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  cacheOptions: PropTypes.bool,
  noOptionsMessage: PropTypes.object,
  isClearable: PropTypes.bool,
  loadOptions: PropTypes.func,
  inputValue: PropTypes.string,
  onFocus: PropTypes.func,
  blurInputOnSelect: PropTypes.bool,
  onInputChange: PropTypes.func,
  defaultOptions: PropTypes.array,
  isLoading: PropTypes.bool,
  isMulti: PropTypes.bool,
  error: PropTypes.bool,
  setFieldTouched: PropTypes.func
};

const MySelect = ({
  options,
  value,
  onChange,
  onBlur,
  label,
  name,
  placeholder,
  fontSize,
  noOptionsMessage,
  disabled,
  onMenuOpen,
  isClearable,
  readOnly,
  defaultValue,
  isLoading,
  styles
}) => {
  const handleChange = (val) => {
    handleBlur();
    // this is going to call setFieldValue and manually update values.name
    onChange(name, val);
  };
  const handleBlur = () => {
    if (onBlur) {
      // this is going to call setFieldTouched and manually update touched.name
      onBlur(name, true);
    }
  };

  const activeStyle = useMemo(() => {
    if (styles) {
      return styles;
    } else if (readOnly) {
      return readOnlyColourStyles;
    }
    return colourStyles;
  }, [styles, readOnly]);

  return (
    <div>
      {label && (
        <label id={`${name}-label`} htmlFor={name}>
          {label}
        </label>
      )}
      <Select
        id={name}
        options={options}
        components={{
          DropdownIndicator: ChevronDropdownIndicator
        }}
        placeholder={placeholder}
        noOptionsMessage={() =>
          isLoading ? <Loader selectVersion={true} /> : noOptionsMessage
        }
        isDisabled={disabled || readOnly}
        onMenuOpen={onMenuOpen}
        aria-label={name}
        aria-labelledby={`${name}-label`}
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
        styles={activeStyle}
        className={classnames(
          { "select-normal": fontSize === "1rem" },
          "select"
        )}
        isClearable={isClearable}
        isOptionDisabled={(option) => option.disabled === "yes"}
        defaultValue={defaultValue}
        readOnly={readOnly}
      />
    </div>
  );
};
MySelect.propTypes = {
  options: PropTypes.array,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  name: PropTypes.string,
  placeholder: PropTypes.string,
  fontSize: PropTypes.string,
  noOptionsMessage: PropTypes.func,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onMenuOpen: PropTypes.func,
  isClearable: PropTypes.bool,
  readOnly: PropTypes.bool,
  defaultValue: PropTypes.object,
  isLoading: PropTypes.bool,
  styles: PropTypes.object
};

const MySelectBtnLink = ({
  options,
  value,
  onChange,
  onBlur,
  label,
  name,
  placeholder,
  fontSize,
  noOptionsMessage,
  disabled,
  onMenuOpen,
  isClearable,
  readOnly,
  width,
  defaultValue,
  menuPlacement
}) => {
  const handleChange = (val) => {
    handleBlur();
    // this is going to call setFieldValue and manually update values.name
    onChange(name, val);
  };

  const handleBlur = () => {
    if (onBlur) {
      // this is going to call setFieldTouched and manually update touched.name
      onBlur(name, true);
    }
  };
  return (
    <div>
      {label && (
        <label id={`${name}-label`} htmlFor={name}>
          {label}
        </label>
      )}
      <Select
        id={name}
        options={options}
        placeholder={placeholder}
        noOptionsMessage={noOptionsMessage}
        isDisabled={disabled || readOnly}
        onMenuOpen={onMenuOpen}
        aria-label={name}
        aria-labelledby={`${name}-label`}
        onChange={handleChange}
        menuPlacement={menuPlacement || "auto"}
        onBlur={handleBlur}
        value={value}
        styles={width === "small" ? smallBtnStyles : btnLinkColourStyles}
        className={classnames(
          { "select-normal": fontSize === "1rem" },
          "select"
        )}
        isClearable={isClearable}
        isSearchable={false}
        isOptionDisabled={(option) => option.disabled === "yes"}
        defaultValue={defaultValue}
      />
    </div>
  );
};
MySelectBtnLink.propTypes = {
  options: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  fontSize: PropTypes.string,
  noOptionsMessage: PropTypes.func,
  disabled: PropTypes.bool,
  onMenuOpen: PropTypes.func,
  isClearable: PropTypes.bool,
  readOnly: PropTypes.bool,
  width: PropTypes.string,
  defaultValue: PropTypes.object,
  menuPlacement: PropTypes.string
};

const MyOption = (props) => (
  <div>
    <props.components.Option {...props}>
      <span>{props.label}</span>
      <div className="d-inline-block float-right">
        <MyCheckbox
          id={props.label}
          name={props.label}
          checked={props.isSelected}
          className="dark-checkmark"
        ></MyCheckbox>
      </div>
    </props.components.Option>
  </div>
);
MyOption.propTypes = {
  components: PropTypes.object,
  label: PropTypes.string,
  isSelected: PropTypes.bool
};

const MyMultiSelect = ({
  options,
  value,
  onChange,
  onBlur,
  label,
  name,
  placeholder,
  isClearable,
  onMenuOpen,
  isLoading,
  error
}) => {
  const handleChange = (val) => {
    // this is going to call setFieldValue and manually update values.name
    onChange(name, val);
  };

  const handleBlur = () => {
    // this is going to call setFieldTouched and manually update touched.name
    onBlur(name, true);
  };

  return (
    <div>
      {label && (
        <label htmlFor={name} id={`${name}-label`}>
          {label}
        </label>
      )}
      <Select
        aria-label={name}
        aria-labelledby={`${name}-label`}
        id={name}
        options={options}
        noOptionsMessage={() =>
          isLoading ? <Loader selectVersion={true} /> : "No options available"
        }
        onMenuOpen={onMenuOpen}
        components={options.map((option) => (
          <MyOption
            key={option.id}
            label={option.label}
            isSelected={option.isSelected}
            components={components}
          />
        ))}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        placeholder={placeholder}
        isMulti
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
        className={classnames("select", { "invalid-input": error })}
        styles={colourStyles}
        isClearable={isClearable}
      />
    </div>
  );
};
MyMultiSelect.propTypes = {
  options: PropTypes.array,
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string
  ]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  isClearable: PropTypes.bool,
  onMenuOpen: PropTypes.func,
  isLoading: PropTypes.bool,
  error: PropTypes.bool
};

const MySelectLight = (props) => {
  const {
    options,
    value,
    onChange,
    onBlur,
    label,
    name,
    placeholder,
    hidden,
    defaultValue,
    fontSize,
    border
  } = props;
  const handleChange = (value) => {
    // this is going to call setFieldValue and manually update values.name
    onChange(name, value);
  };
  const handleBlur = () => {
    // this is going to call setFieldTouched and manually update touched.name
    if (onBlur) onBlur(name, true);
  };
  return (
    <div className={classnames({ "d-none": hidden })}>
      {label && (
        <label htmlFor={name} id={`${name}-label`}>
          {label}
        </label>
      )}
      <Select
        aria-label={name}
        aria-labelledby={`${name}-label`}
        id={name}
        options={options}
        placeholder={placeholder}
        // menuPortalTarget={
        //   document.getElementsByClassName("modal")[
        //     document.getElementsByClassName("modal").length - 1
        //   ]
        //   :
        // (name !== "numberOfSteps" || isMobileWidth) &&
        // // ? document.getElementsByClassName("modal")[0] ||
        // document.querySelector("body")
        // }
        //   multi={true}
        onChange={handleChange}
        defaultValue={defaultValue}
        className={classnames(
          { "select-normal": fontSize === "1rem" },
          "select"
        )}
        onBlur={handleBlur}
        value={value}
        styles={
          border === "white" ? lightNoBorderColourStyles : lightColourStyles
        }
      />
    </div>
  );
};
MySelectLight.propTypes = {
  options: PropTypes.array,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.object,
    PropTypes.string
  ]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  hidden: PropTypes.bool,
  defaultValue: PropTypes.object,
  fontSize: PropTypes.string,
  border: PropTypes.string
};

const MySelectFilter = (props) => {
  const {
    options,
    defaultValue,
    onChange,
    name,
    placeholder,
    isClearable,
    isSearchable,
    styles,
    isMulti
  } = props;
  const is768Width = UseMobileOrTabletMediaQuery();
  const [val, setVal] = React.useState(defaultValue);

  const handleChange = (value) => {
    onChange(name, value);
    setVal(value);
  };
  function chooseStyles() {
    if (styles) return styles;
    else if (is768Width && name !== "medSelectFilter")
      return mobileFilterColourStyles;
    else return filterColourStyles;
  }
  return (
    <div>
      <Select
        id={name}
        options={options}
        onChange={handleChange}
        // menuPortalTarget={document.querySelector("body")}
        isMulti={isMulti}
        isClearable={isClearable}
        isSearchable={isSearchable}
        className="select"
        value={val}
        placeholder={placeholder}
        styles={chooseStyles()}
      />
    </div>
  );
};
MySelectFilter.propTypes = {
  options: PropTypes.array,
  defaultValue: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  onChange: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  styles: PropTypes.object,
  isMulti: PropTypes.bool
};

const MyGroupedFilter = (props) => {
  const {
    options,
    value,
    onChange,
    onBlur,
    name,
    placeholder,
    disabled,
    defaultValue,
    label,
    isSearchable
  } = props;

  const handleChange = (value) => {
    // this is going to call setFieldValue and manually update values.name
    onChange(name, value);
  };

  const handleBlur = () => {
    // this is going to call setFieldTouched and manually update touched.name
    onBlur(name, true);
  };
  return (
    <div>
      <Select
        aria-label={name}
        components={{
          SingleValue: customValueRenderer,
          DropdownIndicator
        }}
        label={label}
        aria-labelledby={`${name}-label`}
        id={name}
        options={options}
        placeholder={placeholder}
        className="select"
        isDisabled={disabled}
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
        styles={groupedColourStyles}
        defaultValue={defaultValue}
        isSearchable={isSearchable}
      />
    </div>
  );
};
MyGroupedFilter.propTypes = {
  options: PropTypes.array,
  value: PropTypes.object,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.object,
  label: PropTypes.string,
  isSearchable: PropTypes.bool
};

const MyDynamicSelect = ({
  options,
  isLoading,
  value,
  onChange,
  onFocus,
  label,
  name,
  disabled
}) => {
  const handleChange = (val) => {
    // this is going to call setFieldValue and manually update values.name
    onChange(name, val);
  };

  const handleFocus = () => {
    // this is going to call setFieldTouched and manually update touched.name
    onFocus();
  };
  return (
    <div>
      {label && (
        <label htmlFor={name} id={`${name}-label`}>
          {label}
        </label>
      )}
      <Select
        aria-label={name}
        aria-labelledby={`${name}-label`}
        id={name}
        // menuPortalTarget={document.body}
        isLoading={isLoading}
        isSearchable={false}
        isClearable={false}
        options={options}
        onChange={handleChange}
        onFocus={handleFocus}
        value={value}
        styles={colourStyles}
        className="select"
        isDisabled={disabled}
      />
    </div>
  );
};
MyDynamicSelect.propTypes = {
  options: PropTypes.array,
  isLoading: PropTypes.bool,
  value: PropTypes.object,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool
};

const InTableSelect = ({
  id,
  menuPortalTarget,
  value,
  isDisabled,
  placeholder,
  options,
  name,
  onChange
}) => {
  return (
    <Select
      id={id}
      menuPortalTarget={menuPortalTarget}
      styles={formInTableColourStyles}
      value={value}
      isDisabled={isDisabled}
      placeholder={placeholder}
      options={options}
      name={name}
      onChange={onChange}
    />
  );
};
InTableSelect.propTypes = {
  id: PropTypes.string,
  menuPortalTarget: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  name: PropTypes.string,
  onChange: PropTypes.func
};

const MyReportSelect = ({
  options,
  value,
  onChange,
  onBlur,
  label,
  name,
  placeholder,
  fontSize,
  noOptionsMessage,
  disabled,
  onMenuOpen,
  isClearable,
  readOnly,
  isSearchable,
  defaultValue
}) => {
  const handleChange = (val) => {
    handleBlur();
    // this is going to call setFieldValue and manually update values.name
    onChange(name, val);
  };
  const handleBlur = () => {
    if (onBlur) {
      // this is going to call setFieldTouched and manually update touched.name
      onBlur(name, true);
    }
  };
  return (
    <div>
      <Select
        id={name}
        components={{
          SingleValue: customValueRenderer,
          DropdownIndicator
        }}
        label={label}
        options={options}
        placeholder={placeholder}
        // menuPortalTarget={document.body}
        noOptionsMessage={noOptionsMessage}
        isDisabled={disabled || readOnly}
        onMenuOpen={onMenuOpen}
        aria-label={name}
        aria-labelledby={`${name}-label`}
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
        defaultValue={defaultValue}
        styles={reportsColourStyles}
        className={classnames(
          { "select-normal": fontSize === "1rem" },
          "select"
        )}
        isClearable={isClearable}
        isOptionDisabled={(option) => option.disabled === "yes"}
        isSearchable={isSearchable}
      />
    </div>
  );
};
MyReportSelect.propTypes = {
  options: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  fontSize: PropTypes.string,
  noOptionsMessage: PropTypes.func,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  onMenuOpen: PropTypes.func,
  isClearable: PropTypes.bool,
  readOnly: PropTypes.bool,
  isSearchable: PropTypes.bool,
  defaultValue: PropTypes.object
};

const MyReportsMultiSelect = ({
  options,
  value,
  onChange,
  onBlur,
  label,
  name,
  placeholder,
  isClearable,
  isSearchable
}) => {
  const handleChange = (val) => {
    // this is going to call setFieldValue and manually update values.name
    onChange(name, val);
  };

  const handleBlur = () => {
    // this is going to call setFieldTouched and manually update touched.name
    onBlur(name, true);
  };

  return (
    <div>
      <Select
        label={label}
        aria-label={name}
        aria-labelledby={`${name}-label`}
        id={name}
        options={options}
        components={{
          Control: CustomControl,
          DropdownIndicator
        }}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        placeholder={placeholder}
        isMulti
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
        className="select"
        styles={multiReportsColourStyles}
        isClearable={isClearable}
        isSearchable={isSearchable}
        controlShouldRenderValue={false}
      />
    </div>
  );
};
MyReportsMultiSelect.propTypes = {
  options: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool
};

export {
  InTableSelect,
  MyAsyncCreatableSelect,
  MyAsyncSelect,
  MyDynamicSelect,
  MyGroupedFilter,
  MyMultiSelect,
  MyReportSelect,
  MyReportsMultiSelect,
  MySelect,
  MySelectBtnLink,
  MySelectFilter,
  MySelectLight
};
