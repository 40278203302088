import classnames from "classnames";
import React, { useState } from "react";
import Moment from "react-moment";
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import {
  CloseButton,
  MyDropdown,
  NewGTable,
  OutreachForm,
  RowExpander,
} from "../../_components";
import { convertUTCTimeToLocalTime, outreachTypeOptions } from "../../_helpers";
import {
  UseMobileMediaQuery,
  UseMobileOrTabletMediaQuery,
  Use1600MediaQuery,
} from "../../_helpers/media-queries";
import { patientService } from "../../_services";
import { useAlertContext } from "../../context/alertContext";
import { usePatientContext } from "../../context/patientContext";
import { useTableStateContext } from "../../context/stateContext";
import { useUserContext } from "../../context/userContext";
import { ReactComponent as Menu } from "../../images/glyph_menu_2.svg";
import PropTypes from "prop-types";

const resultOptions = [
  { value: 1, label: "Contacted" },
  { value: 2, label: "Left Voicemail" },
  { value: 3, label: "Unable to Reach" },
  { value: 4, label: "Reached, but refused conversation" },
];

const DuedateHeader = ({ is1600Width }) => {
  return <>Date {is1600Width && " Contacted"}</>;
};
DuedateHeader.propTypes = {
  is1600Width: PropTypes.bool,
};

const DuedateCell = ({ row, value }) => {
  if (value)
    return (
      <div>
        {row.original.dueTime && row.original.dueTime !== "00:00:00" ? (
          <>
            <Moment utc format="M/D/YYYY">
              {value}
            </Moment>
            <br></br>
            {convertUTCTimeToLocalTime(row.original.dueTime)}
          </>
        ) : (
          <Moment format="M/D/YYYY">{value.slice(0, -1)}</Moment>
        )}
      </div>
    );
  else return <>--</>;
};
DuedateCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({ dueTime: PropTypes.string }),
  }),
  value: PropTypes.string,
};

const TypeCell = ({ value, isMobileWidth, row }) => {
  let text = outreachTypeOptions.find((item) => Number(item.value) === value);
  return (
    <div className="d-flex justify-content-between align-items-center">
      {text.label}
      {isMobileWidth && <RowExpander {...row} />}
    </div>
  );
};
TypeCell.propTypes = {
  value: PropTypes.number,
  isMobileWidth: PropTypes.bool,
  row: PropTypes.shape({
    values: PropTypes.shape({
      result: PropTypes.number,
    }),
  }),
};

const ResultHeader = ({ is768Width }) => (
  <div className="word-wrap-normal">Result{!is768Width && "ed By"}</div>
);
ResultHeader.propTypes = {
  is768Width: PropTypes.bool,
};

const ResultCell = ({ value }) => {
  const text = resultOptions.find((item) => Number(item.value) === value);
  return (
    <div className="d-flex justify-content-between word-wrap-normal">
      {text.label}
    </div>
  );
};
ResultCell.propTypes = {
  value: PropTypes.number,
};

const ContactHeader = ({ is1600Width }) => <>Contact{is1600Width && "ed By"}</>;
ContactHeader.propTypes = {
  is1600Width: PropTypes.bool,
};

const ContactCell = ({ value, user, userId }) => (
  <>{userId === user?.userId ? <div>Me</div> : <div>{value || "--"}</div>}</>
);
ContactCell.propTypes = {
  value: PropTypes.string,
  user: PropTypes.shape({
    name: PropTypes.string,
    userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

const NotesCell = ({ value }) => (
  <div className="white-space-prewrap">{value || "--"}</div>
);
NotesCell.propTypes = {
  value: PropTypes.string,
};

const ActionsCell = ({
  is768Width,
  toggleAdd,
  setSelectedItem,
  row,
  toggleDelete,
}) => {
  return (
    <div className={classnames({ "d-flex justify-content-end": is768Width })}>
      <MyDropdown
        details={{
          name: "row menu",
          glyph: <Menu aria-label="menu" className="glyph-gray w-10px" />,
          items: [
            {
              name: "Edit Outreach",
              link: "",
              onClick: () => {
                toggleAdd();
                setSelectedItem(row);
              },
              class: "text-primary",
            },
            {
              name: "Delete Outreach",
              link: "",
              onClick: () => {
                toggleDelete();
                setSelectedItem(row);
              },
              class: "text-danger",
            },
          ],
        }}
        // container="body"
        direction="right"
      />
    </div>
  );
};
ActionsCell.propTypes = {
  is768Width: PropTypes.bool,
  toggleAdd: PropTypes.func,
  setSelectedItem: PropTypes.func,
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.number,
    }),
  }),
  toggleDelete: PropTypes.func,
};

const MobileAction = ({ toggleAdd, setSelectedItem }) => (
  <button
    onClick={() => {
      setSelectedItem(null);
      toggleAdd();
    }}
    className={classnames("btn btn-primary mb-4 mr-4")}
  >
    Create Outreach
  </button>
);
MobileAction.propTypes = {
  toggleAdd: PropTypes.func,
  setSelectedItem: PropTypes.func,
};

const TabOutreach = ({
  patient,
  patientGroups,
  patientId,
  users,
  patientExists,
}) => {
  const { userState } = useUserContext();
  const { alertMethods } = useAlertContext();
  const { user } = userState;
  const { state, setOutreach, setLoadingOutreach } = usePatientContext();
  const { tableState, updateOutreachTableState } = useTableStateContext();
  const { outreach, loadingOutreach } = state;
  const [selectedItem, setSelectedItem] = useState({});
  const isMobileWidth = UseMobileMediaQuery();
  const is768Width = UseMobileOrTabletMediaQuery();
  const [addModal, setAddModal] = useState(false);
  const [totalOutreachPages, setTotalOutreachPages] = useState(0);
  const [totalOutreachCount, setTotalOutreachCount] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const is1600Width = Use1600MediaQuery();
  const toggleDelete = React.useCallback(() => {
    setDeleteModal(!deleteModal);
  }, [deleteModal]);

  const fetchIdRef = React.useRef(0);
  const toggleAdd = React.useCallback(() => {
    if (addModal) setSelectedItem({});
    setAddModal(!addModal);
  }, [addModal]);
  const mounted = React.useRef(false);
  React.useEffect(() => {
    mounted.current = true; // Will set it to true on mount ...
    return () => {
      mounted.current = false;
    }; // ... and to false on unmount
  }, []);

  const fetchOutreachData = React.useCallback(
    ({ sortStr, pageSize, pageIndex }) => {
      let sort = {};
      if (sortStr) {
        sort.id = sortStr.slice(0, sortStr.indexOf("."));
        sort.desc = sortStr.slice(sortStr.indexOf(".") + 1) === "DESC";
      }

      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current;
      setOutreach([]);
      // Set the loading state
      setLoadingOutreach(true);
      if (fetchId === fetchIdRef.current && patientExists) {
        if (patientId) {
          patientService
            .getOutreach(patientId, sortStr, pageSize, pageIndex)
            .then((res) => {
              if (mounted.current) {
                setOutreach(res.data);
                setTotalOutreachPages(res.totalPages);
                setTotalOutreachCount(res.count);
                setLoadingOutreach(false);
              }
            })
            .catch((e) => {
              setLoadingOutreach(false);
              alertMethods.error("Something went wrong. Please try again.");
            });
        }
        // Clear existing data
      }
    },
    [setOutreach, setLoadingOutreach, patientExists, patientId]
  );

  function deleteOutreach(id) {
    setIsSubmitting(true);
    patientService
      .deleteOutreach(patientId, id)
      .then(() => {
        setIsSubmitting(false);
        toggleDelete();

        alertMethods.success("Outreach has been deleted");
        fetchOutreachData(tableState.outreachTableState);
      })
      .catch(() => {
        setIsSubmitting(false);

        alertMethods.error("Outreach could not be deleted");
      });
  }

  const findResult = (result) => {
    let found = resultOptions.find((el) => el.value === result);
    return found?.label;
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Title",
        accessor: "title",
        //disableSortBy: false,
      },
      {
        Header: () => <DuedateHeader is1600Width={is1600Width} />,
        disableSortBy: false,
        accessor: "dueDate",
        Cell: (props) => {
          return <DuedateCell value={props.value} row={props.row} />;
        },
      },
      {
        Header: "Type",
        accessor: "type",
        disableSortBy: true,
        Cell: (props) => {
          return (
            <TypeCell
              value={props.value}
              isMobileWidth={isMobileWidth}
              row={props.row}
            />
          );
        },
      },
      {
        Header: () => <ResultHeader is768Width={is768Width} />,
        disableSortBy: true,
        accessor: "result",
        Cell: (props) => {
          return <ResultCell value={props.value} />;
        },
      },
      {
        Header: () => <ContactHeader is1600Width={is1600Width} />,
        disableSortBy: true,
        accessor: "contactedByUser",
        Cell: (props) => {
          return (
            <ContactCell
              value={props.value}
              user={user}
              userId={props.row.original.contactedByUserId}
            />
          );
        },
      },
      {
        Header: "Notes",
        disableSortBy: true,
        accessor: "description",
        Cell: (props) => {
          return <NotesCell value={props.value} />;
        },
      },
      {
        Header: "",
        id: "actions",
        disableSortBy: true,
        accessor: (row) => {
          return (
            <ActionsCell
              is768Width={is768Width}
              toggleAdd={toggleAdd}
              setSelectedItem={setSelectedItem}
              row={row}
              toggleDelete={toggleDelete}
            />
          );
        },
      },
    ],
    [is1600Width, isMobileWidth, is768Width, user, toggleAdd, toggleDelete]
  );
  columns.propTypes = {
    is1600Width: PropTypes.bool,
    isMobileWidth: PropTypes.bool,
    is768Width: PropTypes.bool,
    toggleAdd: PropTypes.func,
    toggleDelete: PropTypes.func,
  };

  const renderRowSubComponent = React.useCallback(
    ({ row }) => (
      <>
        <tr>
          <td className="border-0 pt-0"></td>
          <td className="border-0 pt-0">
            <div className="">
              <div>Result</div>
              <div>Contact</div>
              <div>Notes</div>
            </div>
          </td>
          <td className="border-0 pt-0">
            <div className="">
              <div>{findResult(row.values.result)}</div>
              <div className="">{row.values.contactedByUser}</div>
              <div className="white-space-prewrap">
                {row.values.description || "--"}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td className="border-0 pt-0"></td>
          <td className="border-0 pt-0">
            {row.original && (
              <div className="d-flex justify-content-end ml-2 mr-3">
                <button
                  className="btn btn-link text-primary mr-2"
                  onClick={() => {
                    toggleAdd();
                    setSelectedItem(row.original);
                  }}
                >
                  Edit
                </button>
                <button
                  className="btn btn-link text-danger ml-1"
                  onClick={() => {
                    toggleDelete();
                    setSelectedItem(row.original);
                  }}
                >
                  Delete
                </button>
              </div>
            )}
          </td>
        </tr>
      </>
    ),
    [toggleAdd, toggleDelete]
  );

  return (
    <Row>
      {!is768Width && user ? (
        <Col xl="3" lg="4" sm="12">
          <div className="card bg-white">
            <div className="card-header">Log New Outreach</div>
            <div className="card-body">
              <OutreachForm
                patient={patient}
                fetchData={fetchOutreachData}
                users={users}
                groups={patientGroups?.map((group) => {
                  return { value: group.id, label: group.name };
                })}
                patientId={patientId}
              />
            </div>
          </div>
        </Col>
      ) : (
        <></>
      )}
      <Col xl="9" lg="8" sm="12">
        <div id="outreach-table">
          <NewGTable
            columns={columns}
            data={outreach}
            emptyColSpan={5}
            fetchData={fetchOutreachData}
            pageCount={totalOutreachPages}
            totalCount={totalOutreachCount}
            loading={loadingOutreach}
            rowExpands={isMobileWidth}
            emptyMsg={
              <div>
                You have no prior outreach matching the selected filters.
              </div>
            }
            type="no-header"
            noSearch={true}
            mobileHideColumnIds={[
              "result",
              "contactedByUser",
              "description",
              "actions",
            ]}
            hideColumnIds={[]}
            renderRowSubComponent={renderRowSubComponent}
            mobileTableButton={
              is768Width && (
                <MobileAction
                  toggleAdd={toggleAdd}
                  setSelectedItem={setSelectedItem}
                />
              )
            }
            updateTableState={updateOutreachTableState}
            toggleAdd={toggleAdd}
          />
        </div>
      </Col>

      <Modal
        isOpen={addModal}
        toggle={toggleAdd}
        className="center-modal scroll-modal"
      >
        <ModalHeader
          toggle={toggleAdd}
          close={<CloseButton toggle={toggleAdd} />}
        >
          {selectedItem ? "Edit Outreach" : "Log New Outreach"}
        </ModalHeader>
        <ModalBody>
          <OutreachForm
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            patient={patient}
            fetchData={fetchOutreachData}
            toggleAdd={toggleAdd}
            users={users}
            groups={patientGroups?.map((group) => {
              return { value: group.id, label: group.name };
            })}
            patientId={patientId}
          />
        </ModalBody>
      </Modal>
      <Modal
        isOpen={deleteModal}
        toggle={toggleDelete}
        className="center-modal scroll-modal"
      >
        <ModalHeader
          toggle={toggleDelete}
          close={<CloseButton toggle={toggleDelete} />}
        >
          Are you sure you want to delete this outreach?
        </ModalHeader>
        <ModalBody>
          <div className="form-group d-flex flex-md-row flex-column align-items-center">
            <button
              type="button"
              className="btn btn-primary w-25 mr-md-4 mb-md-0 mb-4"
              onClick={() => deleteOutreach(selectedItem.id)}
              disabled={isSubmitting}
            >
              {isSubmitting && (
                <span className="spinner-border spinner-border-sm mr-1"></span>
              )}
              Yes
            </button>
            <button
              type="button"
              className="btn btn-outline-primary w-25"
              onClick={toggleDelete}
            >
              No
            </button>
          </div>
        </ModalBody>
      </Modal>
    </Row>
  );
};

TabOutreach.propTypes = {
  patient: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  patientGroups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  patientId: PropTypes.string,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  patientExists: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export { TabOutreach };
