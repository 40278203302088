import React, { lazy, Suspense } from "react";
import { MySelectFilter } from "./MySelect";
import { filterColourStyles } from "../_helpers/select-styles";
import PropTypes from "prop-types";
import classnames from "classnames";

const PagerComponent = lazy(() =>
  import("@syncfusion/ej2-react-grids").then((module) => ({
    default: module.PagerComponent
  }))
);
const PaginationButtons = ({
  pageIndex,
  handlePageClick,
  totalCount,
  pageSize
}) => {
  return (
    <Suspense
      fallback={
        <div className="ph-item border-0 min-width-20rem bg-bg">
          <div className="ph-col-12">
            <div className="ph-row">
              <div className="ph-col-1 big mt-5"></div>
              <div className="ph-col-half empty"></div>
              <div className="ph-col-1 big mt-5"></div>
              <div className="ph-col-half empty"></div>
              <div className="ph-col-15 big mt-5"></div>
              <div className="ph-col-half empty"></div>
              <div className="ph-col-2 big mt-5"></div>
              <div className="ph-col-half empty"></div>
              <div className="ph-col-1 big mt-5"></div>
              <div className="ph-col-half empty"></div>
              <div className="ph-col-1 big mt-5"></div>
            </div>
          </div>
        </div>
      }
    >
      <PagerComponent
        pageSize={pageSize}
        totalRecordsCount={totalCount}
        pageCount={5}
        click={(e) => {
          handlePageClick(e);
        }}
        currentPage={pageIndex}
      ></PagerComponent>
    </Suspense>
  );
};
PaginationButtons.propTypes = {
  pageIndex: PropTypes.number,
  handlePageClick: PropTypes.func,
  totalCount: PropTypes.number,
  pageSize: PropTypes.number
};

const TablePagination = ({
  totalCount,
  pageIndex,
  pageSize,
  page,
  pageOptions,
  setPageSize,
  gotoPage,
  isMobileWidth,
  handlePageClick,
  mobilePageOptions,
  onCard
}) => {
  if (!pageIndex) pageIndex = 1;

  return (
    <div
      className={classnames(
        { "px-lg-0 mb-7": !onCard },

        "d-flex flex-column justify-content-between mt-4 px-4"
      )}
    >
      {isMobileWidth && totalCount > pageSize && (
        <div className="align-self-center">
          <PaginationButtons
            pageIndex={pageIndex}
            controlledPageCount={3}
            handlePageClick={handlePageClick}
            totalCount={totalCount}
            pageSize={pageSize}
          />
        </div>
      )}
      {/* page Index: {pageIndex} pageSize: {pageSize} totalCount:{totalCount} */}
      {totalCount === 0 && (
        <span className="g-table-pagination-info">
          Showing 0 - 0 of 0 items
        </span>
      )}
      {totalCount > 0 && (
        <div className="d-flex flex-row align-items-center justify-content-between mb-7">
          <span className="g-table-pagination-info mr-6">
            Showing {(pageIndex - 1) * pageSize + 1} - {""}
            {(pageIndex - 1) * pageSize + page.length} of {totalCount}{" "}
            {!isMobileWidth && "items"}
          </span>
          {!isMobileWidth && (
            <div className="align-self-center">
              <PaginationButtons
                pageIndex={pageIndex}
                controlledPageCount={3}
                handlePageClick={handlePageClick}
                totalCount={totalCount}
                pageSize={pageSize}
              />
            </div>
          )}

          <div className="pb-6 pt-4">
            {pageOptions[0].value < totalCount ? (
              <MySelectFilter
                tabIndex="0"
                name="page-options"
                options={isMobileWidth ? mobilePageOptions : pageOptions}
                styles={filterColourStyles}
                onChange={(name, val) => {
                  setPageSize(val.value);
                  gotoPage(1);
                }}
                defaultValue={pageOptions.find(
                  (option) => Number(option.value) === Number(pageSize)
                )}
              />
            ) : (
              <div className="text-secondary">
                {pageOptions[0].value} items per page
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

TablePagination.propTypes = {
  totalCount: PropTypes.number,
  pageIndex: PropTypes.number,
  pageSize: PropTypes.number,
  page: PropTypes.array,
  pageOptions: PropTypes.array,
  setPageSize: PropTypes.func,
  gotoPage: PropTypes.func,
  isMobileWidth: PropTypes.bool,
  mobilePageOptions: PropTypes.array,
  handlePageClick: PropTypes.func,
  onCard: PropTypes.bool
};

export { TablePagination, PaginationButtons };
