import React from "react";
import PropTypes from "prop-types";
import { useDrag, useDrop } from "react-dnd";
import { ReactComponent as Drag } from "../images/drag.svg";
import classnames from "classnames";

const DND_ITEM_TYPE = "row";

const MoveableRow = ({
  row,
  index,
  moveRow,
  isMobileWidth,
  renderRowSubComponent
}) => {
  const dropRef = React.useRef(null);
  const dragRef = React.useRef(null);

  const [, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    hover(item, monitor) {
      if (!dropRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = dropRef.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveRow(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    }
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: DND_ITEM_TYPE,
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  preview(drop(dropRef));
  drag(dragRef);

  return (
    <React.Fragment>
      <tr
        ref={dropRef}
        className={classnames(
          { "opacity-0": isDragging },
          { "opacity-1": !isDragging }
        )}
        data-testid="draggable-row"
      >
        <td ref={dragRef}>
          <Drag aria-label="drag" />
        </td>
        {row.cells.map((cell) => {
          return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
        })}
      </tr>
      {row.isExpanded && isMobileWidth ? (
        // <tr>
        <>
          {/*
      Inside it, call our renderRowSubComponent function. In reality,
      you could pass whatever you want as props to
      a component like this, including the entire
      table instance. But for this example, we'll just
      pass the row
    */}
          {renderRowSubComponent({ row })}
        </>
      ) : null}
    </React.Fragment>
  );
};

MoveableRow.propTypes = {
  row: PropTypes.object,
  index: PropTypes.number,
  moveRow: PropTypes.func,
  isMobileWidth: PropTypes.bool,
  renderRowSubComponent: PropTypes.func
};

export { MoveableRow };
