import React, { useContext, useEffect, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import { Col } from "reactstrap";
import { MyCircularProgressbar } from "../components/patient/overviewComponents";
import { PatientContext } from "../context/patientContext";
import { AdhSummary } from "./AdhSummary";
import { AdherenceChart } from "./AdherenceChart";
import PropTypes from "prop-types";

const colorMap = [
  { min: 80, max: 100, colors: ["#65aa00", "#8bce00"] },
  { min: 50, max: 80, colors: ["#d59004", "#fcb422"] },
  { min: -1, max: 50, colors: ["#b41b1b", "#d73838"] }
];

export const findColor = (value, highContrast) => {
  if (!value) return highContrast ? "#b41b1b" : "#d73838";
  else {
    let range = colorMap.find((item) => item.min < value && item.max >= value);
    if (highContrast) {
      return range.colors[0];
    } else return range.colors[1];
  }
};

const AdherencePlot = ({
  isNewPatient,
  patientId,
  loadingAdhrence,
  handleAdherenceSummaryFilterChange,
  range,
  chartRef,
  activeOption,
  setActiveOption
}) => {
  const { state } = useContext(PatientContext);
  const { adhSummary, adherence } = state;
  const highContrast =
    document.body.getAttribute("data-theme") === "high-contrast";

  const [color, setColor] = useState("");
  useEffect(() => {
    if (adhSummary.percent || adhSummary.percent === 0)
      setColor(findColor(adhSummary.percent, highContrast));
  }, [adhSummary.percent, highContrast]);

  useEffect(() => {
    //This is a special rule for IE that forces the SVG to rotate properly
    const speedometer = document.getElementsByClassName(
      "CircularProgressbar-trail"
    )[0];
    const path = document.getElementsByClassName("CircularProgressbar-path")[0];
    if (speedometer) {
      speedometer.setAttribute("transform", "rotate(225 48 48)");
    }
    if (path) {
      path.setAttribute("transform", "rotate(225 48 48)");
    }
  }, []);

  // Render the UI for your plot
  return (
    <div>
      <div className="d-flex align-items-center h-100">
        <Col sm="8" className="pl-sm-6">
          <AdhSummary
            patientId={patientId}
            adherence={adhSummary}
            isNewPatient={isNewPatient}
            loadingAdhrence={loadingAdhrence}
            handleAdherenceSummaryFilterChange={
              handleAdherenceSummaryFilterChange
            }
            version="overview"
            range={range}
            activeOption={activeOption}
            setActiveOption={setActiveOption}
          />
        </Col>
        <Col sm="4" className="border-left mt-6">
          <div className="d-flex flex-column align-items-center px-4 px-lg-7 px-xl-4">
            <MyCircularProgressbar
              average={adhSummary.percent || 0}
              color={color}
            />
          </div>
        </Col>
      </div>
      <div className="text-center mt-5 mb-3 border-bottom"></div>
      {loadingAdhrence ? (
        <div className="p-2">
          {[...Array(5)].map((e, i) => {
            if (i % 2 === 0) {
              return (
                <div className="ph-item" key={i}>
                  <div className="ph-col-12">
                    <div className="ph-row">
                      <div className="ph-col-real3 big"></div>
                      <div className="ph-col-3 empty"></div>
                      <div className="ph-col-8 big"></div>
                    </div>
                  </div>
                </div>
              );
            } else {
              return (
                <div className="ph-item" key={i}>
                  <div className="ph-col-12">
                    <div className="ph-row">
                      <div className="ph-col-2 big"></div>
                      <div className="ph-col-14 empty"></div>
                      <div className="ph-col-8 big"></div>
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </div>
      ) : (
        <div className="pr-6" ref={chartRef}>
          {adherence &&
          adherence.length > 0 &&
          (adhSummary.percent || adhSummary.percent === 0) ? (
            <AdherenceChart
              data={adherence}
              average={adhSummary.percent}
              chartRef={chartRef}
            />
          ) : (
            <></>
          )}
        </div>
      )}
      <div id="description" className="d-none">
        {"Scatter chart showing the patient's adherence by medication" +
          adherence.map(
            (item) => item.name + " " + item.overallAdhrencePercentage + "%"
          )}
      </div>
    </div>
  );
};

AdherencePlot.propTypes = {
  isNewPatient: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  patientId: PropTypes.string,
  loadingAdhrence: PropTypes.bool,
  handleAdherenceSummaryFilterChange: PropTypes.func,
  range: PropTypes.object,
  chartRef: PropTypes.object,
  activeOption: PropTypes.string,
  setActiveOption: PropTypes.func
};

export { AdherencePlot };
