import { default as classNames, default as classnames } from "classnames";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  TabContent,
  TabPane
} from "reactstrap";
import {
  CloseButton,
  DeleteModal,
  NewPatientAlert,
  PauseModal
} from "../../_components";
import { UseMobileMediaQuery } from "../../_helpers/media-queries";
import { patientService } from "../../_services";
import { useAlertContext } from "../../context/alertContext";
import { PatientContext } from "../../context/patientContext";
import { useUserContext } from "../../context/userContext";
import { AddMedications } from "../../home/AddMedications";
import { SuccessModal } from "../../home/SuccessModal";
import { ReactComponent as GlyphSmile } from "../../images/smile.svg";
import { ClaimsNav } from "./ClaimsNav";
import {
  AlertCards,
  DDIs,
  DailyPillBox,
  MedClaimsTable,
  MedlistTable,
  SuggestedMeds
} from "./medListComponents";
import { isIE } from "../../_helpers";

const TabMedList = ({
  patientId,
  isNewPatient,
  loadingMedications,
  fetchMedications,
  dataChange,
  setDataChange,
  fetchSuggestedChanges,
  suggestedMedFilterApplied
}) => {
  const isMobileWidth = UseMobileMediaQuery();
  const { userState } = useUserContext();
  const { user, tenantFeatures } = userState;
  const { alertMethods } = useAlertContext();
  const { state } = useContext(PatientContext);
  const {
    loadingSuggestedChanges,
    medications,
    clinicalInteractions,
    suggestedMedCount
  } = state;

  const [medlist, setMedlist] = React.useState([]);

  const [loadingInteractions, setLoadingInteractions] = React.useState(false);

  const [modal, setModal] = useState(false);
  // State for setting alerts in med list
  const [multiSelect, setMultiSelect] = useState(false);
  const [deleteBulkAlerts, setDeleteBulkAlerts] = useState(false);
  const [bulkUpdate, setBulkUpdate] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [alertModal, setAlertModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [modalNumber, setModalNumber] = useState(1);
  const [name, setName] = useState("");
  const [rowId, setRowId] = useState(0);
  const [selectedMed, setSelectedMed] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [pauseModal, setPauseModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [scrollPosition, setScrollPosition] = useState(document.body.scrollTop);
  const [activeTab, setActiveTab] = useState(
    tenantFeatures?.PayorPages ? "2" : "1"
  );

  const mounted = React.useRef(false);
  useEffect(() => {
    mounted.current = true; // Will set it to true on mount ...
    return () => {
      mounted.current = false;
    }; // ... and to false on unmount
  }, []);

  useEffect(() => {
    setMedlist(medications.filter((val) => val.status === 0));
  }, [medications]);
  const goBack = () => {
    setModalNumber(1);
  };
  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const toggleDelete = React.useCallback(
    (fields) => {
      if (deleteModal) setModalNumber(1);
      setDeleteModal(!deleteModal);
    },
    [deleteModal]
  );

  const togglePause = (fields) => {
    setPauseModal(!pauseModal);
  };

  const toggleAdd = React.useCallback(() => {
    setModal(!modal);
  }, [modal]);

  const toggleAlertModal = React.useCallback(() => {
    setAlertModal(!alertModal);
  }, [alertModal]);

  useEffect(() => {
    if (
      !loadingSuggestedChanges &&
      // !loadingNotInEhrMeds &&
      !loadingInteractions &&
      !loadingMedications
    ) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [
    loadingSuggestedChanges,
    // loadingNotInEhrMeds,
    loadingInteractions,
    loadingMedications
  ]);

  const submitAddMed = (item, setSubmitting, toggle) => {
    patientService
      .addMedication(patientId, item)
      .then((res) => {
        setTimeout(() => {
          if (mounted.current) {
            setDataChange(!dataChange);
          }
        }, 10000);

        toggleAdd();
        if (toggle) toggle();
        setTimeout(() => {
          if (setSubmitting && mounted.current) setSubmitting(false);
        }, 100);
        alertMethods.info(
          "<div class=''>Success!</div>Suggested change has been sent to patient.<br>On acceptance, your changes will be added to medication list."
        );
      })
      //Wait for backend to update, then reload suggested meds
      .catch((error) => {
        alertMethods.error(
          "<div class=''>An Error Occurred</div><div>Something went wrong. Please refresh the page and try again.</div>"
        );
        setSubmitting(false);
      });
  };

  return (
    <div className="w-100">
      {tenantFeatures?.PayorPages && (
        <ClaimsNav activeTab={activeTab} toggleTab={toggleTab} />
      )}
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row>
            <Col xl="7" lg="12" md="12">
              <MedlistTable
                setMedlist={setMedlist}
                loadingMedications={loadingMedications}
                setBulkUpdate={setBulkUpdate}
                selectedRows={selectedRows}
                fetchMedications={fetchMedications}
                setMultiSelect={setMultiSelect}
                setDeleteBulkAlerts={setDeleteBulkAlerts}
                bulkUpdate={bulkUpdate}
                toggleAdd={toggleAdd}
                medlist={medlist}
                setSelectedRows={setSelectedRows}
                setName={setName}
                setRowId={setRowId}
                setSelectedMed={setSelectedMed}
                setSelectedRow={setSelectedRow}
                toggleAlertModal={toggleAlertModal}
                medications={medications}
                multiSelect={multiSelect}
                isMobileWidth={isMobileWidth}
                deleteBulkAlerts={deleteBulkAlerts}
                selectedRow={selectedRow}
                alertModal={alertModal}
                isIE={isIE}
                scrollPosition={scrollPosition}
                setScrollPosition={setScrollPosition}
                submitAddMed={submitAddMed}
              />
            </Col>
            <Col xl="5" lg="12" md="12">
              <Row>
                <Col xl="12" md="12" lg="5" sm="12" className="col-mdlg-5">
                  {medications.length ? (
                    <div
                      className={classnames(
                        { "alert-border": isNewPatient },
                        "card patient-alert-card"
                      )}
                    >
                      {
                        <NewPatientAlert
                          id={"tabO-4"}
                          isNewPatient={isNewPatient}
                          notRightAligned={true}
                        />
                      }

                      <DailyPillBox
                        loadingMedications={loadingMedications}
                        medications={medications}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </Col>
                <Col xl="12" md="12" lg="7" sm="12" className="col-mdlg-7">
                  <DDIs
                    setLoadingInteractions={setLoadingInteractions}
                    loadingInteractions={loadingInteractions}
                    patientId={patientId}
                    user={user}
                    isIE={isIE}
                    scrollPosition={scrollPosition}
                    setScrollPosition={setScrollPosition}
                  />

                  {/* <NotInEHRMeds
                        user={user}
                        patientId={patientId}
                        setNotInEhrMeds={setNotInEhrMeds}
                        setLoadingNotInEhrMeds={setLoadingNotInEhrMeds}
                        notInEhrMeds={notInEhrMeds}
                        loadingNotInEhrMeds={loadingNotInEhrMeds}
                      /> */}
                  <SuggestedMeds
                    dataChange={dataChange}
                    setDataChange={setDataChange}
                    fetchSuggestedChanges={fetchSuggestedChanges}
                    suggestedMedFilterApplied={suggestedMedFilterApplied}
                  />

                  {!isLoading &&
                    !clinicalInteractions.length &&
                    !suggestedMedCount && (
                      // !notInEhrMeds.length &&
                      <div className="dashed-border  d-flex flex-column justify-content-center align-items-center p-5">
                        <GlyphSmile aria-label="smile" />
                        <h4 className="text-secondary text-center">
                          No Notifications Found
                        </h4>
                        <div className="text-secondary text-center">
                          This patient is up to date
                        </div>
                      </div>
                    )}
                </Col>
              </Row>
            </Col>
          </Row>
        </TabPane>
        {/* Claims Tab, only if payor pages */}
        {tenantFeatures?.PayorPages && (
          <TabPane tabId="2">
            <Row>
              <Col xl="7" lg="7" md="12">
                <Card className="bg-white">
                  <CardBody className="p-0">
                    <div className="mx-7 my-4 d-flex justify-content-between align-items-center">
                      <div>
                        Medications populated from pharmacy claims data.
                      </div>
                      <div>Last updated: 6/30/2023</div>
                    </div>
                    <MedClaimsTable />
                  </CardBody>
                </Card>
              </Col>
              <Col xl="5" lg="5" md="12">
                <AlertCards />
                <DDIs
                  setLoadingInteractions={setLoadingInteractions}
                  patientId={patientId}
                  user={user}
                  isIE={isIE}
                  scrollPosition={scrollPosition}
                  setScrollPosition={setScrollPosition}
                />
              </Col>
            </Row>
          </TabPane>
        )}
      </TabContent>

      <Modal
        isOpen={modal}
        toggle={toggleAdd}
        className={classNames(
          { "scroll-modal": modalNumber === 1 },
          "center-modal  add-meds-modal"
        )}
      >
        <ModalHeader
          toggle={toggleAdd}
          close={<CloseButton toggle={toggleAdd} />}
        >
          {selectedMed.medicationName
            ? `Edit ${selectedMed.medicationName}`
            : "Add Medications"}
        </ModalHeader>
        <ModalBody>
          {modalNumber === 1 ? (
            <AddMedications
              meds={medications}
              submitAddMed={submitAddMed}
              selectedMed={selectedMed}
              setMeds={setMedlist}
              rowId={rowId}
              setModalNumber={setModalNumber}
              toggleDelete={toggleDelete}
              togglePause={togglePause}
            />
          ) : (
            <SuccessModal
              setRowId={setRowId}
              medName={selectedMed.medicationName}
              editVersion={!!selectedMed.medicationName}
              setSelectedMed={setSelectedMed}
              goBack={goBack}
              toggle={toggleAdd}
            />
          )}
        </ModalBody>
      </Modal>

      <DeleteModal
        name={name}
        toggleAdd={toggleAdd}
        deleteModal={deleteModal}
        toggleDelete={toggleDelete}
        rowId={rowId}
        submitAddMed={submitAddMed}
        selectedMed={selectedMed}
      />
      <PauseModal
        name={name}
        toggleAdd={toggleAdd}
        pauseModal={pauseModal}
        togglePause={togglePause}
        submitAddMed={submitAddMed}
        selectedMed={selectedMed}
      />
    </div>
  );
};

TabMedList.propTypes = {
  patientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isNewPatient: PropTypes.bool,
  loadingMedications: PropTypes.bool,
  fetchMedications: PropTypes.func,
  dataChange: PropTypes.bool,
  setDataChange: PropTypes.func,
  fetchSuggestedChanges: PropTypes.func,
  suggestedMedFilterApplied: PropTypes.bool
};

export { TabMedList };
