import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import "raf/polyfill";
import React from "react";
import ReactDOM from "react-dom";
import { datadogRum } from "@datadog/browser-rum";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import { ErrorBoundary } from "./_components";
import { TableStateProvider } from "./context/stateContext";
import { ThemeProvider } from "./context/themeContext";
// Registering Syncfusion license key
import { registerLicense } from "@syncfusion/ej2-base";
import { AlertProvider } from "./context/alertContext";
import { AuthProvider } from "./context/auth0Context";
import { UserProvider } from "./context/userContext";

if (!window.crypto) {
  window.crypto = window.msCrypto;
}

if (typeof window["TextEncoder"] !== "function") {
  const TextEncodingPolyfill = require("text-encoding");
  window["TextEncoder"] = TextEncodingPolyfill.TextEncoder;
  window["TextDecoder"] = TextEncodingPolyfill.TextDecoder;
}
if (!("remove" in Element.prototype)) {
  Element.prototype.remove = function () {
    if (this.parentNode) {
      this.parentNode.removeChild(this);
    }
  };
}

registerLicense(process.env.REACT_APP_SYNCFUSION_KEY);

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");
datadogRum.init({
  applicationId: "cbb351c6-6ec4-4516-90f7-0f06450288bf",
  clientToken: "pub17f43616955b55168f9d637956c9820e",
  site: "datadoghq.com",
  service: "providerportal",
  env: process.env.NODE_ENV,
  version: process.env.REACT_APP_VERSION,
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input"
});

startApp();
// }

function startApp() {
  datadogRum.startSessionReplayRecording();

  // if (process.env.NODE_ENV === "development") {
  //   const { worker } = require("./mocks/browser");
  //   worker.start();
  // }

  ReactDOM.render(
    <AuthProvider>
      <UserProvider>
        <AlertProvider>
          <ThemeProvider>
            <TableStateProvider>
              <Router basename={baseUrl}>
                <ErrorBoundary>
                  <App />
                </ErrorBoundary>
              </Router>
            </TableStateProvider>
          </ThemeProvider>
        </AlertProvider>
      </UserProvider>
    </AuthProvider>,
    rootElement
  );
}

// Uncomment the line above that imports the registerServiceWorker function
// and the line below to register the generated service worker.
// By default create-react-app includes a service worker to improve the
// performance of the application by caching static assets. This service
// worker can interfere with the Identity UI, so it is
// disabled by default when Identity is being used.
//
//registerServiceWorker();
