import classnames from "classnames";
import React, { useEffect, useState } from "react";
import {
  CustomInput,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  DropdownItem,
} from "reactstrap";
import PropTypes from "prop-types";
import {
  UseMobileMediaQuery,
  UseMobileOrTabletMediaQuery,
} from "../_helpers/media-queries";
import { ReactComponent as ChevronDown } from "../images/chevron_down_small.svg";
import { ReactComponent as Filter } from "../images/glyph_filter.svg";

const HeaderFilters = ({
  headerFilters,
  handleFilterChange,
  clearAllFilters,
  rightAlignedTableAction,
  filtersArr,
}) => {
  const [num, setNum] = useState(0);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOpts, setSelectedOpts] = useState(new Set());
  const [selectedRadio, setSelectedRadio] = useState(undefined);
  const is768Width = UseMobileOrTabletMediaQuery();
  const isMobileWidth = UseMobileMediaQuery();

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  //  If filtersArr changes, update the selectedOpts and number of filters
  useEffect(() => {
    if (filtersArr) {
      setSelectedOpts(new Set());
      // for each filter in filtersArr, add the filter.value array items to the selectedOpts set
      filtersArr.forEach((filter) => {
        filter.value.forEach((item) => {
          // to account for multiple filters with the same value, add the filter.id to the set
          setSelectedOpts((state) => state.add(`${filter.id}-${item}`));
        });
      });
    }
  }, [filtersArr]);

  useEffect(() => {
    if (filtersArr && filtersArr.length > 0) {
      filtersArr.forEach((filter) => {
        if (filter.value.length > 0) {
          setSelectedRadio(filter.value[0]);
        }
      });
    }
  }, [filtersArr]);

  // If selectedOpts changes, update the number of filters
  useEffect(() => {
    setNum(selectedOpts.size);
  }, [selectedOpts]);

  function handleChange(ev, headerFilter, option) {
    handleFilterChange(headerFilter.column, option, true);
  }

  function handleRadioChange(ev, headerFilter, option) {
    //If a radio is not yet selected add 1 to num - else leave num the same
    if (!selectedRadio) setNum((n) => n + 1);
    setSelectedRadio(option?.label);
    handleFilterChange(headerFilter.column, option, false);
    toggle();
  }

  function handleClearClick(e) {
    e.preventDefault();
    setNum(0);
    setSelectedRadio(undefined);
    setSelectedOpts(new Set());

    clearAllFilters();
  }

  return (
    <>
      {headerFilters && (
        <div
          className={classnames(
            { "ml-auto pl-2": is768Width },
            { "d-flex justify-content-end": !is768Width }
          )}
        >
          <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle
              tag="span"
              data-toggle="dropdown"
              aria-expanded={dropdownOpen}
              id="filter-button"
              tabIndex="0"
            >
              {isMobileWidth ? (
                <div className="btn btn-link p-0">
                  <Filter
                    className="glyph-grooveblue h16-w16-style"
                    aria-label="filter"
                  />{" "}
                </div>
              ) : (
                <div className="btn primary-underline px-0 text-secondary filter-btn">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>Add Filters ({num})</div>
                    <ChevronDown
                      className="glyph-gray"
                      aria-label="chevron down"
                    />
                  </div>
                </div>
              )}
            </DropdownToggle>

            <DropdownMenu className="p-4 header-filter mt-2">
              {headerFilters?.map((headerFilter, i) => (
                <div key={headerFilter.name} id="header">
                  <Form>
                    <FormGroup className="mb-2">
                      <label className="text-secondary">
                        {headerFilter.name}
                      </label>
                      {headerFilter.options.map((option, idx) => (
                        <DropdownItem
                          key={option.value}
                          tag="span"
                          className="p-0"
                          onClick={(e) => {
                            e.preventDefault();
                            document
                              .getElementById(
                                `${headerFilter.name}-${option.value}`
                              )
                              .click();
                          }}
                        >
                          {headerFilter.multi ? (
                            <CustomInput
                              type="checkbox"
                              className="dark-checkmark"
                              id={`${headerFilter.name}-${option.value}`}
                              name={headerFilter.column}
                              value={option.label}
                              checked={selectedOpts.has(
                                `${headerFilter.column}-${option.value}`
                              )}
                              onChange={(info) => {
                                handleChange(info, headerFilter, option);
                              }}
                              label={option.label}
                            ></CustomInput>
                          ) : (
                            <CustomInput
                              type="radio"
                              className=""
                              id={`${headerFilter.name}-${option.value}`}
                              name={headerFilter.column}
                              value={option.value}
                              checked={selectedRadio === option?.value}
                              onChange={(info) => {
                                handleRadioChange(info, headerFilter, option);
                              }}
                              label={option.label}
                            ></CustomInput>
                          )}
                        </DropdownItem>
                      ))}
                    </FormGroup>
                  </Form>
                </div>
              ))}
              <DropdownItem
                className="btn btn-link text-secondary p-0"
                onClick={handleClearClick}
                onKeyDown={(e) => {
                  // if the key is enter
                  if (e.key === "Enter") handleClearClick(e);
                }}
                disabled={num === 0}
              >
                Clear All
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>

          {!is768Width && rightAlignedTableAction}
        </div>
      )}
    </>
  );
};

HeaderFilters.propTypes = {
  headerFilters: PropTypes.array,
  handleFilterChange: PropTypes.func,
  clearAllFilters: PropTypes.func,
  rightAlignedTableAction: PropTypes.element,
  filtersArr: PropTypes.array,
};

export { HeaderFilters };
