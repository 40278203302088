import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useIdleTimer } from "react-idle-timer";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { CloseButton } from "./CloseButton";
import { useUserContext } from "../context/userContext";
import { clearLocalStorage } from "../_helpers";

const IdleLogout = () => {
  const [modal, setModal] = useState(false);
  const [remainingTime, setRemainingTime] = useState(60);
  const { logout } = useAuth0();
  const { userState } = useUserContext();
  const { user } = userState;
  function toggleModal() {
    setModal(!modal);
  }

  function onPrompt() {
    toggleModal();
  }

  function onIdle() {
    toggleModal();
    clearLocalStorage();
    logout({ logoutParams: { returnTo: window.location.origin } });
  }

  const { start, reset, pause, getRemainingTime } = useIdleTimer({
    onPrompt,
    onIdle,
    timeout: 1000 * 60 * 30,
    promptBeforeIdle: 60000,
    crossTab: true,
    emitOnAllTabs: true,
    syncTimers: 200,
  });

  function onReset() {
    toggleModal();
    reset();
  }
  useEffect(() => {
    let mounted = true;

    const timer = setInterval(() => {
      if (modal && getRemainingTime() <= 60000 && getRemainingTime() > 0) {
        setRemainingTime(Math.floor(getRemainingTime() / 1000));
      } else if (mounted) {
        setRemainingTime(59);
        clearInterval(timer);
      }
    }, 1000);
    return function cleanup() {
      mounted = false;
    };
  }, [getRemainingTime, modal]);

  useEffect(() => {
    if (user) {
      start();
    } else {
      setModal(false);
      pause();
    }
  }, [user, start, pause]);
  return (
    <Modal
      isOpen={modal}
      toggle={toggleModal}
      className="center-modal scroll-modal"
    >
      <ModalHeader close={<CloseButton toggle={onReset} />}>
        You have been inactive for 30 mintues
      </ModalHeader>
      <ModalBody>
        <div>
          <p>You will be automatically logged out in {remainingTime} seconds</p>
          <div className="d-flex pb-4">
            <button className="btn btn-primary mr-4" onClick={onReset}>
              Stay Logged In
            </button>
            <button
              className="btn btn-outline-secondary"
              onClick={() => {
                clearLocalStorage();
                logout({
                  logoutParams: { returnTo: window.location.origin },
                });
              }}
            >
              Log Out
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
export { IdleLogout };
