import React, { useEffect, useState } from "react";
import { TabPane } from "reactstrap";
import PropTypes from "prop-types";
import { findDuplicates } from "../_helpers";
import { ReactComponent as Exclamation } from "../images/glyph-exclamation.svg";
import { MyCheckbox, LanguageField } from ".";
import { MedTable } from "../invitations/MedTable";
import { Field, ErrorMessage } from "formik";
import Input from "react-phone-number-input/input";
import classnames from "classnames";
import { useUserContext } from "../context/userContext";

const SmartInviteForm = ({
  version,
  name,
  mrn,
  meds,
  setMeds,
  syncMedications,
  loading,
  toggle,
  isSubmitting,
  values,
  setFieldValue,
  errors,
  touched,
  isValid,
  cantSubmitInvite,
  setFieldTouched,
  inviteVersion,
  resetForm,
  missingDoB
}) => {
  const [hasDuplicates, setHasDuplicates] = useState(false);
  const [tableMeds, setTableMeds] = useState([]);
  const { userState } = useUserContext();
  const { user } = userState;

  useEffect(() => {
    findDuplicates(meds, setTableMeds, setHasDuplicates);
  }, [meds]);
  return (
    <>
      <TabPane tabId="2" data-testid="tab2">
        {version === "ehr" ? (
          <h3 className="text-center mb-5">Import Meds from EHR</h3>
        ) : (
          <div className="d-flex justify-content-between px-6 py-3">
            <div className="max-width-50">{name}</div>
            <div className="max-width-50">
              MRN(s):{" "}
              {mrn?.length
                ? mrn.map((item, idx) => (
                    <span key={item}>
                      {item}
                      {/* if not last item, add a comma and a space */}
                      {idx !== mrn.length - 1 && ", "}
                    </span>
                  ))
                : "N/A"}
            </div>
          </div>
        )}
        <p className="text-center">
          This information will be shown to the patient in the{" "}
          {user?.tenantsPreference?.appName || "EveryDose"} mobile app.{" "}
          <br></br>Please review it for accuracy and make edits as
          necessary.&nbsp;&nbsp;
          <a
            className="btn btn-link py-0 my-0"
            type="button"
            href="https://support.everydose.ai/provider/docs/ehr-sync"
            target="_blank"
            rel="noreferrer"
          >
            Learn more
          </a>
        </p>
        <div
          className={classnames("import-table pt-1 pb-0", {
            "smart-table": version === "ehr"
          })}
        >
          <div className="px-0 pt-0 pb-0">
            <MedTable
              meds={tableMeds}
              setMeds={setMeds}
              syncMedications={syncMedications}
              loading={loading}
              smartVersion={true}
              hasDuplicates={hasDuplicates}
              inviteVersion={inviteVersion}
            />
          </div>
        </div>
        {hasDuplicates && (
          <p className="text-center text-secondary">
            <Exclamation className="glyph-danger mb-1 mr-1" alt="exclamation" />
            Remove/unselect duplicate medications before proceeding.
          </p>
        )}
        {missingDoB && (
          <p className="text-center text-secondary">
            <Exclamation className="glyph-danger mb-1 mr-1" alt="exclamation" />
            This patient has no Date of Birth in the EHR. DoB is required to
            invite the patient. Please add a DoB in the EHR and refresh the
            page.
          </p>
        )}
        <div className="d-flex flex-md-row flex-column justify-content-center align-items-center mt-4 mb-5 mx-md-0 mx-4">
          <button
            type="button"
            // disabled={loading}
            data-testid="back-to-start"
            className="btn btn-outline-primary w-25 mr-md-5 mr-0 mb-md-0 mb-4"
            onClick={() => {
              toggle("1");
              if (resetForm) resetForm();
            }}
          >
            Back
          </button>
          <button
            type="button"
            disabled={loading || hasDuplicates || missingDoB}
            className="btn btn-primary w-25"
            onClick={() => {
              toggle("3");
            }}
          >
            {isSubmitting && (
              <span className="spinner-border spinner-border-sm mr-1"></span>
            )}
            Next
          </button>
        </div>
      </TabPane>
      <TabPane tabId="3" data-testid="tab3">
        <h3 className="text-center mb-5">Send Invitation</h3>
        <p className="text-center">
          We recommend sending through both email and text message.
        </p>

        <div className="card bg-white align-self-center invite">
          <div className="card-body">
            <div className="form-group mx-5">
              <div className="d-flex">
                <MyCheckbox
                  checked={values.emailCheckbox}
                  onChange={(e) => {
                    setFieldValue("emailCheckbox", e.target.checked);
                    if (e.target.checked) {
                      setFieldValue("neitherCheckbox", false);
                    }
                  }}
                  id="emailCheckbox"
                  data-testid="emailCheckbox"
                  name="emailCheckbox"
                  className="dark-checkmark"
                ></MyCheckbox>
                <label htmlFor="email">Email </label>
              </div>
              <Field
                name="email"
                id="email"
                required={values.emailCheckbox}
                onChange={(e) => {
                  setFieldValue("email", e.target.value);
                  if (e.target.value) {
                    setFieldValue("emailCheckbox", true);

                    setFieldValue("neitherCheckbox", false);
                  } else {
                    setFieldValue("emailCheckbox", false);
                  }
                }}
                placeholder="Patient's Email"
                type="email"
                className={classnames(
                  {
                    "is-invalid": errors.email && touched.email
                  },
                  "form-control"
                )}
              />
              <ErrorMessage
                name="email"
                component="div"
                className="invalid-feedback"
              />
            </div>
            <div className="form-group mx-5">
              <div className="d-flex">
                <MyCheckbox
                  className="dark-checkmark"
                  data-testid="phoneCheckbox"
                  onChange={(e) => {
                    setFieldValue("phoneCheckbox", e.target.checked);
                    if (e.target.checked)
                      setFieldValue("neitherCheckbox", false);
                  }}
                  checked={values.phoneCheckbox}
                  id="phoneCheckbox"
                  name="phoneCheckbox"
                ></MyCheckbox>
                <label htmlFor="phoneNumber">Mobile Phone Number</label>
              </div>
              <Input
                id="phoneNumber"
                value={values.phoneNumber}
                onChange={(val) => {
                  setFieldValue("phoneNumber", val);
                  setFieldTouched("phoneNumber", true);
                  if (val) {
                    setFieldValue("phoneCheckbox", true);
                    setFieldValue("neitherCheckbox", false);
                  } else {
                    setFieldValue("phoneCheckbox", false);
                  }
                }}
                defaultCountry="US"
                onBlur={setFieldTouched}
                name="phoneNumber"
                placeholder="Patient's Mobile"
                className={classnames(
                  {
                    "is-invalid": errors.phoneNumber && touched.phoneNumber
                  },
                  "form-control"
                )}
              />
            </div>
            <div className="d-flex mx-5 pt-5">
              <MyCheckbox
                className="gray-checkmark"
                onChange={(e) => {
                  setFieldTouched("neitherCheckbox", true);
                  setFieldValue("neitherCheckbox", e.target.checked);
                  if (e.target.checked) {
                    setFieldValue("emailCheckbox", false);
                    setFieldValue("phoneCheckbox", false);
                  }
                }}
                checked={values.neitherCheckbox}
                id="neitherCheckbox"
                name="neitherCheckbox"
              ></MyCheckbox>
              <span className={classnames("text-secondary")}>
                Neither. I'll provide the patient with the Activation Code
                manually.
              </span>
            </div>
          </div>
        </div>
        <LanguageField
          value={values.language}
          onChange={setFieldValue}
          centered={true}
        />

        <div className="d-flex flex-md-row flex-column justify-content-center align-items-center mt-4 mb-5 mx-md-0 mx-4">
          <button
            type="button"
            className="btn btn-outline-primary w-50 mr-md-5 mr-0 mb-md-0 mb-4"
            onClick={() => toggle("2")}
          >
            Back
          </button>
          <button
            type="submit"
            disabled={isSubmitting || !isValid || cantSubmitInvite(values)}
            className="btn btn-primary w-50"
          >
            {isSubmitting && (
              <span className="spinner-border spinner-border-sm mr-1"></span>
            )}
            {version === "portal" ? "Send Invitation" : "Send"}
          </button>
        </div>
      </TabPane>
    </>
  );
};

SmartInviteForm.propTypes = {
  version: PropTypes.string,
  name: PropTypes.string,
  mrn: PropTypes.array,
  meds: PropTypes.array,
  setMeds: PropTypes.func,
  syncMedications: PropTypes.func,
  loading: PropTypes.bool,
  toggle: PropTypes.func,
  isSubmitting: PropTypes.bool,
  values: PropTypes.object,
  setFieldValue: PropTypes.func,
  errors: PropTypes.object,
  touched: PropTypes.object,
  isValid: PropTypes.bool,
  cantSubmitInvite: PropTypes.func,
  setFieldTouched: PropTypes.func,
  inviteVersion: PropTypes.bool,
  resetForm: PropTypes.func,
  missingDoB: PropTypes.bool
};

export { SmartInviteForm };
