import React, { useState, useEffect, useMemo } from "react";
import { Col, Card, CardHeader, CardBody } from "reactstrap";
import { MyStaticTable } from "../../../_components";
import { ReactToPrintButton } from "./ReactToPrintButton";
import { TooltipCell } from "./TooltipCell";
import { useUserContext } from "../../../context/userContext";
import PropTypes from "prop-types";

export const HealthTrackerTable = ({
  tracker,
  trackerInfo,
  selectedUnit,
  alternateUnitSelected
}) => {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const componentRef = React.useRef();
  const { userState } = useUserContext();
  const { user } = userState;

  const getColumns = React.useCallback(
    (row) => {
      const objectWithMostKeys = data.reduce(
        (max, obj) =>
          Object.keys(obj).length > Object.keys(max).length ? obj : max,
        {}
      );

      return Object.keys(objectWithMostKeys).map((key, i) => {
        return {
          Header: key,
          accessor: key,
          key: i,
          Cell: (props) => {
            return (
                <TooltipCell value={props.value} row={props.row} />
            );
          }
        };
      });
    },
    [data]
  );

  const cholesterolOrder = useMemo(
    () => ["HDL", "LDL", "Triglycerides", "Total Cholesterol"],
    []
  );

  const dataArray = React.useCallback(
    (healthTrackersLog, name) => {
      return healthTrackersLog.reduce((result, log) => {
        let obj = {
          Date: new Date(log.logDate).toLocaleDateString("en-US", {
            month: "numeric",
            day: "numeric"
          })
        };
        if (name === "Cholesterol") {
          log.healthTrackersLogDetails.sort(
            (a, b) =>
              cholesterolOrder.indexOf(a.valueTypeDescription) -
              cholesterolOrder.indexOf(b.valueTypeDescription)
          );
        }
        log.healthTrackersLogDetails.forEach((detail) => {
          let value = detail.value;
          let valueTypeDescription = detail.valueTypeDescription;
          if (
            valueTypeDescription === "Sleep Duration" ||
            valueTypeDescription === "Duration"
          ) {
            valueTypeDescription = "Time";
            value = Math.floor(value / 60) + " hrs, " + (value % 60) + " mins";
          }
          obj[`${valueTypeDescription}`] = alternateUnitSelected
            ? trackerInfo.conversion(value)
            : value;
        });
        obj["Notes"] = log.notes;
        result.push(obj);

        return result;
      }, []);
    },
    [alternateUnitSelected, cholesterolOrder, trackerInfo]
  );

  useEffect(() => {
    if (tracker.healthTrackerType) {
      setData(dataArray(tracker.healthTrackersLog, trackerInfo.name));
    }
  }, [dataArray, tracker, trackerInfo.name]);

  useEffect(() => {
    if (data.length) {
      setColumns(getColumns());
    }
  }, [data, getColumns]);
  return (
    <Col xl="6" lg="12">
      <Card className="bg-white" innerRef={componentRef}>
        <CardHeader className="bg-white pt-4">
          <div className="d-flex justify-content-between">
            {!user?.isSmartSession ? (
              <ReactToPrintButton componentRef={componentRef} />
            ) : (
              <div></div>
            )}
            <h6 className="font-weight-normal pt-2">Units: {selectedUnit}</h6>
          </div>
        </CardHeader>
        <CardBody className="px-0 py-4 overflow-hidden">
          <MyStaticTable data={data} columns={columns} pagination={true} />
        </CardBody>
      </Card>
    </Col>
  );
};
HealthTrackerTable.propTypes = {
  tracker: PropTypes.object,
  trackerInfo: PropTypes.object,
  selectedUnit: PropTypes.string,
  alternateUnitSelected: PropTypes.bool
};
