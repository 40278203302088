import React from "react";
import ReactToPrint from "react-to-print";
import PropTypes from "prop-types";

export const ReactToPrintButton = ({ componentRef }) => (
  <ReactToPrint
    trigger={() => (
      <button className="btn btn-link hide-on-print">Print Report</button>
    )}
    content={() => componentRef.current}
  />
);
ReactToPrintButton.propTypes = {
  componentRef: PropTypes.object
};
