import React, { useState } from "react";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { Card, CardBody } from "reactstrap";
import { LeafletComponent, PageHeader } from "../_components";
import PropTypes from "prop-types";
import { isIOS } from "../_helpers";

const PrintButton = ({ componentRef }) => {
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Medication Leaflet Report",
  });

  return (
    <button className="btn btn-link" onClick={handlePrint}>
      Print
    </button>
  );
};
PrintButton.propTypes = {
  componentRef: PropTypes.object,
};

const LeafletReport = ({
  setActiveTab,
  leaflets,
  noLeaflets,
  editedMedlist,
  getResources,
  version,
}) => {
  const [leafletTab, setLeafletTab] = useState(0);
  const [submitting, setSubmitting] = useState(false);

  const componentRef = React.useRef();

  const toggle = (tab) => {
    if (leafletTab !== tab) {
      setSubmitting(true);
      getResources(editedMedlist, tab, setSubmitting);
      setLeafletTab(tab);
    }
  };

  const isIOSDevice = isIOS();

  return (
    <div>
      {version !== "patient" ? (
        <PageHeader
          title="Medication Leaflet Report"
          onBackClick={() => setActiveTab(0)}
          backText="to Leaflet Search"
        />
      ) : (
        <> </>
      )}

      <div className="d-flex justify-content-center">
        <Card
          className="bg-white align-self-center"
          style={
            version !== "patient"
              ? { width: "50rem" }
              : { width: "100%", border: 0, boxShadow: "none" }
          }
        >
          <CardBody>
            <div className="d-flex justify-content-between">
              <h3>Medication Leaflet Report</h3>
              <div>
                {!isIOSDevice && leaflets.length ? (
                  <ReactToPrint
                    trigger={() => <PrintButton componentRef={componentRef} />}
                    content={() => componentRef.current}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
            {version !== "patient" ? (
              <>
                <p>Generate medication information leaflets for patients</p>
                {leaflets.length ? (
                  <div>
                    Medication Leaflets were found for the following&nbsp;
                    {leaflets.length}
                    &nbsp;drug(s):
                  </div>
                ) : (
                  <></>
                )}
                <ul>
                  {leaflets.map((med) => (
                    <li key={med.name}>{med.name}</li>
                  ))}
                </ul>
                {noLeaflets?.length ? (
                  <div>
                    Medication Leaflets were not found for the following&nbsp;
                    {noLeaflets.length}&nbsp;drug(s):
                  </div>
                ) : (
                  <></>
                )}
                <ul>
                  {noLeaflets?.map((med) => (
                    <li key={med.name}>{med.name}</li>
                  ))}
                </ul>
                <button
                  className="btn btn-link pl-5"
                  onClick={() => setActiveTab(0)}
                >
                  Edit List
                </button>
              </>
            ) : (
              <></>
            )}
            <LeafletComponent
              leafletTab={leafletTab}
              toggle={toggle}
              submitting={submitting}
              loading={submitting}
              componentRef={componentRef}
              leaflets={leaflets}
            />
          </CardBody>
        </Card>
      </div>
    </div>
  );
};
LeafletReport.propTypes = {
  setActiveTab: PropTypes.func,
  leaflets: PropTypes.array,
  noLeaflets: PropTypes.array,
  editedMedlist: PropTypes.array,
  getResources: PropTypes.func,
  version: PropTypes.string,
};

export { LeafletReport };
