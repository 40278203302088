import React from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { ReactComponent as Caution } from "../../../images/caution.svg";
import { ReactComponent as Exclamation } from "../../../images/circle-exclaimation.svg";
import { ReactComponent as Check } from "../../../images/circle-check.svg";
import { claimsAlertData } from "../DummyClaimsData";
import PropTypes from "prop-types";

const AlertCard = ({ medicationName, description, severity, type }) => {
  return (
    <Card className="bg-white mx-lg-0 mx-md-3 mx-3">
      <CardHeader className="bg-white">
        <span className="my-3 d-flex">
          {severity === "major" && (
            <Caution
              className="glyph-danger mr-2 caution-style"
              aria-label="caution"
            />
          )}
          {severity === "moderate" && (
            <Exclamation
              className="glyph-warning-stroke mr-2 caution-style"
              aria-label="exclamation"
            />
          )}
          {severity === "low" && (
            <Check
              className="glyph-success mr-2 caution-style"
              aria-label="check"
            />
          )}
          {type}
        </span>
      </CardHeader>
      <CardBody className="pt-0">
        <div>
          <h6 className="mb-2">{medicationName}</h6>
        </div>
        <div>
          {description.split("\n").map((line, idx) => {
            return (
              <p key={idx} className="mb-0">
                {line}
              </p>
            );
          })}
        </div>
      </CardBody>
    </Card>
  );
};
AlertCard.propTypes = {
  medicationName: PropTypes.string,
  description: PropTypes.string,
  severity: PropTypes.string,
  type: PropTypes.string,
};

export const AlertCards = () => {
  return (
    <Row>
      {claimsAlertData.map((alert, index) => (
        <Col xs="12" md="6" key={index}>
          <AlertCard {...alert} />
        </Col>
      ))}
    </Row>
  );
};
