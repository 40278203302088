import PropTypes from "prop-types";
import React from "react";
import { Card, CardBody, Row } from "reactstrap";

const ReportsSelector = ({
  setSelectedReportType,
  reportTypes,
  toggle,
  disabledButtons
}) => {
  return (
    <div>
      <Card className="bg-white">
        <CardBody>
          <h4 className="card-title"> Create Report</h4>
          <p>Click on a report type below to begin creating a report</p>
          <Row className="d-flex flex-wrap">
            {/* map over reportTypes and create primary buttons in rows of three that will trigger api call tbd*/}
            {reportTypes
              // .filter((type) => ![50].includes(type.value))
              .map((reportType, index) => {
                return (
                  <div
                    className="col-6 col-sm-4 col-md-3 col-mdlg-12 col-lg-12 col-xxl-6 "
                    key={reportType.value}
                  >
                    <button
                      className="btn btn-primary mb-3 w-100 min-h-2-75rem"
                      id={`button-${reportType.value}`}
                      onClick={() => {
                        const btn = document.getElementById(
                          `button-${reportType.value}`
                        );
                        btn.blur();
                        setSelectedReportType(reportType);
                        toggle();
                      }}
                      // disabled if the reportType is in the disabledButtons array
                      disabled={disabledButtons.includes(reportType.value)}
                    >
                      {reportType.label}
                    </button>
                  </div>
                );
              })}
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

ReportsSelector.propTypes = {
  setSelectedReportType: PropTypes.func,
  reportTypes: PropTypes.array,
  toggle: PropTypes.func,
  disabledButtons: PropTypes.array
};

export { ReportsSelector };
