import PropTypes from "prop-types";
import React from "react";
import { useUserContext } from "../context/userContext";
import { ReactComponent as Download } from "../images/glyph-download.svg";
import { ReactComponent as Exclamation } from "../images/glyph-exclamation.svg";
import { PrintSignUpDoc } from "./PrintSignUpDoc";

function ActivationCode({ signUpDocument }) {
  const { userState } = useUserContext();
  const { user } = userState;

  return (
    <div className="mt-4">
      <h4 className="mb-0">
        <Exclamation
          aria-label="alert - this is your activation code"
          className="glyph-link-blue mr-2 mb-1"
          href="#"
          id="activation-tooltip"
        />
        {user?.activationCode}
      </h4>
      <p className="mb-2">This is the Activation Code for your patients.</p>
      <PrintSignUpDoc
        text={
          <>
            <Download className="mr-2 " aria-label="download" />
            <span id="sign-up-tooltip">Sign up instructions for patients</span>
          </>
        }
      />
    </div>
  );
}

ActivationCode.propTypes = {
  // page: PropTypes.string,
  signUpDocument: PropTypes.bool,
};

export { ActivationCode };
