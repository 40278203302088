import React, { useState } from "react";
import { MyDropdown, MyStaticTable, findColor } from "../../../_components";
import { ReactComponent as GylphMenu } from "../../../images/glyph_menu_2.svg";
import { Card } from "reactstrap";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { dummyClaimsData } from "../DummyClaimsData";
import "react-circular-progressbar/dist/styles.css";

const headerTabs = [
  { name: "By Medication", id: "Medication" },
  { name: "By Measure", id: "Measure" }
];

export const AdhClaimsTable = (props) => {
  const [data, setData] = useState(
    // dummy claims data of type "Medication"
    dummyClaimsData.find((d) => d.type === "Medication").data
  );
  const highContrast =
    document.body.getAttribute("data-theme") === "high-contrast";
  const columns = React.useMemo(
    () => [
      {
        Header: "Medication",
        accessor: "name"
      },
      {
        Header: "Days On Hand",
        accessor: "daysOnHand",
        Cell: ({ value }) => {
          return value > 0 ? (
            <span>{value}</span>
          ) : (
            <span>
              {value}
              <div
                className="d-inline-block ml-1 h-10px w-10px rounded-circle"
                style={{ background: "var(--danger)" }}
              ></div>
            </span>
          );
        }
      },
      {
        Header: "PDC Ratio",
        accessor: "pdcRatio",
        Cell: ({ value }) => {
          const color = findColor(value, highContrast);
          return (
            <div className="h20-w20-style d-flex white-space-nowrap">
              <CircularProgressbar
                value={value}
                text=""
                strokeWidth={18}
                styles={buildStyles({
                  pathColor: color,
                  textColor: "var(--dark)",
                  trailColor: "#edeeef"
                })}
              />
              <span className="ml-1">{value}%</span>
            </div>
          );
        }
      },
      {
        Header: "",
        id: "actions",
        accessor: (row) => (
          <div className="my-neg-8px text-right">
            <MyDropdown
              details={{
                name: "row menu",
                glyph: <GylphMenu className="width-8" aria-label="menu" />,
                items: []
              }}
              // container="body"
              direction="right"
            />
          </div>
        )
      }
    ],
    [highContrast]
  );

  return (
    <Card className="bg-white claims-table">
      <MyStaticTable
        data={data}
        columns={columns}
        headerTabs={headerTabs}
        setClaimsData={setData}
        dummyClaimsData={dummyClaimsData}
        {...props}
      />
    </Card>
  );
};
