import React from "react";
import PropTypes from "prop-types";
import { MySelectBtnLink } from "../_components";

export const HeaderMenu = ({
  options,
  value,
  handleHeaderMenuChange,
  headerMenuText
}) => {
  return (
    <div className="d-flex ml-4">
      <label
        htmlFor="invitationType"
        className="text-secondary mr-1 mb-0 font-weight-normal"
      >
        <span>{headerMenuText}: </span>
      </label>
      <div className="mt-neg-0-5rem ml-2 min-width-9rem">
        <MySelectBtnLink
          options={options.filter((option) => option.value !== value.value)}
          name="invitationType"
          value={value}
          onChange={handleHeaderMenuChange}
        />
      </div>
    </div>
  );
};

HeaderMenu.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.object.isRequired,
  handleHeaderMenuChange: PropTypes.func.isRequired,
  headerMenuText: PropTypes.string.isRequired,
};
