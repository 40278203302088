import React, { useState } from "react";
import { Popover, PopoverBody, PopoverHeader } from "reactstrap";
import { ReactComponent as GlyphExclamation } from "../images/glyph-exclamation.svg";
import { CloseButton } from "../_components";
import { UseMobileOrTabletMediaQuery } from "../_helpers/media-queries";
import PropTypes from "prop-types";

const CustomMedPopover = ({ id, text }) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const is_touch_device = "ontouchstart" in window;
  const isMobileOrTabletWidth = UseMobileOrTabletMediaQuery();

  return (
    <div>
      <button
        className="btn focusable-text"
        id={"CMPopover-" + id}
        aria-describedby="custom med"
      >
        <GlyphExclamation
          aria-label="custom-med"
          className="glyph-grooveblue"
        />
      </button>

      <Popover
        id={id}
        trigger={is_touch_device ? "click" : "hover focus"}
        className="text-left width-220"
        placement={"right"}
        isOpen={popoverOpen}
        target={"CMPopover-" + id}
        toggle={() => setPopoverOpen(!popoverOpen)}
      >
        {isMobileOrTabletWidth && (
          <PopoverHeader>
            {" "}
            <CloseButton
              toggle={() => setPopoverOpen(!popoverOpen)}
              color="glyph-white mb-2"
            />
          </PopoverHeader>
        )}
        <PopoverBody>
          <div>
            {text ||
              "This med was added by the mobile app user but was not found in our drug database."}
          </div>
        </PopoverBody>
      </Popover>
    </div>
  );
};

CustomMedPopover.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string
};

export { CustomMedPopover };
