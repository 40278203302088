import React from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import classnames from "classnames";
import PropTypes from "prop-types";

export const MyPagination = ({ activeTab }) => {
  return (
    <Pagination
      size="sm"
      role="tablist"
      aria-label="Page navigation example"
      className="nav"
    >
      <PaginationItem className="import-circles">
        <PaginationLink className={classnames({ active: activeTab === "1" })}>
          1
        </PaginationLink>
      </PaginationItem>
      <PaginationItem className="import-circles">
        <PaginationLink className={classnames({ active: activeTab === "2" })}>
          2
        </PaginationLink>
      </PaginationItem>
      <PaginationItem className="import-circles">
        <PaginationLink className={classnames({ active: activeTab === "3" })}>
          3
        </PaginationLink>
      </PaginationItem>
    </Pagination>
  );
};

MyPagination.propTypes = {
  activeTab: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
