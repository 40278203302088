import React, { lazy, Suspense } from "react";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Select from "react-select";
import PropTypes from "prop-types";
import { Loader } from "../_components";
import { datePickerColourStyles } from "../_helpers";
const DatePicker = lazy(() => import("react-datepicker"));

const DateOfBirthField = ({
  readonly,
  name,
  value,
  onChange,
  className,
  onBlur,
  touched,
  dayClassName,
  id
}) => {
  const currentYear = new Date().getFullYear();
  const range = (start, stop, step) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => start + i * step
    );
  //Setting years for the dropdown from 1900-2021
  const years = range(currentYear, currentYear - 110, -1);

  const months = [
    { value: "January", label: "January" },
    { value: "February", label: "February" },
    { value: "March", label: "March" },
    { value: "April", label: "April" },
    { value: "May", label: "May" },
    { value: "June", label: "June" },
    { value: "July", label: "July" },
    { value: "August", label: "August" },
    { value: "September", label: "September" },
    { value: "October", label: "October" },
    { value: "November", label: "November" },
    { value: "December", label: "December" }
  ];
  //Still setting date field to invalid if there's no value before submit
  if (touched && !value) {
    className = className + " is-invalid";
  }

  return (
    <Suspense fallback={<Loader />}>
      <DatePicker
        id={id}
        popperPlacement="bottom-start"
        renderCustomHeader={({ date, changeYear, changeMonth }) => (
          <div className="d-flex justify-content-center">
            <Select
              value={{ label: date.getFullYear(), value: date.getFullYear() }}
              onChange={(value) => changeYear(value.value)}
              options={years.map((year) => {
                return { value: year, label: year };
              })}
              id="years"
              // isSearchable={false}
              styles={datePickerColourStyles}
            ></Select>
            <Select
              className="select"
              value={months[date.getMonth()]}
              onChange={(value) => {
                changeMonth(months.indexOf(value));
              }}
              id="months"
              // isSearchable={false}
              options={months}
              styles={datePickerColourStyles}
            ></Select>
          </div>
        )}
        dayClassName={dayClassName}
        readOnly={readonly}
        placeholderText="M/D/YYYY"
        dateFormat="M/d/yyyy"
        className={className}
        maxDate={new Date()}
        minDate={
          // new date minus 110 years
          new Date(new Date().setFullYear(new Date().getFullYear() - 110))
        }
        selected={(value && new Date(value)) || null}
        onChange={(val) => {
          onChange(id, val);
          if (name === "dateOfBirth-invite") {
            //Clear field if invalid date is typed in (can't select it)
            if (val) {
              localStorage.setItem(
                "dateOfBirth",
                moment(val).format("MM/DD/YYYY")
              );
            } else localStorage.removeItem("dateOfBirth");
          }
        }}
        onBlur={() => {
          onBlur(id, true);
        }}

      />
    </Suspense>
  );
};

DateOfBirthField.propTypes = {
  readonly: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  onChange: PropTypes.func,
  className: PropTypes.string,
  dayClassName: PropTypes.func,
  id: PropTypes.string,
  onBlur: PropTypes.func,
  touched: PropTypes.bool
};

export { DateOfBirthField };
