import classnames from "classnames";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { Col, Progress, Row, TabContent, TabPane } from "reactstrap";
import {
  AdhSummary,
  MedicationSelector,
  NewPatientAlert
} from "../../_components";
import { createAdherence } from "../../_helpers";
import {
  Use1280MediaQuery,
  UseMobileMediaQuery
} from "../../_helpers/media-queries";
import { patientService } from "../../_services";
import { PatientContext } from "../../context/patientContext";
import { useUserContext } from "../../context/userContext";
import { ClaimsNav } from "./ClaimsNav";
import {
  AdhClaimsTable,
  Calendar,
  GanttChart,
  UserCreatedEvent,
  MedsHistoryEvent,
  createMonthlyEvents,
  createWeeklyEvents
} from "./adhComponents";

const AdhTabSummary = ({
  isNewPatient,
  patientId,
  handleAdherenceSummaryFilterChange,
  loadingAdhrence,
  range,
  activeOption,
  setActiveOption,
  adhSummary
}) => {
  function findColor(adh) {
    if (adh.percent >= 80) return "success";
    if (adh.percent < 80 && adh.percent >= 50) return "warning";
    else return "danger";
  }
  return (
    <div
      className={
        classnames({ "alert-border": isNewPatient }) + " card bg-white"
      }
    >
      {<NewPatientAlert id={"tabO-9"} isNewPatient={isNewPatient} />}
      <div className="card-header">Adherence Summary</div>
      <div className="card-body">
        <AdhSummary
          patientId={patientId}
          adherence={adhSummary}
          isNewPatient={isNewPatient}
          handleAdherenceSummaryFilterChange={
            handleAdherenceSummaryFilterChange
          }
          loadingAdhrence={loadingAdhrence}
          version="adherence"
          range={range}
          activeOption={activeOption}
          setActiveOption={setActiveOption}
        />

        <Progress
          barAriaValueText={"progressbar " + adhSummary.percent + "%"}
          barAriaLabelledBy={"progressbar2"}
          value={adhSummary.percent}
          color={findColor(adhSummary)}
        />
        <div className="text-left mt-1 mb-4">
          <span>
            <h3 className="mt-4" id="progressbar2">
              {adhSummary.percent}%
              <span className="small ml-2">Avg. Adherence</span>
            </h3>
          </span>
        </div>
      </div>
    </div>
  );
};
AdhTabSummary.propTypes = {
  isNewPatient: PropTypes.bool,
  patientId: PropTypes.string,
  handleAdherenceSummaryFilterChange: PropTypes.func,
  loadingAdhrence: PropTypes.bool,
  range: PropTypes.object,
  activeOption: PropTypes.string,
  setActiveOption: PropTypes.func,
  adhSummary: PropTypes.shape({
    percent: PropTypes.number
  })
};

const initialAdherenceState = {
  eventDetails: { firstTime: "07:00:00", events: [] },
  calendar: [],
  loadingCalendar: true,
  calendarView: "dayGridMonth",
  days: {
    startDay: moment()
      .clone()
      .utc()
      .startOf("month")
      .format("YYYY-MM-DDTHH:mm:ss"),
    endDay: moment().utc().format("YYYY-MM-DDTHH:mm:ss")
  },
  selectedMedicationIds: [],
  selectedEvent: false,
  eventInfo: false,
  availableMedications: [],
  isAllMedications: true,
  currentDate: moment().utc().format(),
  tabAdhAdherence: undefined,
  tabAdhSummary: {}
};

const adhReducer = (state, action) => {
  switch (action.type) {
    case "SET_CALENDAR":
      return {
        ...state,
        calendar: action.payload
      };
    case "SET_EVENTS":
      let eventDetails = {};
      if (state.calendarView === "dayGridMonth") {
        eventDetails = createMonthlyEvents(
          action.payload,
          state.selectedMedicationIds
        );
      } else {
        eventDetails = createWeeklyEvents(
          action.payload,
          state.selectedMedicationIds
        );
      }
      return {
        ...state,
        eventDetails: eventDetails
      };
    case "SET_CALENDAR_VIEW":
      return {
        ...state,
        calendarView: action.payload
      };
    case "SET_LOADING_CALENDAR":
      return {
        ...state,
        loadingCalendar: action.payload
      };
    case "SET_DAYS":
      // action.payload will include current date and whether it is week or month view
      return {
        ...state,
        days: {
          startDay: moment(action.payload.currentDate)
            .add(1, "days")
            .utc()
            .clone()
            .startOf(action.payload.view)
            .format("YYYY-MM-DDTHH:mm:ss"),
          endDay: moment(action.payload.currentDate)
            .utc()
            .clone()
            .endOf(action.payload.view)
            .format("YYYY-MM-DDTHH:mm:ss")
        }
      };

    case "SET_SELECTED_MEDICATION_IDS":
      return {
        ...state,
        selectedMedicationIds: action.payload
      };
    case "SET_SELECTED_EVENT":
      return {
        ...state,
        selectedEvent: action.payload
      };
    case "SET_EVENT_INFO":
      return {
        ...state,
        eventInfo: action.payload
      };
    case "SET_AVAILABLE_MEDICATIONS":
      return {
        ...state,
        availableMedications: action.payload
      };

    case "SET_IS_ALL_MEDICATIONS":
      return {
        ...state,
        isAllMedications: action.payload
      };

    case "SET_ADHERENCE":
      return {
        ...state,
        tabAdhAdherence: action.payload
      };

    case "SET_ADH_SUMMARY":
      return {
        ...state,
        tabAdhSummary: action.payload
      };

    default:
      return state;
  }
};

const TabAdherence = ({ patientId, isNewPatient, loadingMedications }) => {
  const { userState } = useUserContext();
  const { tenantFeatures } = userState;
  const { state } = useContext(PatientContext);
  const { medications, adherence } = state;
  const [adhState, adhDispatch] = useReducer(adhReducer, initialAdherenceState);
  const is1280Width = Use1280MediaQuery();
  const isMobileWidth = UseMobileMediaQuery();
  const {
    selectedMedicationIds,
    selectedEvent,
    availableMedications,
    isAllMedications,
    calendar,
    tabAdhAdherence,
    tabAdhSummary
  } = adhState;
  const [range, setRange] = useState({
    start: moment().utc().subtract(29, "days").startOf("day").format(),
    end: moment().utc().format()
  });
  const [activeOption, setActiveOption] = useState("Last 30 Days");

  const [loadingAdhrence, setLoadingAdhrence] = React.useState(false);
  const [activeTab, setActiveTab] = useState(
    tenantFeatures?.PayorPages ? "2" : "1"
  );
  const fetchIdRef = React.useRef(0);

  //Get 3 months initially for adherence plot

  const eventTypeHistory = "HISTORY_EVENT";

  const medSelectFilterOptions = [
    { value: "ALL", label: "All Medications" },
    { value: "ACTIVE", label: "Active Medications" },
    { value: "PAUSED", label: "Paused Medications" },
    { value: "REMOVED", label: "Deleted Medications" }
  ];

  const mounted = React.useRef(false);
  React.useEffect(() => {
    mounted.current = true; // Will set it to true on mount ...
    return () => {
      mounted.current = false;
    }; // ... and to false on unmount
  }, []);
  let calendarRef = React.createRef();
  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  useEffect(() => {
    if (mounted.current) {
      adhDispatch({
        type: "SET_AVAILABLE_MEDICATIONS",
        payload: medications
      });
      adhDispatch({
        type: "SET_SELECTED_MEDICATION_IDS",
        payload: medications.map((val) => val.id)
      });
    }
  }, [medications]);

  useEffect(() => {
    if (!tabAdhAdherence && adherence.length) {
      adhDispatch({
        type: "SET_ADHERENCE",
        payload: adherence
      });
      adhDispatch({
        type: "SET_ADH_SUMMARY",
        payload: createAdherence(adherence)
      });
    }
  }, [adherence, tabAdhAdherence]);

  useEffect(() => {
    if (tabAdhAdherence?.length && selectedMedicationIds.length) {
      let filteredMeds = tabAdhAdherence.filter((val) => {
        if (selectedMedicationIds.includes(val.id)) {
          return val;
        } else return null;
      });
      adhDispatch({
        type: "SET_ADH_SUMMARY",
        payload: createAdherence(filteredMeds)
      });
    }
  }, [tabAdhAdherence, selectedMedicationIds]);

  // dispatch set events when selected med ids change
  useEffect(() => {
    adhDispatch({
      type: "SET_EVENTS",
      payload: calendar
    });
  }, [calendar, selectedMedicationIds]);

  const handleAdherenceSummaryFilterChange = (start, end) => {
    setRange({
      start: moment(start).utc().format(),
      end: moment(end).utc().format()
    });
    fetchAdhrence(
      patientId,
      moment(start).utc().format(),
      moment(end).utc().format()
    );
  };
  const handleMedSelectFilterChange = (name, option) => {
    let meds = [];
    if (option.value === "ALL") {
      meds = medications;
    } else if (option.value === "ACTIVE") {
      meds = medications.filter((val) => val.status === 0);
    } else if (option.value === "PAUSED") {
      meds = medications.filter((val) => val.status === 2);
    } else if (option.value === "REMOVED") {
      meds = medications.filter((val) => val.status === 1);
    }
    //Reset med selection when filter is selected
    adhDispatch({
      type: "SET_AVAILABLE_MEDICATIONS",
      payload: meds
    });
    adhDispatch({
      type: "SET_SELECTED_MEDICATION_IDS",
      payload: meds.map((val) => val.id)
    });
    adhDispatch({
      type: "SET_IS_ALL_MEDICATIONS",
      payload: true
    });
  };

  const handleSelectAllRadioChange = (val) => {
    adhDispatch({
      type: "SET_IS_ALL_MEDICATIONS",
      payload: true
    });
    adhDispatch({
      type: "SET_SELECTED_MEDICATION_IDS",
      payload: availableMedications.map((val) => val.id)
    });
  };

  const handleMedClick = (medId) => {
    let meds = [];
    if (isAllMedications) {
      adhDispatch({
        type: "SET_IS_ALL_MEDICATIONS",
        payload: false
      });
    } else {
      meds = selectedMedicationIds;
    }
    const index = meds.indexOf(medId);
    if (index !== -1) {
      meds.splice(index, 1);
    } else {
      meds.push(medId);
    }
    if (meds.length === 0) {
      adhDispatch({
        type: "SET_IS_ALL_MEDICATIONS",
        payload: true
      });
      adhDispatch({
        type: "SET_SELECTED_MEDICATION_IDS",
        payload: availableMedications.map((val) => val.id)
      });
    } else {
      adhDispatch({
        type: "SET_SELECTED_MEDICATION_IDS",
        payload: meds.map((val) => val)
      });
    }
  };
  const fetchAdhrence = React.useCallback((id, start, end) => {
    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;
    // Set the loading state
    setLoadingAdhrence(true);
    if (fetchId === fetchIdRef.current) {
      // Clear existing data
      // Set the loading state
      setLoadingAdhrence(true);
      adhDispatch({
        type: "SET_ADHERENCE",
        payload: []
      });
      // Clear existing data
      patientService
        .getAdhrence(id, start, end)
        .then((res) => {
          if (mounted.current) {
            adhDispatch({
              type: "SET_ADHERENCE",
              payload: res
            });
            adhDispatch({
              type: "SET_ADH_SUMMARY",
              payload: createAdherence(res)
            });

            setLoadingAdhrence(false);
          }
        })
        .catch((e) => {
          if (mounted.current) setLoadingAdhrence(false);
        });
    }
  }, []);
  //These two are for navigating between event popovers in mobile view
  const handlePreviousClick = (info) => {
    let date = new Date(selectedEvent.id);
    date.setDate(date.getDate() - 1);
    let newInfo = calendarRef.current
      .getApi()
      .getEventById(date.toLocaleString());
    adhDispatch({
      type: "SET_SELECTED_EVENT",
      payload: newInfo
    });
  };

  const handleNextClick = (info) => {
    let date = new Date(selectedEvent.id);
    date.setDate(date.getDate() + 1);
    let newInfo = calendarRef.current
      .getApi()
      .getEventById(date.toLocaleString());

    adhDispatch({
      type: "SET_SELECTED_EVENT",
      payload: newInfo
    });
  };

  // Render the UI for your table
  return (
    <div>
      {tenantFeatures?.PayorPages && (
        <ClaimsNav activeTab={activeTab} toggleTab={toggleTab} />
      )}
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          {is1280Width ? (
            <Row>
              <Col md="6">
                {!isMobileWidth && (
                  <MedicationSelector
                    medSelectFilterOptions={medSelectFilterOptions}
                    handleMedSelectFilterChange={handleMedSelectFilterChange}
                    isAllMedications={isAllMedications}
                    handleSelectAllRadioChange={handleSelectAllRadioChange}
                    availableMedications={availableMedications}
                    handleMedClick={handleMedClick}
                    selectedMedicationIds={selectedMedicationIds}
                    loadingAdhrence={loadingAdhrence}
                  />
                )}
              </Col>
              <br />
              <Col md="6">
                <AdhTabSummary
                  isNewPatient={isNewPatient}
                  patientId={patientId}
                  adhSummary={tabAdhSummary}
                  handleAdherenceSummaryFilterChange={
                    handleAdherenceSummaryFilterChange
                  }
                  loadingAdhrence={loadingAdhrence}
                  range={range}
                  activeOption={activeOption}
                  setActiveOption={setActiveOption}
                />
              </Col>
              <br />
            </Row>
          ) : (
            <></>
          )}
          <Row>
            {!is1280Width ? (
              <Col sm="4">
                <br />
                {!isMobileWidth && (
                  <MedicationSelector
                    medSelectFilterOptions={medSelectFilterOptions}
                    handleMedSelectFilterChange={handleMedSelectFilterChange}
                    isAllMedications={isAllMedications}
                    handleSelectAllRadioChange={handleSelectAllRadioChange}
                    availableMedications={availableMedications}
                    handleMedClick={handleMedClick}
                    selectedMedicationIds={selectedMedicationIds}
                    loadingAdhrence={loadingAdhrence}
                    loadingMedications={loadingMedications}
                  />
                )}
                <br />

                <AdhTabSummary
                  isNewPatient={isNewPatient}
                  patientId={patientId}
                  adhSummary={tabAdhSummary}
                  handleAdherenceSummaryFilterChange={
                    handleAdherenceSummaryFilterChange
                  }
                  loadingAdhrence={loadingAdhrence}
                  range={range}
                  activeOption={activeOption}
                  setActiveOption={setActiveOption}
                />
              </Col>
            ) : (
              <></>
            )}
            <Calendar
              adhDispatch={adhDispatch}
              adhState={adhState}
              calendarRef={calendarRef}
              eventTypeHistory={eventTypeHistory}
              patientId={patientId}
              isMobileWidth={isMobileWidth}
              isNewPatient={isNewPatient}
              handleSelectAllRadioChange={handleSelectAllRadioChange}
              handleMedClick={handleMedClick}
            />
          </Row>

          {selectedEvent ? (
            <div>
              {selectedEvent.extendedProps.type === eventTypeHistory ? (
                <MedsHistoryEvent
                  adhState={adhState}
                  adhDispatch={adhDispatch}
                  handlePreviousClick={handlePreviousClick}
                  handleNextClick={handleNextClick}
                />
              ) : (
                <UserCreatedEvent
                  adhState={adhState}
                  adhDispatch={adhDispatch}
                />
              )}
            </div>
          ) : (
            <div></div>
          )}
        </TabPane>
        {tenantFeatures?.PayorPages && (
          <TabPane tabId="2">
            <Row>
              <Col xl="4" lg="4" md="12">
                <AdhClaimsTable />
              </Col>
              <Col xl="8" lg="8" md="12">
                <GanttChart />
              </Col>
            </Row>
          </TabPane>
        )}
      </TabContent>
    </div>
  );
};

TabAdherence.propTypes = {
  patientId: PropTypes.string,
  isNewPatient: PropTypes.bool,
  loadingMedications: PropTypes.bool
};

export { TabAdherence };
