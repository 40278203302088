export const sigCodes = [
  {
    "label": "Every Day",
    "options": [
      {
        "value": 10,
        "label": "Daily",
        "description": "Once a day",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 11,
        "label": "Daily WM",
        "description": "Once a day with meal",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 12,
        "label": "Daily AC",
        "description": "Once a day before meal",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 13,
        "label": "Daily PC",
        "description": "Once a day after meal",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 14,
        "label": "QAM",
        "description": "Once a day in the morning",
        "numberOfDoses": 1,
        "SuggestedTimes": [
          0
        ]
      },
      {
        "value": 15,
        "label": "QPM",
        "description": "Once a day in the evening",
        "numberOfDoses": 1,
        "SuggestedTimes": [
          2
        ]
      },
      {
        "value": 16,
        "label": "QHS",
        "description": "Once a day at bedtime",
        "numberOfDoses": 1,
        "SuggestedTimes": [
          4
        ]
      },
      {
        "value": 18,
        "label": "QAFT",
        "description": "Once a day in the afternoon",
        "numberOfDoses": 1,
        "SuggestedTimes": [
          1
        ]
      },
      {
        "value": 20,
        "label": "BID",
        "description": "Twice a day",
        "numberOfDoses": 2,
        "SuggestedTimes": []
      },
      {
        "value": 21,
        "label": "BIDWM",
        "description": "Twice a day with meal",
        "numberOfDoses": 2,
        "SuggestedTimes": []
      },
      {
        "value": 22,
        "label": "BIDAC",
        "description": "Twice a day before meal",
        "numberOfDoses": 2,
        "SuggestedTimes": []
      },
      {
        "value": 23,
        "label": "BIDPC",
        "description": "Twice a day after meal",
        "numberOfDoses": 2,
        "SuggestedTimes": []
      },
      {
        "value": 30,
        "label": "TID",
        "description": "Three times a day",
        "numberOfDoses": 3,
        "SuggestedTimes": []
      },
      {
        "value": 31,
        "label": "TIDWM",
        "description": "Three times a day with meal",
        "numberOfDoses": 3,
        "SuggestedTimes": []
      },
      {
        "value": 32,
        "label": "TIDAC",
        "description": "Three times a day before meal",
        "numberOfDoses": 3,
        "SuggestedTimes": []
      },
      {
        "value": 33,
        "label": "TIDPC",
        "description": "Three times a day after meal",
        "numberOfDoses": 3,
        "SuggestedTimes": []
      },
      {
        "value": 40,
        "label": "QID",
        "description": "Four times a day",
        "numberOfDoses": 4,
        "SuggestedTimes": []
      },
      {
        "value": 41,
        "label": "QIDPCHS",
        "description": "Four times a day (after meals and at bedtime)",
        "numberOfDoses": 4,
        "SuggestedTimes": []
      },
      {
        "value": 42,
        "label": "QIDACHS",
        "description": "Four times a day (before meals and at bedtime)",
        "numberOfDoses": 4,
        "SuggestedTimes": []
      },
      {
        "value": 43,
        "label": "QIDWMHS",
        "description": "Four times a day (with meals and at bedtime)",
        "numberOfDoses": 4,
        "SuggestedTimes": []
      },
      {
        "value": 50,
        "label": "5X/D",
        "description": "5 times a day",
        "numberOfDoses": 5,
        "SuggestedTimes": []
      },
      {
        "value": 51,
        "label": "6X/D",
        "description": "6 Times a Day",
        "numberOfDoses": 6,
        "SuggestedTimes": []
      },
      {
        "value": 52,
        "label": "7X/D",
        "description": "7 Times a Day",
        "numberOfDoses": 7,
        "SuggestedTimes": []
      },
      {
        "value": 53,
        "label": "8X/D",
        "description": "8 Times a Day",
        "numberOfDoses": 8,
        "SuggestedTimes": []
      },
      {
        "value": 54,
        "label": "9X/D",
        "description": "9 Times a Day",
        "numberOfDoses": 9,
        "SuggestedTimes": []
      },
      {
        "value": 55,
        "label": "10X/D",
        "description": "10 Times a Day",
        "numberOfDoses": 10,
        "SuggestedTimes": []
      },
      {
        "value": 56,
        "label": "11X/D",
        "description": "11 Times a Day",
        "numberOfDoses": 11,
        "SuggestedTimes": []
      },
      {
        "value": 57,
        "label": "12X/D",
        "description": "12 Times a Day",
        "numberOfDoses": 12,
        "SuggestedTimes": []
      },
      {
        "value": 58,
        "label": "14X/D",
        "description": "14 Times a Day",
        "numberOfDoses": 14,
        "SuggestedTimes": []
      },
      {
        "value": 59,
        "label": "16X/D",
        "description": "16 Times a Day",
        "numberOfDoses": 16,
        "SuggestedTimes": []
      },
      {
        "value": 60,
        "label": "20X/D",
        "description": "20 Times a Day",
        "numberOfDoses": 20,
        "SuggestedTimes": []
      }
    ]
  },
  {
    "label": "Day Intervals",
    "options": [
      {
        "value": 100,
        "label": "Every other day",
        "description": "Every other day",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 101,
        "label": "Every other day WM",
        "description": "Every other day with meal",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 102,
        "label": "Every other day AC",
        "description": "Every other day before meal",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 103,
        "label": "Every other day PC",
        "description": "Every other day after meal",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 104,
        "label": "Every other day HS",
        "description": "Every other day at bedtime",
        "numberOfDoses": 1,
        "SuggestedTimes": [
          4
        ]
      },
      {
        "value": 110,
        "label": "Q3D",
        "description": "Every 3 Days",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 111,
        "label": "Q4D",
        "description": "Every 4 Days",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 112,
        "label": "Q5D",
        "description": "Every 5 Days",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 113,
        "label": "Q6D",
        "description": "Every 6 Days",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 114,
        "label": "Q7D",
        "description": "Every 7 Days",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 115,
        "label": "Q8D",
        "description": "Every 8 Days",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 116,
        "label": "Q10D",
        "description": "Every 10 Days",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 117,
        "label": "Q14D",
        "description": "Every 14 Days",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 118,
        "label": "Q21D",
        "description": "Every 21 Days",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 119,
        "label": "Q28D",
        "description": "Every 28 Days",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 120,
        "label": "Q30D",
        "description": "Every 30 Days",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 600,
        "label": "2X/MO",
        "description": "Twice a month",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      }
    ]
  },
  {
    "label": "Specific Days",
    "options": [
      {
        "value": 200,
        "label": "Su",
        "description": "Sun",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 201,
        "label": "Mo",
        "description": "Mon",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 202,
        "label": "Tu",
        "description": "Tue",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 203,
        "label": "We",
        "description": "Wed",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 204,
        "label": "Th",
        "description": "Thu",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 205,
        "label": "Fr",
        "description": "Fri",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 206,
        "label": "Sa",
        "description": "Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 207,
        "label": "SuMo",
        "description": "Sun, Mon",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 208,
        "label": "SuTu",
        "description": "Sun, Tue",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 209,
        "label": "SuWe",
        "description": "Sun, Wed",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 210,
        "label": "SuTh",
        "description": "Sun, Thu",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 211,
        "label": "SuFr",
        "description": "Sun, Fri",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 212,
        "label": "SuSa",
        "description": "Sun, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 213,
        "label": "MoTu",
        "description": "Mon, Tue",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 214,
        "label": "MoWe",
        "description": "Mon, Wed",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 215,
        "label": "MoTh",
        "description": "Mon, Thu",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 216,
        "label": "MoFr",
        "description": "Mon, Fri",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 217,
        "label": "MoSa",
        "description": "Mon, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 218,
        "label": "TuWe",
        "description": "Tue, Wed",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 219,
        "label": "TuTh",
        "description": "Tue, Thu",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 220,
        "label": "TuFr",
        "description": "Tue, Fri",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 221,
        "label": "TuSa",
        "description": "Tue, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 222,
        "label": "WeTh",
        "description": "Wed, Thu",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 223,
        "label": "WeFr",
        "description": "Wed, Fri",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 224,
        "label": "WeSa",
        "description": "Wed, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 225,
        "label": "ThFr",
        "description": "Thu, Fri",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 226,
        "label": "ThSa",
        "description": "Thu, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 227,
        "label": "FrSa",
        "description": "Fri, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 228,
        "label": "SuMoTu",
        "description": "Sun, Mon, Tue",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 229,
        "label": "SuMoWe",
        "description": "Sun, Mon, Wed",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 230,
        "label": "SuMoTh",
        "description": "Sun, Mon, Thu",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 231,
        "label": "SuMoFr",
        "description": "Sun, Mon, Fri",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 232,
        "label": "SuMoSa",
        "description": "Sun, Mon, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 233,
        "label": "SuTuWe",
        "description": "Sun, Tue, Wed",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 234,
        "label": "SuTuTh",
        "description": "Sun, Tue, Thu",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 235,
        "label": "SuTuFr",
        "description": "Sun, Tue, Fri",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 236,
        "label": "SuTuSa",
        "description": "Sun, Tue, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 237,
        "label": "SuWeTh",
        "description": "Sun, Wed, Thu",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 238,
        "label": "SuWeFr",
        "description": "Sun, Wed, Fri",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 239,
        "label": "SuWeSa",
        "description": "Sun, Wed, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 240,
        "label": "SuThFr",
        "description": "Sun, Thu, Fri",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 241,
        "label": "SuThSa",
        "description": "Sun, Thu, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 242,
        "label": "SuFrSa",
        "description": "Sun, Fri, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 243,
        "label": "MoTuWe",
        "description": "Mon, Tue, Wed",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 244,
        "label": "MoTuTh",
        "description": "Mon, Tue, Thu",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 245,
        "label": "MoTuFr",
        "description": "Mon, Tue, Fri",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 246,
        "label": "MoTuSa",
        "description": "Mon, Tue, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 247,
        "label": "MoWeTh",
        "description": "Mon, Wed, Thu",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 248,
        "label": "QMWF",
        "description": "Mon, Wed, Fri",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 249,
        "label": "MoWeSa",
        "description": "Mon, Wed, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 250,
        "label": "MoThFr",
        "description": "Mon, Thu, Fri",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 251,
        "label": "MoThSa",
        "description": "Mon, Thu, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 252,
        "label": "MoFrSa",
        "description": "Mon, Fri, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 253,
        "label": "TuWeTh",
        "description": "Tue, Wed, Thu",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 254,
        "label": "TuWeFr",
        "description": "Tue, Wed, Fri",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 255,
        "label": "TuWeSa",
        "description": "Tue, Wed, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 256,
        "label": "TuThFr",
        "description": "Tue, Thu, Fri",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 257,
        "label": "TuThSa",
        "description": "Tue, Thu, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 258,
        "label": "TuFrSa",
        "description": "Tue, Fri, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 259,
        "label": "WeThFr",
        "description": "Wed, Thu, Fri",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 260,
        "label": "WeThSa",
        "description": "Wed, Thu, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 261,
        "label": "WeFrSa",
        "description": "Wed, Fri, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 262,
        "label": "ThFrSa",
        "description": "Thu, Fri, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 263,
        "label": "SuMoTuWe",
        "description": "Sun, Mon, Tue, Wed",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 264,
        "label": "SuMoTuTh",
        "description": "Sun, Mon, Tue, Thu",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 265,
        "label": "SuMoTuFr",
        "description": "Sun, Mon, Tue, Fri",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 266,
        "label": "SuMoTuSa",
        "description": "Sun, Mon, Tue, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 267,
        "label": "SuMoWeTh",
        "description": "Sun, Mon, Wed, Thu",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 268,
        "label": "SuMoWeFr",
        "description": "Sun, Mon, Wed, Fri",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 269,
        "label": "SuMoWeSa",
        "description": "Sun, Mon, Wed, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 270,
        "label": "SuMoThFr",
        "description": "Sun, Mon, Thu, Fri",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 271,
        "label": "SuMoThSa",
        "description": "Sun, Mon, Thu, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 272,
        "label": "SuMoFrSa",
        "description": "Sun, Mon, Fri, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 273,
        "label": "SuTuWeTh",
        "description": "Sun, Tue, Wed, Thu",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 274,
        "label": "SuTuWeFr",
        "description": "Sun, Tue, Wed, Fri",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 275,
        "label": "SuTuWeSa",
        "description": "Sun, Tue, Wed, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 276,
        "label": "SuTuThFr",
        "description": "Sun, Tue, Thu, Fri",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 277,
        "label": "SuTuThSa",
        "description": "Sun, Tue, Thu, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 278,
        "label": "SuTuFrSa",
        "description": "Sun, Tue, Fri, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 279,
        "label": "SuWeThFr",
        "description": "Sun, Wed, Thu, Fri",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 280,
        "label": "SuWeThSa",
        "description": "Sun, Wed, Thu, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 281,
        "label": "SuWeFrSa",
        "description": "Sun, Wed, Fri, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 282,
        "label": "SuThFrSa",
        "description": "Sun, Thu, Fri, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 283,
        "label": "MoTuWeTh",
        "description": "Mon, Tue, Wed, Thu",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 284,
        "label": "MoTuWeFr",
        "description": "Mon, Tue, Wed, Fri",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 285,
        "label": "MoTuWeSa",
        "description": "Mon, Tue, Wed, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 286,
        "label": "MoTuThFr",
        "description": "Mon, Tue, Thu, Fri",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 287,
        "label": "MoTuThSa",
        "description": "Mon, Tue, Thu, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 288,
        "label": "MoTuFrSa",
        "description": "Mon, Tue, Fri, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 289,
        "label": "MoWeThFr",
        "description": "Mon, Wed, Thu, Fri",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 290,
        "label": "MoWeThSa",
        "description": "Mon, Wed, Thu, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 291,
        "label": "MoWeFrSa",
        "description": "Mon, Wed, Fri, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 292,
        "label": "MoThFrSa",
        "description": "Mon, Thu, Fri, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 293,
        "label": "TuWeThFr",
        "description": "Tue, Wed, Thu, Fri",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 294,
        "label": "TuWeThSa",
        "description": "Tue, Wed, Thu, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 295,
        "label": "TuWeFrSa",
        "description": "Tue, Wed, Fri, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 296,
        "label": "TuThFrSa",
        "description": "Tue, Thu, Fri, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 297,
        "label": "WeThFrSa",
        "description": "Wed, Thu, Fri, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 298,
        "label": "SuMoTuWeTh",
        "description": "Sun, Mon, Tue, Wed, Thu",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 299,
        "label": "SuMoTuWeFr",
        "description": "Sun, Mon, Tue, Wed, Fri",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 300,
        "label": "SuMoTuWeSa",
        "description": "Sun, Mon, Tue, Wed, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 301,
        "label": "SuMoTuThFr",
        "description": "Sun, Mon, Tue, Thu, Fri",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 302,
        "label": "SuMoTuThSa",
        "description": "Sun, Mon, Tue, Thu, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 303,
        "label": "SuMoTuFrSa",
        "description": "Sun, Mon, Tue, Fri, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 304,
        "label": "SuMoWeThFr",
        "description": "Sun, Mon, Wed, Thu, Fri",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 305,
        "label": "SuMoWeThSa",
        "description": "Sun, Mon, Wed, Thu, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 306,
        "label": "SuMoWeFrSa",
        "description": "Sun, Mon, Wed, Fri, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 307,
        "label": "SuMoThFrSa",
        "description": "Sun, Mon, Thu, Fri, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 308,
        "label": "SuTuWeThFr",
        "description": "Sun, Tue, Wed, Thu, Fri",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 309,
        "label": "SuTuWeThSa",
        "description": "Sun, Tue, Wed, Thu, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 310,
        "label": "SuTuWeFrSa",
        "description": "Sun, Tue, Wed, Fri, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 311,
        "label": "SuTuThFrSa",
        "description": "Sun, Tue, Thu, Fri, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 312,
        "label": "SuWeThFrSa",
        "description": "Sun, Wed, Thu, Fri, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 313,
        "label": "QM_F",
        "description": "Mon, Tue, Wed, Thu, Fri",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 314,
        "label": "MoTuWeThSa",
        "description": "Mon, Tue, Wed, Thu, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 315,
        "label": "MoTuWeFrSa",
        "description": "Mon, Tue, Wed, Fri, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 316,
        "label": "MoTuThFrSa",
        "description": "Mon, Tue, Thu, Fri, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 317,
        "label": "MoWeThFrSa",
        "description": "Mon, Wed, Thu, Fri, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 318,
        "label": "TuWeThFrSa",
        "description": "Tue, Wed, Thu, Fri, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 319,
        "label": "SuMoTuWeThFr",
        "description": "Sun, Mon, Tue, Wed, Thu, Fri",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 320,
        "label": "SuMoTuWeThSa",
        "description": "Sun, Mon, Tue, Wed, Thu, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 321,
        "label": "SuMoTuWeFrSa",
        "description": "Sun, Mon, Tue, Wed, Fri, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 322,
        "label": "SuMoTuThFrSa",
        "description": "Sun, Mon, Tue, Thu, Fri, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 323,
        "label": "SuMoWeThFrSa",
        "description": "Sun, Mon, Wed, Thu, Fri, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 324,
        "label": "SuTuWeThFrSa",
        "description": "Sun, Tue, Wed, Thu, Fri, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 325,
        "label": "MoTuWeThFrSa",
        "description": "Mon, Tue, Wed, Thu, Fri, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 326,
        "label": "SuMoTuWeThFrSa",
        "description": "Sun, Mon, Tue, Wed, Thu, Fri, Sat",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 335,
        "label": "2X/W",
        "description": "Twice a Week",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 336,
        "label": "TIW",
        "description": "3 times a Week",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 337,
        "label": "BIDTIW",
        "description": "2 times a day (3 days a week)",
        "numberOfDoses": 2,
        "SuggestedTimes": [
          0,
          2
        ]
      },
      {
        "value": 340,
        "label": "4X/W",
        "description": "4 Times a Week",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 350,
        "label": "5X/W",
        "description": "5 Times a Week",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 360,
        "label": "6X/W",
        "description": "6 Times a Week",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      }
    ]
  },
  {
    "label": "Week Intervals",
    "options": [
      {
        "value": 401,
        "label": "QW",
        "description": "Every Week",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 402,
        "label": "Q2W",
        "description": "Every 2 Weeks",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 403,
        "label": "Q3W",
        "description": "Every 3 Weeks",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 404,
        "label": "Q4W",
        "description": "Every 4 Weeks",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 406,
        "label": "Q6W",
        "description": "Every 6 Weeks",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 408,
        "label": "Q8W",
        "description": "Every 8 Weeks",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 410,
        "label": "Q10W",
        "description": "Every 10 Weeks",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 412,
        "label": "Q12W",
        "description": "Every 12 Weeks",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 413,
        "label": "Q13W",
        "description": "Every 13 Weeks",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 414,
        "label": "Q14W",
        "description": "Every 14 Weeks",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 416,
        "label": "Q16W",
        "description": "Every 16 Weeks",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 424,
        "label": "Q24W",
        "description": "Every 24 Weeks",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      }
    ]
  },
  {
    "label": "Month Intervals",
    "options": [
      {
        "value": 601,
        "label": "QMO",
        "description": "Once a month",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 602,
        "label": "Q2MO",
        "description": "Every 2 months",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 603,
        "label": "Q3MO",
        "description": "Every 3 months",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 604,
        "label": "Q4MO",
        "description": "Every 4 months",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 606,
        "label": "Q6MO",
        "description": "Every 6 months",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      },
      {
        "value": 700,
        "label": "QY",
        "description": "Every Year",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      }
    ]
  },
  {
    "label": "Other",
    "options": [
      {
        "value": 1000,
        "label": "PRN",
        "description": "As Needed",
        "numberOfDoses": 0,
        "SuggestedTimes": []
      }
    ]
  },
  {
    "label": "Cycles",
    "options": [
      {
        "value": 1100,
        "label": "Cycle",
        "description": "Cycle",
        "numberOfDoses": 1,
        "SuggestedTimes": []
      }
    ]
  },
  {
    "label": "Hour Intervals",
    "options": [
      {
        "value": 801,
        "label": "Q1H",
        "description": "Every 1 Hour",
        "numberOfDoses": 0,
        "SuggestedTimes": []
      },
      {
        "value": 802,
        "label": "Q2H",
        "description": "Every 2 Hours",
        "numberOfDoses": 0,
        "SuggestedTimes": []
      },
      {
        "value": 803,
        "label": "Q3H",
        "description": "Every 3 Hours",
        "numberOfDoses": 0,
        "SuggestedTimes": []
      },
      {
        "value": 804,
        "label": "Q4H",
        "description": "Every 4 Hours",
        "numberOfDoses": 0,
        "SuggestedTimes": []
      },
      {
        "value": 805,
        "label": "Q5H",
        "description": "Every 5 Hours",
        "numberOfDoses": 0,
        "SuggestedTimes": []
      },
      {
        "value": 806,
        "label": "Q6H",
        "description": "Every 6 Hours",
        "numberOfDoses": 0,
        "SuggestedTimes": []
      },
      {
        "value": 808,
        "label": "Q8H",
        "description": "Every 8 Hours",
        "numberOfDoses": 0,
        "SuggestedTimes": []
      },
      {
        "value": 810,
        "label": "Q10H",
        "description": "Every 10 Hours",
        "numberOfDoses": 0,
        "SuggestedTimes": []
      },
      {
        "value": 812,
        "label": "Q12H",
        "description": "Every 12 Hours",
        "numberOfDoses": 0,
        "SuggestedTimes": []
      },
      {
        "value": 816,
        "label": "Q16H",
        "description": "Every 16 Hours",
        "numberOfDoses": 0,
        "SuggestedTimes": []
      },
      {
        "value": 818,
        "label": "Q18H",
        "description": "Every 18 Hours",
        "numberOfDoses": 0,
        "SuggestedTimes": []
      },
      {
        "value": 824,
        "label": "Q24H",
        "description": "Every 24 Hours",
        "numberOfDoses": 0,
        "SuggestedTimes": []
      },
      {
        "value": 836,
        "label": "Q36H",
        "description": "Every 36 Hours",
        "numberOfDoses": 0,
        "SuggestedTimes": []
      },
      {
        "value": 840,
        "label": "Q40H",
        "description": "Every 40 Hours",
        "numberOfDoses": 0,
        "SuggestedTimes": []
      },
      {
        "value": 848,
        "label": "Q48H",
        "description": "Every 48 Hours",
        "numberOfDoses": 0,
        "SuggestedTimes": []
      },
      {
        "value": 860,
        "label": "Q60H",
        "description": "Every 60 Hours",
        "numberOfDoses": 0,
        "SuggestedTimes": []
      },
      {
        "value": 872,
        "label": "Q72H",
        "description": "Every 72 Hours",
        "numberOfDoses": 0,
        "SuggestedTimes": []
      },
      {
        "value": 904,
        "label": "Q4HWA",
        "description": "Every 4 Hours when awake",
        "numberOfDoses": 0,
        "SuggestedTimes": []
      },
      {
        "value": 906,
        "label": "Q6HWA",
        "description": "Every 6 Hours when awake",
        "numberOfDoses": 0,
        "SuggestedTimes": []
      },
      {
        "value": 908,
        "label": "Q8HWA",
        "description": "Every 8 Hours when awake",
        "numberOfDoses": 0,
        "SuggestedTimes": []
      }
    ]
  }
]