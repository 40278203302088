import React, { useEffect, useState } from "react";
import { therapeuticCategories } from "../_helpers/therapeuticCategories";
import {
  Badge,
  Collapse,
  CustomInput,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from "reactstrap";
import classNames from "classnames";
import { ReactComponent as ChevronDown } from "../images/chevron_down_small.svg";
import { ReactComponent as CloseIcon } from "../images/glyph_x.svg";
import { isIE } from "../_helpers";

const extractOptions = (data) => {
  return Object.entries(data).reduce((acc, [key, value]) => {
    if (Array.isArray(value)) {
      return [...acc, key, ...value];
    }
    return [...acc, ...extractOptions(value)];
  }, []);
};
const filterData = (data, searchTerm, setOpenCollapses) => {
  const lowercasedSearchTerm = searchTerm.toLowerCase();
  // filter also needs to open the parent if the child is found

  return Object.entries(data).reduce((acc, [key, value]) => {
    if (key.toLowerCase().includes(lowercasedSearchTerm)) {
      acc[key] = value;
    } else if (Array.isArray(value)) {
      const filteredArray = value.filter((item) =>
        item.toLowerCase().includes(lowercasedSearchTerm)
      );
      if (filteredArray.length > 0) {
        acc[key] = filteredArray;
        setOpenCollapses((prevState) => [...prevState, key]);
      }
    } else {
      const nestedResult = filterData(value, searchTerm, setOpenCollapses);
      if (Object.keys(nestedResult).length > 0) {
        acc[key] = nestedResult;
        setOpenCollapses((prevState) => [...prevState, key]);
      }
    }
    return acc;
  }, {});
};

function TherapeuticCategories({
  options,
  setCheckedData,
  checkedData,
  name,
  onBlur,
  openCollapses,
  setOpenCollapses
}) {
  const handleCheckChange = (event, item, nestedData = null) => {
    const isChecked = event.target.checked;
    const allOptions = nestedData
      ? Array.isArray(nestedData)
        ? nestedData
        : extractOptions(nestedData)
      : [];

    setCheckedData((prevState) =>
      isChecked
        ? [...prevState, item, ...allOptions]
        : prevState.filter((rItem) => ![item, ...allOptions].includes(rItem))
    );
    // setInputVal("");
    // setFilteredOptions(therapeuticCategories);
    if (document.getElementById(`${name}-input`))
      document.getElementById(`${name}-input`).focus();
    onBlur(name, true);
  };
  //   when checkedData changes, call the onChange function

  return (
    <ParentCheck
      data={options}
      onCheck={handleCheckChange}
      checkedData={checkedData}
      setOpenCollapses={setOpenCollapses}
      openCollapses={openCollapses}
    />
  );
}

const DropdownCheckbox = ({ item, value, checkedData, onCheck }) => {
  return (
    <h6>
      <CustomInput
        type="checkbox"
        className="dark-checkmark"
        checked={checkedData.includes(item)}
        onChange={(event) => onCheck(event, item, value)}
        label={item}
        id={item}
      />{" "}
    </h6>
  );
};

const CollapseItem = ({
  item,
  value,
  onCheck,
  checkedData,
  setOpenCollapses,
  openCollapses
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleCollapse = (item) => {
    // setIsOpen(!isOpen);
    if (isOpen) {
      setOpenCollapses((prevState) =>
        prevState.filter((collapseItem) => collapseItem !== item)
      );
    } else {
      setOpenCollapses((prevState) => [...prevState, item]);
    }
  };

  // if the item is in the openCollapses array, set isOpen to true
  useEffect(() => {
    if (openCollapses?.includes(item)) {
      setIsOpen(true);
    } else if (!openCollapses?.includes(item)) {
      setIsOpen(false);
    }
  }, [item, openCollapses]);

  return (
    <React.Fragment key={item}>
      <DropdownItem tag="div" className="px-3 py-1 text-wrap d-flex">
        <DropdownCheckbox
          item={item}
          value={value}
          checkedData={checkedData}
          onCheck={onCheck}
        />
        <ChevronDown
          className="glyph-dark ml-auto mt-2"
          aria-label="chevron down"
          onClick={() => toggleCollapse(item)}
        />
      </DropdownItem>

      <Collapse className="ml-4" isOpen={isOpen}>
        <ChildCheck
          data={value}
          onCheck={onCheck}
          checkedData={checkedData}
          openCollapses={openCollapses}
          setOpenCollapses={setOpenCollapses}
        />
      </Collapse>
    </React.Fragment>
  );
};

const ParentCheck = ({
  data,
  onCheck,
  checkedData,
  setOpenCollapses,
  openCollapses
}) => {
  return (
    <>
      {Object.entries(data).map(([key, value]) => {
        return (
          <React.Fragment key={key}>
            {value.length > 0 ||
            // value has keys and values
            (typeof value === "object" && Object.keys(value).length > 0) ? (
              <CollapseItem
                item={key}
                value={value}
                onCheck={onCheck}
                checkedData={checkedData}
                setOpenCollapses={setOpenCollapses}
                openCollapses={openCollapses}
              />
            ) : (
              <DropdownItem tag="div" className="px-3 py-1 text-wrap">
                <DropdownCheckbox
                  item={key}
                  value={value}
                  checkedData={checkedData}
                  onCheck={onCheck}
                />
              </DropdownItem>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

const ChildCheck = ({
  data,
  onCheck,
  checkedData,
  openCollapses,
  setOpenCollapses
}) => {
  if (!Array.isArray(data)) {
    return (
      <ParentCheck
        data={data}
        onCheck={onCheck}
        checkedData={checkedData}
        setOpenCollapses={setOpenCollapses}
        openCollapses={openCollapses}
      />
    );
  }

  return (
    <>
      {data.map((item) => (
        <DropdownItem tag="div" key={item} className="px-3 py-1 text-wrap">
          <DropdownCheckbox
            item={item}
            checkedData={checkedData}
            onCheck={onCheck}
          />
        </DropdownItem>
      ))}
    </>
  );
};

const TherapeuticCategoriesDropdown = ({
  placeholder,
  value,
  onChange,
  name,
  onBlur,
  options,
  error
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputVal, setInputVal] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [checkedData, setCheckedData] = React.useState(value);
  const [openCollapses, setOpenCollapses] = useState([]);
  let wordLength = 20;

  const toggleDropdown = () => setIsOpen(!isOpen);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        document.getElementById("tcDropdown") &&
        !document.getElementById("tcDropdown").contains(event.target) &&
        document.getElementById("tcToggle") &&
        !document.getElementById("tcToggle").contains(event.target)
      ) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleInputClick = (e) => {
    e.stopPropagation();
    if (!isOpen) {
      setIsOpen(true);
    }
  };

  const handleInputChange = (e) => {
    if (!isOpen) {
      setIsOpen(true);
    }
    setInputVal(e.target.value);
    setOpenCollapses([]);
    // filter the options based on the input value
    setFilteredOptions(
      filterData(therapeuticCategories, e.target.value, setOpenCollapses)
    );
  };

  useEffect(() => {
    onChange(name, checkedData);
  }, [checkedData, name, onChange]);

  return (
    <div className={classNames("position-relative")}>
      <Dropdown isOpen={isOpen} toggle={() => {}}>
        <DropdownToggle
          tag="span"
          data-toggle="dropdown"
          aria-expanded={isOpen}
          tabIndex={0}
          className={classNames(
            "d-flex align-items-center expandable-dropdown custom-dropdown"
          )}
          onClick={() => {
            toggleDropdown();
            // focus on input
            if (document.getElementById(`${name}-input`))
              document.getElementById(`${name}-input`).focus();
            // set the input value to "" when dropdown is opened
          }}
          id="tcToggle"
        >
          <div className="d-flex flex-wrap overflow-hidden">
            {value?.length > 0 &&
              Array.isArray(value) &&
              value.map((item) => (
                <Badge
                  color="badge-gray"
                  className="mr-1 font-weight-normal p-2 font-size-85 mb-1"
                  key={`${item}-badge`}
                >
                  {item.length > wordLength
                    ? item.slice(0, wordLength) + "..."
                    : item}
                  {/* add x button that unchecks item on click */}
                  <CloseIcon
                    className="glyph-dark h8-w8-style ml-2 close-icon"
                    onClick={(e) => {
                      e.stopPropagation();

                      setCheckedData((prevState) =>
                        prevState.filter((rItem) => rItem !== item)
                      );
                    }}
                  />
                </Badge>
              ))}{" "}
            <input
              type="text"
              placeholder={value?.length ? "" : placeholder}
              onChange={handleInputChange}
              value={inputVal}
              className={classNames("overflow-hidden mb-1")}
              id={`${name}-input`}
              data-testid={`${name}-input`}
              onClick={handleInputClick}
              // disabled={disabled}
            />
          </div>
          <div className={classNames({ "d-flex": !isIE }, "ml-auto mb-1")}>
            {value?.length > 0 && (
              <CloseIcon
                className="glyph-gray mr-4 h12-w12-style close-icon"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsOpen(false);
                  setCheckedData([]);
                }}
              />
            )}

            <ChevronDown
              className="glyph-dark mr-2 h12-w12-style"
              aria-label="chevron down"
            />
          </div>
        </DropdownToggle>
        <DropdownMenu
          className="therapeuticClass scrollable-dropdown-menu pb-3"
          id="tcDropdown"
        >
          <TherapeuticCategories
            onChange={onChange}
            onBlur={onBlur}
            name={name}
            options={filteredOptions}
            setCheckedData={setCheckedData}
            checkedData={checkedData}
            setInputVal={setInputVal}
            setFilteredOptions={setFilteredOptions}
            openCollapses={openCollapses}
            setOpenCollapses={setOpenCollapses}
          />
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default TherapeuticCategoriesDropdown;
