import React, { useEffect, useContext, useState, useRef } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { accountService, patientInviteService } from "../_services";
import { useUserContext } from "../context/userContext";
import { SmartContext } from "../context/smartContext";
import { AcceptTerms } from "./AcceptTerms";
import { smartService } from "../_services/smart.service";

const Smart = () => {
  const {
    patientId,
    patientInviteStatus,
    mPageType,
    setPatientId,
    setPatientInviteStatus,
    setMPageType,
    setIsMPages,
    isMPages,
    setPatientName
  } = useContext(SmartContext);
  const { updateUser, updatePermissions, updateTenantFeatures } =
    useUserContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [showTermsPage, setShowTermsPage] = useState(false);
  const [success, setSuccess] = useState(false);
  const [noPatientId, setNoPatientId] = useState(false);
  const [noInviteStatus, setNoInviteStatus] = useState(false);

  function isMPage() {
    return window.self != window.top;
  }

  const navigateRef = useRef();

  useEffect(() => {
    navigateRef.current = navigate;
  }, [navigate]);

  useEffect(() => {
    setSuccess(false);
    setShowTermsPage(false);
    let mounted = true;
    localStorage.removeItem("isMPages");
    sessionStorage.removeItem("patientId");
    sessionStorage.removeItem("invitationStatus");
    smartService
      .smartAuthorize(location.search, isMPage())
      .then((response) => {
        if (mounted) {
          if (response.showTermsPage) {
            setShowTermsPage(true);
          }
          if (response.mPageType === 1 || response.mPageType === 2) {
            window.CernerSmartEmbeddableLib.calcFrameHeight = function () {
              return 500;
            };
          }
          if (response.mPageType === 0) {
            window.CernerSmartEmbeddableLib.calcFrameHeight = function () {
              return 400;
            };
          }
          if (
            response.patientInvitationStatus ||
            response.patientInvitationStatus === 0
          ) {
            let status;
            if (
              response.patientInvitationStatus === 0 ||
              response.patientInvitationStatus >= 6
            ) {
              status = 0;
            } else {
              status = response.patientInvitationStatus;
            }
            setPatientInviteStatus(status);

            sessionStorage.setItem("invitationStatus", JSON.stringify(status));
          } else setNoInviteStatus(true);

          if (response.patientId) {
            setPatientId(response.patientId);

            sessionStorage.setItem(
              "patientId",
              JSON.stringify(response.patientId)
            );
          } else setNoPatientId(true);

          if (response.patientName) {
            setPatientName(response.patientName);

            sessionStorage.setItem(
              "patientName",
              JSON.stringify(response.patientName)
            );
          }
          if (response.mPageType !== 1 && isMPage()) {
            setIsMPages(true);
            localStorage.setItem("isMPage", JSON.stringify(true));
          } else {
            setIsMPages(false);
            localStorage.setItem("isMPage", JSON.stringify(false));
          }
          setMPageType(response.mPageType);
          setSuccess(true);
        }
      })
      .catch((e) => {
        if (e.error === "User is blocked") {
          sessionStorage.setItem(
            "user",
            JSON.stringify({ isSmartSession: true })
          );
          navigateRef.current("/smart/401");
        } else if (e.email && e.tenant) {
          navigateRef.current("/smart/request-access", { state: e });
        } else if (e.goLiveDate) {
          navigateRef.current("/smart/go-live");
        } else {
          navigateRef.current("/smart/403");
        }
      });
    return function cleanup() {
      mounted = false;
    };
  }, [
    location.search,
    setIsMPages,
    setMPageType,
    setPatientId,
    setPatientInviteStatus,
    setPatientName
  ]);

  useEffect(() => {
    if (
      patientId &&
      (patientInviteStatus === 1 ||
        patientInviteStatus === 2 ||
        patientInviteStatus === 3)
    ) {
      patientInviteService.smartSyncMed(patientId).catch((e) => {
        navigate("/smart/403");
      });
    }
  }, [navigate, patientId, patientInviteStatus]);

  useEffect(() => {
    if (success)
      accountService
        .getProfile()
        .then((user) => {
          const sessionUser = { ...user, isSmartSession: true };
          updateUser(sessionUser);
          sessionStorage.setItem(
            "user",
            JSON.stringify({ name: user.name, isSmartSession: true })
          );
        })
        .catch((e) => {
          console.log(e);
        });
  }, [navigate, success, updateUser]);

  useEffect(() => {
    if (success) {
      accountService
        .getPermissions()
        .then((permissions) => {
          updatePermissions(permissions);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [success, updatePermissions]);

  useEffect(() => {
    if (success) {
      accountService
        .getTenantFeatures()
        .then((tenantFeatures) => {
          updateTenantFeatures(tenantFeatures);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [success, updateTenantFeatures]);

  return (
    <div>
      {!showTermsPage && !success && (
        <div className="d-flex justify-content-center align-items-center h-80vh">
          <span className="spinner-border mr-1"></span>
        </div>
      )}
      {showTermsPage && <AcceptTerms setShowTermsPage={setShowTermsPage} />}
      {success && !showTermsPage && (
        <>
          {isMPages && mPageType === 0 && (
            <Navigate to={`/smart/403`} state={{ isMpage: true }} />
          )}
          {patientId && (
            <>
              {isMPages && patientInviteStatus === 2 && (
                <Navigate to={`/smart/workflow/${patientId}`} />
              )}
              {isMPages && patientInviteStatus !== 2 && (
                <Navigate
                  to={`/smart/mpages/${patientId}/${patientInviteStatus}`}
                />
              )}
              {(patientInviteStatus === 0 || patientInviteStatus >= 4) &&
                !isMPages && <Navigate to={`/smart/invite/${patientId}`} />}
              {(patientInviteStatus === 1 || patientInviteStatus === 3) &&
                !isMPages && (
                  <Navigate
                    to={`/smart/manage-invitations/manage/${patientId}`}
                  />
                )}

              {patientInviteStatus === 2 && !isMPages && (
                <Navigate to={`/smart/patients/${patientId}`} />
              )}
            </>
          )}
          {noPatientId && noInviteStatus && !isMPages && (
            <Navigate to={`/smart/admin/groups`} />
          )}
        </>
      )}
    </div>
  );
};

export { Smart };
