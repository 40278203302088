import { fetchWrapper } from "../_helpers/index";

export const riskprofileService = {
  getAllRiskProfiles,
  editRiskProfile,
  deleteRiskProfile,
  createRiskProfile
};

const baseUrl = "/api/admin/riskprofiles";

function getAllRiskProfiles() {
  return fetchWrapper.get(baseUrl);
}

function editRiskProfile(id, body) {
  return fetchWrapper.put(`${baseUrl}/${id}`, body);
}

function deleteRiskProfile(id) {
  return fetchWrapper.delete(`${baseUrl}/${id}`);
}

function createRiskProfile(fields) {
  return fetchWrapper.post(`${baseUrl}/create`, fields);
}
