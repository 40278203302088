function isIE() {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf("MSIE ");
  const trident = ua.indexOf("Trident/");
  const edge = ua.indexOf("Edge/");
  return msie > 0 || trident > 0 || edge > 0;
}
function getColor({ isDisabled, isFocused, isSelected }) {
  let color;
  if (isDisabled) {
    color = "#ccc";
  } else if (isSelected) {
    color = "var(--primary)";
  } else if (isFocused) {
    color = "var(--secondary)";
  } else {
    color = "#1f2f36";
  }
  return color;
}

function getColorDatePicker({ isDisabled, isFocused, isSelected }) {
  let color;
  if (isDisabled) {
    color = "#ccc";
  } else if (isSelected || isFocused) {
    color = "var(--primary)";
  } else {
    color = "#1f2f36";
  }
  return color;
}

function getColorStyles({ state }) {
  let color;
  if (state.isDisabled) {
    color = "#747474";
  } else if (state.isFocused) {
    color = "var(--extra-light-primary)";
  } else {
    color = "#1f2f36";
  }
  return color;
}

function getLightColorStyles({ state }) {
  let color;
  if (state.isDisabled) {
    color = "#747474";
  } else if (state.isFocused) {
    color = "var(--extra-light-primary)";
  } else {
    color = "#1f2f36";
  }
  return color;
}

function getReadOnlyColorStyles({ data, isDisabled, isFocused, isSelected }) {
  let color;
  if (data.__isNew__) {
    color = "var(--light-primary)";
  } else if (isDisabled) {
    color = "#dad6d6";
  } else if (isSelected || isFocused) {
    color = "var(--primary)";
  } else {
    color = "#1f2f36";
  }
  return color;
}

function getGroupedColorStyles({ state }) {
  let color;
  if (state.isDisabled) {
    color = "#747474";
  } else if (state.isFocused) {
    color = "var(--extra-light-primary)";
  } else {
    color = "#1f2f36";
  }
  return color;
}

function getFormInTableColor({ isDisabled, isFocused, isSelected }) {
  let color;
  if (isDisabled) {
    color = "#dad6d6";
  } else if (isSelected || isFocused) {
    color = "var(--primary)";
  } else {
    color = "#1f2f36";
  }
  return color;
}

function getMobileFilterColor({ isDisabled, isSelected, isFocused }) {
  let color;
  if (isDisabled) {
    color = "#ccc";
  } else if (isSelected) {
    color = "var(--light-primary)";
  } else if (isFocused) {
    color = "var(--primary)";
  } else {
    color = "#1f2f36";
  }
  return color;
}

const dot = (color = "transparent") => ({
  alignItems: "center",
  display: "flex",

  ":before": {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: "block",
    marginRight: 8,
    height: 10,
    width: 10
  }
});

export const colorfulColorStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = data.color;
    return {
      ...styles,
      ...dot(color),

      color: getReadOnlyColorStyles({
        data,
        isDisabled,
        isFocused,
        isSelected
      }),
      backgroundColor: "white",
      cursor: isDisabled ? "not-allowed" : "pointer",
      ":active": {
        ...styles[":active"],
        backgroundColor: "white"
      }
    };
  },
  input: (styles) => ({ ...styles, ...dot() }),
  placeholder: (styles) => ({ ...styles, ...dot("#ccc") }),
  singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none"
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  dropdownIndicator: (styles, state) => ({
    ...styles,
    color: getColorStyles({ state }),
    "&:hover": { color: "#747474" }
  }),
  clearIndicator: (styles, state) => ({
    ...styles,
    color: "#747474",
    "&:hover": { color: "#d73838" }
  }),

  control: (styles, state) => ({
    ...styles,
    backgroundColor: state.isDisabled ? "#dad6d6" : "#f2f2f2",
    borderColor: state.isFocused ? "var(--extra-light-primary)" : "#dad6d6",
    borderWidth: "2px",
    "&:hover": { borderColor: "var(--extra-light-primary)" },
    cursor: "pointer",
    boxShadow: "none",
    minWidth: "7rem"
  }),
  menu: (styles) => ({
    ...styles,
    border: "none",
    marginTop: "0",
    boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.16)",
    zIndex: "1060",
    marginBottom: "1rem"
  }),
  menuList: (styles) => ({
    ...styles,
    maxHeight: "160px",
    paddingTop: "8px",
    paddingBottom: "8px"
  }),
  groupHeading: (styles) => ({
    ...styles,
    color: "#1f2f36",
    textTransfom: "unset",
    fontSize: "16px"
    // fontSize: "14px"
  }),
  noOptionsMessage: (styles) => ({
    ...styles,
    textAlign: "left",
    color: "#747474",
    padding: "16px 16px",
    fontSize: "16px"
  }),

  loadingMessage: (styles) => ({
    ...styles,
    // padding: "20px 16px",
    // margin: "10px",
    color: "transparent",
    backgroundSize: "auto",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center"
  }),

  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: "#1f2f36",
    ":hover": {
      backgroundColor: "transparent",
      color: "#d73838"
    }
  }),

  valueContainer: (styles, { data }) => ({
    ...styles,
    fontSize: "16px"
  })
};

export const filterColourStyles = {
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none"
  }),
  dropdownIndicator: (styles, state) => ({
    ...styles,
    color: state.isFocused ? "var(--extra-light-primary)" : "#747474"
  }),
  control: (styles, state) => ({
    ...styles,
    backgroundColor: "transparent",
    borderColor: state.isFocused ? "var(--extra-light-primary)" : "#dad6d6",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderRadius: "0px",
    borderWidth: "2px",
    "&:hover": { color: "#dad6d6" },
    cursor: "pointer",
    boxShadow: "none",
    color: "#747474",
    minWidth: "13rem"
  }),
  menu: (styles) => ({
    ...styles,
    border: "none",
    marginTop: "0",
    boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.16)",
    zIndex: 3
  }),
  menuList: (styles) => ({
    ...styles,
    overflowX: "hidden"
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: "white",
      color: getColor({ isDisabled, isFocused, isSelected }),
      // fontSize: "14px",
      "&:hover": { color: "#747474" },
      cursor: isDisabled ? "not-allowed" : "pointer",

      ":active": {
        ...styles[":active"],
        backgroundColor: "white"
      },
      textAlign: "left"
    };
  },
  input: (styles) => ({ ...styles }),
  placeholder: (styles) => ({ ...styles }),
  singleValue: (styles, { data }) => ({
    ...styles,
    color: "#747474"
  }),
  valueContainer: (styles, { data }) => ({
    ...styles,
    padding: "2px 0",
    flexWrap: "nowrap"
  }),
  indicatorsContainer: (styles, { data }) => ({
    ...styles,
    marginRight: "-8px"
  }),
  multiValue: (styles, { data }) => ({
    ...styles
  })
};
export const btnLinkColourStyles = {
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none"
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  dropdownIndicator: (styles, state) => ({
    ...styles,
    display: "none"
  }),
  control: (styles, state) => ({
    ...styles,
    backgroundColor: "transparent",
    borderColor: state.isFocused ? "var(--extra-light-primary)" : "#dad6d6",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderRadius: "0px",
    borderBottom: "none",
    "&:hover": { color: "#747474" },
    cursor: "pointer",
    boxShadow: "none",
    color: isIE() ? "#0078c8" : getColor({ state }),
    minWidth: "5.5rem"
  }),
  menu: (styles) => ({
    ...styles,
    border: "none",
    marginTop: "0",
    boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.16)",
    zIndex: 3
  }),
  menuList: (styles) => ({
    ...styles,
    overflowX: "hidden"
  }),

  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: "white",
      color: getColor({ isDisabled, isFocused, isSelected }),
      // fontSize: "14px",
      "&:hover": { color: "#747474" },
      cursor: isDisabled ? "not-allowed" : "pointer",

      ":active": {
        ...styles[":active"],
        backgroundColor: "white"
      },
      textAlign: "left"
    };
  },
  input: (styles) => ({ ...styles }),
  placeholder: (styles) => ({ ...styles, color: "var(--primary)" }),
  singleValue: (styles, { data }) => ({
    ...styles,
    color: "var(--primary)"
  }),
  valueContainer: (styles, { data }) => ({
    ...styles,
    padding: "2px 0",
    flexWrap: "nowrap"
  }),
  indicatorsContainer: (styles, { data }) => ({
    ...styles,
    marginRight: "-8px"
  }),
  multiValue: (styles, { data }) => ({
    ...styles
  })
};
export const smallBtnStyles = {
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none"
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  dropdownIndicator: (styles, state) => ({
    ...styles,
    display: "none"
  }),
  control: (styles, state) => ({
    ...styles,
    backgroundColor: "transparent",
    borderColor: state.isFocused ? "var(--extra-light-primary)" : "#dad6d6",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderRadius: "0px",
    borderBottom: "none",
    "&:hover": { color: "#dad6d6" },
    cursor: "pointer",
    boxShadow: "none",
    color: isIE() ? "#0078c8" : "var(--primary)",
    minWidth: "4.5rem"
  }),
  menu: (styles) => ({
    ...styles,
    border: "none",
    marginTop: "0",
    boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.16)",
    zIndex: 3
  }),
  menuList: (styles) => ({
    ...styles,
    overflowX: "hidden"
  }),

  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: "white",
      color: getColor({ isDisabled, isFocused, isSelected }),
      // fontSize: "14px",
      "&:hover": { color: "#747474" },
      cursor: isDisabled ? "not-allowed" : "pointer",

      ":active": {
        ...styles[":active"],
        backgroundColor: "white"
      },
      textAlign: "left"
    };
  },
  input: (styles) => ({ ...styles }),
  placeholder: (styles) => ({ ...styles, color: "var(--primary)" }),
  singleValue: (styles, { data }) => ({
    ...styles,
    color: "var(--primary)"
  }),
  valueContainer: (styles, { data }) => ({
    ...styles,
    padding: "2px 0",
    flexWrap: "nowrap"
  }),
  indicatorsContainer: (styles, { data }) => ({
    ...styles,
    marginRight: "-8px"
  }),
  multiValue: (styles, { data }) => ({
    ...styles
  })
};
export const datePickerColourStyles = {
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none"
  }),
  dropdownIndicator: (styles, state) => ({
    ...styles,
    color: "#1f2f36",
    "&:hover": { color: "#8c8c8c" }
  }),

  control: (styles, state) => ({
    ...styles,
    backgroundColor: "#ffffff",
    borderColor: state.isFocused ? "var(--extra-light-primary)" : "#dad6d6",
    borderWidth: "2px",
    "&:hover": { color: "#dad6d6" },
    cursor: "pointer",
    boxShadow: "none",
    minWidth: "6.3rem"
  }),
  menu: (styles) => ({
    ...styles,
    border: "none",
    marginTop: "0",
    boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.16)",
    zIndex: "10",
    textAlign: "left",
    fontSize: "12px"
  }),

  menuList: (styles) => ({
    ...styles,
    maxHeight: "200px"
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: "white",
      color: getColorDatePicker({ isDisabled, isFocused, isSelected }),
      "&:hover": { color: "var(--primary)" },
      cursor: isDisabled ? "not-allowed" : "pointer",

      ":active": {
        ...styles[":active"]
      }
    };
  },
  input: (styles) => ({ ...styles }),
  placeholder: (styles) => ({ ...styles }),
  singleValue: (styles, { data }) => ({ ...styles }),
  valueContainer: (styles, { data }) => ({
    ...styles,
    fontSize: "12px"
  })
};

export const colourStyles = {
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none"
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  dropdownIndicator: (styles, state) => ({
    ...styles,
    color: getColorStyles({ state }),
    "&:hover": { color: "#747474" }
  }),
  clearIndicator: (styles, state) => ({
    ...styles,
    color: "#747474",
    "&:hover": { color: "#d73838" }
  }),

  control: (styles, state) => ({
    ...styles,
    backgroundColor: state.isDisabled ? "#dad6d6" : "#f2f2f2",
    borderColor: state.isFocused ? "var(--extra-light-primary)" : "#dad6d6",
    borderWidth: "2px",
    "&:hover": { borderColor: "var(--extra-light-primary)" },
    cursor: "pointer",
    boxShadow: "none",
    minWidth: "7rem"
  }),
  menu: (styles) => ({
    ...styles,
    border: "none",
    marginTop: "0",
    boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.16)",
    zIndex: "1060",
    marginBottom: "1rem"
  }),
  menuList: (styles) => ({
    ...styles,
    maxHeight: "160px",
    paddingTop: "8px",
    paddingBottom: "8px"
  }),
  groupHeading: (styles) => ({
    ...styles,
    color: "#1f2f36",
    textTransfom: "unset",
    fontSize: "16px"
    // fontSize: "14px"
  }),
  noOptionsMessage: (styles) => ({
    ...styles,
    textAlign: "left",
    color: "#747474",
    padding: "16px 16px",
    fontSize: "16px"
  }),
  input: (styles, state) => ({
    ...styles,
    color: state.isDisabled ? "#dad6d6" : "#747474",
    visibility: state.isDisabled ? "hidden" : "visible"
  }),
  loadingMessage: (styles) => ({
    ...styles,
    // padding: "20px 16px",
    // margin: "10px",
    color: "transparent",
    backgroundSize: "auto",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center"
  }),

  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: "white",
      whiteSpace: "pre-wrap",
      "&:hover": { color: isDisabled ? "#dad6d6" : "var(--primary)" },
      cursor: isDisabled ? "not-allowed" : "pointer",

      ":active": {
        ...styles[":active"],
        backgroundColor: "white"
      },
      "&:before": {
        content:
          data.__isNew__ &&
          '"No results? Continue typing or add a custom med below"',
        whiteSpace: data.__isNew__ && "pre",
        color: "#747474",
        borderBottom: "2px solid #edeeef",
        display: data.__isNew__ && "inline-block",
        width: "100%",
        marginBottom: data.__isNew__ && "1rem",
        paddingBottom: data.__isNew__ && "1rem"
      },
      padding: data.__isNew__ ? "16px" : "8px 12px",

      color: getReadOnlyColorStyles({
        data,
        isDisabled,
        isFocused,
        isSelected
      })
    };
  },
  placeholder: (styles, state) => ({
    ...styles,
    color: "#747474",
    fontSize: "16px",
    fontWeight: "400"
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: "#1f2f36",
    ":hover": {
      backgroundColor: "transparent",
      color: "#d73838"
    }
  }),

  singleValue: (styles, { data }) => ({ ...styles }),
  valueContainer: (styles, { data }) => ({
    ...styles,
    fontSize: "16px"
  })
};

export const readOnlyColourStyles = {
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none"
  }),
  dropdownIndicator: (styles, state) => ({
    ...styles,
    display: "none"
  }),
  clearIndicator: (styles, state) => ({
    ...styles,
    color: "#747474",
    "&:hover": { color: "#d73838" }
  }),
  noOptionsMessage: (styles) => ({
    ...styles,
    textAlign: "left",
    color: "#747474",
    padding: "16px 16px",
    fontSize: "16px"
  }),
  control: (styles, state) => ({
    ...styles,
    backgroundColor: "#f2f2f2",

    borderColor: state.isFocused ? "var(--extra-light-primary)" : "#dad6d6",
    borderWidth: "2px",
    "&:hover": { borderColor: "var(--extra-light-primary)" },
    cursor: "pointer",
    boxShadow: "none"
  }),
  menu: (styles) => ({
    ...styles,
    border: "none",
    marginTop: "0",
    boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.16)",
    zIndex: "1060",
    marginBottom: "1rem"
  }),
  menuList: (styles) => ({
    ...styles,
    maxHeight: "160px",
    paddingTop: "8px",
    paddingBottom: "8px"
  }),
  groupHeading: (styles) => ({
    ...styles,
    color: "#1f2f36",
    textTransfom: "unset",
    fontSize: "16px"
    // fontSize: "14px"
  }),

  input: (styles, state) => ({
    ...styles,
    color: state.isDisabled ? "#dad6d6" : "#747474",
    visibility: state.isDisabled ? "hidden" : "visible"
  }),
  loadingMessage: (styles) => ({
    ...styles,
    padding: "20px 16px",
    margin: "10px",
    color: "transparent",
    backgroundSize: "auto",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center"
  }),

  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: "white",
      whiteSpace: "pre-wrap",
      "&:hover": { color: isDisabled ? "#dad6d6" : "var(--primary)" },
      cursor: isDisabled ? "not-allowed" : "pointer",

      ":active": {
        ...styles[":active"],
        backgroundColor: "white"
      },
      "&:before": {
        content:
          data.__isNew__ &&
          '"No results? Continue typing or add a custom med below"',
        whiteSpace: data.__isNew__ && "pre",
        color: "#747474",
        borderBottom: "2px solid #edeeef",
        display: data.__isNew__ && "inline-block",
        width: "100%",
        marginBottom: data.__isNew__ && "1rem",
        paddingBottom: data.__isNew__ && "1rem"
      },
      padding: data.__isNew__ ? "16px" : "8px 12px",

      color: getReadOnlyColorStyles({
        data,
        isDisabled,
        isFocused,
        isSelected
      })
    };
  },
  placeholder: (styles, state) => ({
    ...styles,
    color: "#747474",
    fontSize: "16px",
    fontWeight: "400"
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: "#1f2f36",
    ":hover": {
      backgroundColor: "transparent",
      color: "#d73838"
    }
  }),
  singleValue: (styles, { data, isDisabled }) => ({
    ...styles,
    color: "#1f2f36"
  }),

  valueContainer: (styles, { data, isDisabled }) => ({
    ...styles,
    fontSize: "16px"
  })
};

export const groupedColourStyles = {
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none"
  }),
  dropdownIndicator: (styles, state) => ({
    ...styles,
    color: getGroupedColorStyles({ state }),

    "&:hover": { color: "#747474" }
  }),
  control: (styles, state) => ({
    ...styles,
    backgroundColor: state.isDisabled ? "#dad6d6" : "#ffffff",
    borderColor: state.isFocused ? "var(--extra-light-primary)" : "#ffffff",
    borderWidth: "2px",
    "&:hover": { borderColor: "var(--extra-light-primary)" },
    cursor: "pointer",
    boxShadow: "none",
    minWidth: "7rem"
  }),
  menu: (styles) => ({
    ...styles,
    border: "none",
    marginTop: "0",
    boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.16)"
  }),
  menuList: (styles) => ({
    ...styles,
    paddingTop: "8px",
    paddingBottom: "15px"
  }),
  groupHeading: (styles) => ({
    ...styles,
    color: "#1f2f36",
    textTransform: "none",
    fontSize: "14px"
    // fontSize: "14px"
  }),
  noOptionsMessage: (styles) => ({
    ...styles,
    textAlign: "left",
    color: "#1f2f36",
    padding: "30px 16px"
    // fontSize: "14px"
  }),
  group: (styles) => ({
    ...styles,
    fontSize: "100%",
    paddingBottom: "0px"
  }),

  input: (styles) => ({
    ...styles,
    color: "#747474"
  }),
  loadingMessage: (styles) => ({
    ...styles,
    padding: "20px 16px",
    margin: "10px",
    color: "transparent",
    backgroundSize: "auto",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center"
  }),

  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: "white",
      color: getColor({ isDisabled, isFocused, isSelected }),
      "&:hover": { color: "#747474" },
      cursor: isDisabled ? "not-allowed" : "pointer",
      // fontSize: "14px",
      padding: "4px 28px"
      // ":active": {
      //   ...styles[":active"],
      //   backgroundColor: "white"
      // }
    };
  },
  placeholder: (styles) => ({ ...styles, fontWeight: "400" }),
  singleValue: (styles, { data }) => ({ ...styles }),
  valueContainer: (styles, { data }) => ({
    ...styles,
    fontSize: "16px"
  })
};

export const lightColourStyles = {
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none"
  }),
  dropdownIndicator: (styles, state) => ({
    ...styles,
    color: getLightColorStyles({ state }),
    "&:hover": { color: "#747474" }
  }),

  control: (styles, state) => ({
    ...styles,
    backgroundColor: "#ffffff",
    borderColor: state.isFocused ? "var(--extra-light-primary)" : "#dad6d6",
    borderWidth: "2px",
    "&:hover": { color: "#dad6d6" },
    cursor: "pointer",
    boxShadow: "none",
    minWidth: "6rem"
  }),
  menu: (styles) => ({
    ...styles,
    border: "none",
    marginTop: "0",
    boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.16)",
    zIndex: "10"
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: "white",
      color: getColor({ isDisabled, isFocused, isSelected }),
      "&:hover": { color: "#747474" },
      cursor: isDisabled ? "not-allowed" : "pointer",

      ":active": {
        ...styles[":active"],
        backgroundColor: "white"
      }
    };
  },
  input: (styles) => ({ ...styles }),
  placeholder: (styles) => ({ ...styles, fontWeight: "400" }),
  singleValue: (styles, { data }) => ({ ...styles }),
  valueContainer: (styles, { data }) => ({
    ...styles,
    fontSize: "16px"
  })
};
export const lightNoBorderColourStyles = {
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none"
  }),
  dropdownIndicator: (styles, state) => ({
    ...styles,
    color: getColorStyles({ state }),

    "&:hover": { color: "#747474" }
  }),

  control: (styles, state) => ({
    ...styles,
    backgroundColor: "#ffffff",
    borderColor: state.isFocused ? "var(--extra-light-primary)" : "#ffffff",
    borderWidth: "2px",
    "&:hover": { color: "#dad6d6" },
    cursor: "pointer",
    boxShadow: "none",
    minWidth: "6rem"
  }),
  menu: (styles) => ({
    ...styles,
    border: "none",
    marginTop: "0",
    boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.16)",
    zIndex: "10"
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: "white",
      color: getColor({ isDisabled, isFocused, isSelected }),
      "&:hover": { color: "#747474" },
      cursor: isDisabled ? "not-allowed" : "pointer",

      ":active": {
        ...styles[":active"],
        backgroundColor: "white"
      }
    };
  },
  input: (styles) => ({ ...styles }),
  placeholder: (styles) => ({ ...styles, fontWeight: "400" }),
  singleValue: (styles, { data }) => ({ ...styles }),
  valueContainer: (styles, { data }) => ({
    ...styles,
    fontSize: "16px"
  })
};
export const mobileMedFilterColourStyles = {
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none"
  }),
  dropdownIndicator: (styles, state) => ({
    ...styles,
    color: state.isFocused ? "var(--extra-light-primary)" : "#747474"
  }),

  control: (styles, state) => ({
    ...styles,
    backgroundColor: "transparent",
    borderColor: state.isFocused ? "var(--extra-light-primary)" : "#dad6d6",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderRadius: "0px",
    borderWidth: "2px",
    "&:hover": { color: "#dad6d6" },
    cursor: "pointer",
    boxShadow: "none",
    color: "#747474"
  }),
  menu: (styles) => ({
    ...styles,
    border: "none",
    marginTop: "0",
    boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.16)",
    zIndex: 3
  }),
  menuList: (styles) => ({
    ...styles,
    overflowX: "hidden"
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: "white",
      color: getColor({ isDisabled, isFocused, isSelected }),
      // fontSize: "14px",
      "&:hover": { color: "#747474" },
      cursor: isDisabled ? "not-allowed" : "pointer",

      ":active": {
        ...styles[":active"],
        backgroundColor: "white"
      },
      textAlign: "left"
    };
  },
  input: (styles) => ({ ...styles }),
  placeholder: (styles) => ({ ...styles, fontWeight: "400" }),
  singleValue: (styles, { data }) => ({
    ...styles,
    color: "#747474"
    // fontSize: "14px"
  }),
  valueContainer: (styles, { data }) => ({
    ...styles,
    padding: "2px 0",
    flexWrap: "nowrap",
    width: "5rem"
  }),
  indicatorsContainer: (styles, { data }) => ({
    ...styles,
    marginRight: "-8px"
  }),
  multiValue: (styles, { data }) => ({
    ...styles
    // backgroundColor: "transparent"
  })
};

export const clearableFilterColourStyles = {
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none"
  }),
  dropdownIndicator: (styles, state) => ({
    ...styles,
    color: state.isFocused ? "var(--extra-light-primary)" : "#747474",
    display: state.hasValue ? "none" : "flex"
  }),

  control: (styles, state) => ({
    ...styles,
    backgroundColor: "transparent",
    borderColor: state.isFocused ? "var(--extra-light-primary)" : "#dad6d6",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderRadius: "0px",
    borderWidth: "2px",
    "&:hover": { color: "#dad6d6" },
    cursor: "pointer",
    boxShadow: "none",
    color: "#747474"
  }),
  menu: (styles) => ({
    ...styles,
    border: "none",
    marginTop: "0",
    boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.16)",
    zIndex: 3
  }),
  menuList: (styles) => ({
    ...styles,
    overflowX: "hidden"
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: "white",
      color: getColor({ isDisabled, isFocused, isSelected }),
      // fontSize: "14px",
      "&:hover": { color: "#747474" },
      cursor: isDisabled ? "not-allowed" : "pointer",

      ":active": {
        ...styles[":active"],
        backgroundColor: "white"
      },
      textAlign: "left"
    };
  },
  input: (styles) => ({ ...styles }),
  placeholder: (styles) => ({ ...styles, fontWeight: "400" }),
  singleValue: (styles, { data }) => ({
    ...styles,
    color: "#747474"
    // fontSize: "14px"
  }),
  valueContainer: (styles, { data }) => ({
    ...styles,
    padding: "2px 0",
    flexWrap: "nowrap"
  }),
  indicatorsContainer: (styles, { data }) => ({
    ...styles,
    marginRight: "-8px"
  }),
  multiValue: (styles, { data }) => ({
    ...styles
    // backgroundColor: "transparent"
  })
};

export const mobileFilterColourStyles = {
  container: (styles) => ({
    ...styles,
    height: "24px",
    zIndex: "100",
    // marginBottom: "-2rem",
    marginRight: "1rem"
  }),
  valueContainer: (styles) => ({
    ...styles,
    // width: "2rem",
    // right: "1.5rem",
    padding: 0
    // height: "1rem"
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none"
  }),
  dropdownIndicator: (styles, state) => ({
    ...styles,
    display: "none"
  }),

  control: (styles, state) => ({
    ...styles,
    backgroundColor: "transparent",
    borderBottom: "none",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderRadius: "0px",
    borderWidth: "2px",
    "&:hover": { color: "#dad6d6" },
    cursor: "pointer",
    boxShadow: "none",
    color: "#747474",
    minHeight: 0
  }),
  menu: (styles) => ({
    ...styles,
    border: "none",
    marginTop: "0",
    boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.16)",
    zIndex: 3,
    right: 0,
    width: "10rem"
  }),

  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: "white",
      color: getMobileFilterColor({ isDisabled, isSelected, isFocused }),
      // fontSize: "14px",
      "&:hover": { color: "#747474" },
      cursor: isDisabled ? "not-allowed" : "pointer",

      ":active": {
        ...styles[":active"]
      }
    };
  },

  input: (styles) => ({ ...styles, color: "transparent" }),
  placeholder: (styles) => ({
    ...styles
  }),
  singleValue: (styles, { data }) => ({
    ...styles,
    display: "none"
    // fontSize: "14px"
  })
};

export const formInTableColourStyles = {
  dropdownIndicator: (styles, state) => ({
    ...styles,
    color: getGroupedColorStyles({ state }),
    "&:hover": { color: "#747474" }
  }),

  control: (styles, state) => ({
    ...styles,
    backgroundColor: state.isDisabled ? "#dad6d6" : "#f2f2f2",
    borderColor: state.isFocused ? "var(--extra-light-primary)" : "#dad6d6",
    borderWidth: "2px",
    "&:hover": { borderColor: "var(--extra-light-primary)" },
    cursor: "pointer",
    boxShadow: "none"
  }),
  menu: (styles) => ({
    ...styles,
    border: "none",
    marginTop: "0",
    boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.16)",
    zIndex: "1060",
    marginBottom: "1rem"
    // fontSize: ".875rem"
  }),
  menuList: (styles) => ({
    ...styles,
    maxHeight: "160px",
    paddingTop: "8px",
    paddingBottom: "8px"
  }),

  input: (styles, state) => ({
    ...styles,
    color: state.isDisabled ? "#dad6d6" : "#8c8c8c",
    visibility: state.isDisabled ? "hidden" : "visible"
  }),

  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: "white",

      "&:hover": { color: isDisabled ? "#dad6d6" : "var(--primary)" },
      cursor: isDisabled ? "not-allowed" : "pointer",

      ":active": {
        ...styles[":active"],

        backgroundColor: "white"
      },

      color: getFormInTableColor({ isDisabled, isSelected, isFocused })
    };
  },
  placeholder: (styles, state) => ({
    ...styles,
    color: "#747474"
    // fontSize: ".875rem"
  }),

  singleValue: (styles, { data }) => ({ ...styles }),
  valueContainer: (styles, { data }) => ({
    ...styles
    // fontSize: ".875rem"
  })
};

export const workflowColourStyles = {
  dropdownIndicator: (styles, state) => ({
    ...styles,
    color: state.isDisabled ? "#8c8c8c" : "#1f2f36",
    "&:hover": { color: "#8c8c8c" }
  }),

  control: (styles, state) => ({
    ...styles,
    backgroundColor: state.isDisabled ? "#edeeef" : "#ffffff",
    borderColor: state.isFocused ? "var(--extra-light-primary)" : "#dad6d6",
    borderWidth: "2px",
    "&:hover": { color: "#dad6d6" },
    cursor: "pointer",
    boxShadow: "none",
    minWidth: "6.3rem"
  }),
  menu: (styles) => ({
    ...styles,
    border: "none",
    marginTop: "0",
    boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.16)",
    zIndex: "10",
    textAlign: "left"
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: "white",
      color: getColorDatePicker({ isDisabled, isSelected, isFocused }),
      "&:hover": { color: "var(--primary)" },
      cursor: isDisabled ? "not-allowed" : "pointer",

      ":active": {
        ...styles[":active"],
        backgroundColor: "white"
      }
    };
  },
  input: (styles) => ({
    ...styles,
    visibility: "hidden"
  }),
  placeholder: (styles) => ({ ...styles, whiteSpace: "nowrap" }),
  singleValue: (styles, { data }) => ({ ...styles }),
  valueContainer: (styles, { data }) => ({
    ...styles
  }),
  menuList: (styles) => ({ ...styles, overflowX: "hidden" })
};

export const reportsColourStyles = {
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none"
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  dropdownIndicator: (styles, state) => ({
    ...styles,
    color: getColorStyles({ state }),
    "&:hover": { color: "#747474" }
  }),
  clearIndicator: (styles, state) => ({
    ...styles,
    color: "#747474",
    "&:hover": { color: "#d73838" }
  }),

  control: (styles, state) => ({
    ...styles,
    backgroundColor: state.isDisabled ? "#edeeef" : "#ffffff",
    borderColor: state.isFocused
      ? "var(--extra-light-primary)"
      : state.isDisabled
      ? "#dad6d6"
      : "#ffffff",
    borderWidth: "2px",
    "&:hover": { borderColor: "var(--extra-light-primary)" },
    cursor: "pointer",
    boxShadow: "none",
    minWidth: "7rem"
  }),
  menu: (styles) => ({
    ...styles,
    border: "none",
    marginTop: "0",
    boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.16)",
    zIndex: "1060",
    marginBottom: "1rem"
  }),
  menuList: (styles) => ({
    ...styles,
    maxHeight: "160px",
    paddingTop: "8px",
    paddingBottom: "8px"
  }),
  groupHeading: (styles) => ({
    ...styles,
    color: "#1f2f36",
    textTransfom: "unset",
    fontSize: "16px"
    // fontSize: "14px"
  }),
  noOptionsMessage: (styles) => ({
    ...styles,
    textAlign: "left",
    color: "#747474",
    padding: "16px 16px",
    fontSize: "16px"
  }),
  input: (styles, state) => ({
    ...styles,
    // color: "transparent",
    // visibility: "hidden",
    display: "none"
    // marginLeft: "-9999999999em"
  }),
  loadingMessage: (styles) => ({
    ...styles,
    padding: "20px 16px",
    margin: "10px",
    color: "transparent",
    backgroundSize: "auto",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center"
  }),

  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: "white",
      whiteSpace: "pre-wrap",
      // "&:hover": { color: isDisabled ? "#dad6d6" : "var(--secondary)" },
      cursor: isDisabled ? "not-allowed" : "pointer",

      ":active": {
        ...styles[":active"],
        backgroundColor: "white"
      },
      "&:before": {
        content:
          data.__isNew__ &&
          '"No results? Continue typing or add a custom med below"',
        whiteSpace: data.__isNew__ && "pre",
        color: "#747474",
        borderBottom: "2px solid #edeeef",
        display: data.__isNew__ && "inline-block",
        width: "100%",
        marginBottom: data.__isNew__ && "1rem",
        paddingBottom: data.__isNew__ && "1rem"
      },
      padding: data.__isNew__ ? "16px" : "8px 12px",

      color: getColor({ isDisabled, isFocused, isSelected })
    };
  },
  placeholder: (styles, state) => ({
    ...styles,
    color: "#747474",
    fontSize: "16px",
    fontWeight: "400"
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: "#1f2f36",
    ":hover": {
      backgroundColor: "transparent",
      color: "#d73838"
    }
  }),

  singleValue: (styles, { data }) => ({ ...styles }),
  valueContainer: (styles, { data }) => ({
    ...styles,
    fontSize: "16px"
  })
};

export const multiReportsColourStyles = {
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none"
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  dropdownIndicator: (styles, state) => ({
    ...styles,
    color: getColorStyles({ state }),
    "&:hover": { color: "#747474" }
  }),
  clearIndicator: (styles, state) => ({
    ...styles,
    color: "#747474",
    "&:hover": { color: "#d73838" }
  }),

  control: (styles, state) => ({
    ...styles,
    backgroundColor: state.isDisabled ? "#edeeef" : "#ffffff",
    borderColor: state.isFocused
      ? "var(--extra-light-primary)"
      : state.isDisabled
      ? "#dad6d6"
      : "#ffffff",
    borderWidth: "2px",
    "&:hover": { borderColor: "var(--extra-light-primary)" },
    cursor: "pointer",
    boxShadow: "none",
    minWidth: "7rem"
  }),
  menu: (styles) => ({
    ...styles,
    border: "none",
    marginTop: "0",
    boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.16)",
    zIndex: "1060",
    marginBottom: "1rem"
  }),
  menuList: (styles) => ({
    ...styles,
    maxHeight: "160px",
    paddingTop: "8px",
    paddingBottom: "8px"
  }),
  groupHeading: (styles) => ({
    ...styles,
    color: "#1f2f36",
    textTransfom: "unset",
    fontSize: "16px"
    // fontSize: "14px"
  }),
  noOptionsMessage: (styles) => ({
    ...styles,
    textAlign: "left",
    color: "#747474",
    padding: "16px 16px",
    fontSize: "16px"
  }),
  input: (styles, state) => ({
    ...styles,
    // color: "transparent",
    // visibility: "hidden",
    display: "none"
    // marginLeft: "-9999999999em"
  }),
  loadingMessage: (styles) => ({
    ...styles,
    padding: "20px 16px",
    margin: "10px",
    color: "transparent",
    backgroundSize: "auto",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center"
  }),

  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: "white",
      whiteSpace: "pre-wrap",
      // "&:hover": { color: isDisabled ? "#dad6d6" : "var(--secondary)" },
      cursor: isDisabled ? "not-allowed" : "pointer",

      ":active": {
        ...styles[":active"],
        backgroundColor: "white"
      },
      "&:before": {
        content:
          data.__isNew__ &&
          '"No results? Continue typing or add a custom med below"',
        whiteSpace: data.__isNew__ && "pre",
        color: "#747474",
        borderBottom: "2px solid #edeeef",
        display: data.__isNew__ && "inline-block",
        width: "100%",
        marginBottom: data.__isNew__ && "1rem",
        paddingBottom: data.__isNew__ && "1rem"
      },
      padding: data.__isNew__ ? "16px" : "8px 12px",

      color: getColor({ isDisabled, isFocused, isSelected })
    };
  },
  placeholder: (styles, state) => ({
    ...styles,
    color: "#747474",
    fontSize: "16px",
    fontWeight: "400"
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: "#1f2f36",
    ":hover": {
      backgroundColor: "transparent",
      color: "#d73838"
    }
  }),

  singleValue: (styles, { data }) => ({ ...styles }),
  valueContainer: (styles, { data }) => ({
    ...styles,
    fontSize: "16px",
    width: 0
  })
};

export const groupedSelectStyles = {
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none"
  }),
  dropdownIndicator: (styles, state) => ({
    ...styles,
    color: getColorStyles({ state })
  }),
  control: (styles, state) => ({
    ...styles,
    backgroundColor: state.isDisabled ? "#dad6d6" : "#f2f2f2",
    borderColor: state.isFocused ? "var(--extra-light-primary)" : "#dad6d6",
    borderWidth: "2px",
    "&:hover": { borderColorolor: "var(--extra-light-primary)" },
    cursor: "pointer",
    boxShadow: "none",
    minWidth: "7rem"
  }),
  menu: (styles) => ({
    ...styles,
    border: "none",
    marginTop: "0",
    boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.16)",
    width: "200%"
  }),
  menuList: (styles) => ({
    ...styles,
    paddingTop: "8px",
    paddingBottom: "15px",
    maxHeight: "280px"
  }),
  groupHeading: (styles) => ({
    ...styles,
    color: "#1f2f36",
    textTransform: "none",
    fontSize: "14px"
    // fontSize: "14px"
  }),
  noOptionsMessage: (styles) => ({
    ...styles,
    textAlign: "left",
    color: "#747474",
    padding: "0px 0px",
    fontSize: "16px"
  }),
  group: (styles) => ({
    ...styles,
    fontSize: "100%"
  }),

  input: (styles, state) => ({
    ...styles,
    color: state.isDisabled ? "#dad6d6" : "#747474",
    visibility: state.isDisabled ? "hidden" : "visible"
  }),
  loadingMessage: (styles) => ({
    ...styles,
    padding: "20px 16px",
    margin: "10px",
    color: "transparent",
    backgroundSize: "auto",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center"
  }),
  loadingIndicator: (styles) => ({
    display: "none"
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: "white",
      color: getColor({ isDisabled, isFocused, isSelected }),
      "&:hover": { color: isDisabled ? "#ccc" : "#747474" },
      cursor: isDisabled ? "not-allowed" : "pointer",
      // fontSize: "14px",
      padding: "4px 14px",
      ":active": {
        ...styles[":active"]
      }
    };
  },
  placeholder: (styles) => ({
    ...styles,
    color: "#747474",
    fontSize: "16px",
    fontWeight: "400"
  }),
  singleValue: (styles, { data }) => ({ ...styles }),
  valueContainer: (styles, { data }) => ({
    ...styles,
    fontSize: "16px"
  })
};
