import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { Popover, PopoverBody, PopoverHeader } from "reactstrap";
import { CloseButton } from "../_components";
import { UseMobileOrTabletMediaQuery } from "../_helpers/media-queries";
import PropTypes from "prop-types";

const MyPopover = (props) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const {
    target,
    text,
    text1,
    text2,
    title,
    accept,
    reject,
    id,
    placement = "bottom-end",
    dynamicPlacement = false,
    scheduledMeds,
    activeMeds,
    prnMeds,
    description,
    noHeader,
  } = props;

  const [currentPlacement, setCurrentPlacement] = useState(placement);

  useEffect(() => {
    const calculatePlacement = () => {
      const targetElement = document.getElementById(target);
      if (!targetElement) return "bottom-end";

      const { bottom, right } = targetElement.getBoundingClientRect(); //destructuring individual properties
      const viewportHeight = window.innerHeight;
      const viewportWidth = window.innerWidth;

      if (viewportHeight - bottom > 200) return "bottom-end";
      else if (viewportWidth - right > 200) return "right-end";
      else return "auto";
    };
    if (popoverOpen && dynamicPlacement) {
      setCurrentPlacement(calculatePlacement());
    }
  }, [popoverOpen, dynamicPlacement, target]);

  const is_touch_device = "ontouchstart" in window;
  const isMobileOrTabletWidth = UseMobileOrTabletMediaQuery();
  return (
    <Popover
      id={id}
      className="text-left"
      placement={dynamicPlacement ? currentPlacement : placement}
      isOpen={popoverOpen}
      trigger={is_touch_device ? "click" : "hover focus legacy"}
      target={target}
      toggle={() => setPopoverOpen(!popoverOpen)}
    >
      {(!noHeader || isMobileOrTabletWidth) && (
        <PopoverHeader>
          {title}
          {is_touch_device && (
            <CloseButton
              toggle={() => setPopoverOpen(!popoverOpen)}
              color="glyph-white mb-2"
            />
          )}
        </PopoverHeader>
      )}
      <PopoverBody html="true">
        <div>
          {text1 && (
            <div
              className={classnames(
                { "primary-underline": text2 },
                "d-flex justify-content-between py-1"
              )}
            >
              <div className="mr-3">Email:</div>
              <div>{text1}</div>
            </div>
          )}
          {text2 && (
            <div className="d-flex justify-content-between py-1">
              <div className="mr-3">Phone:</div>
              <div>{text2}</div>
            </div>
          )}
          {activeMeds && (
            <div>
              <div className="primary-underline d-flex justify-content-between py-1">
                <div className="mr-3">Active Meds </div>
                <div className="ml-auto">{activeMeds}</div>
              </div>
              <div className="primary-underline d-flex justify-content-between py-1">
                <div className="mr-3">Daily Scheduled Meds </div>
                <div className="ml-auto">{scheduledMeds}</div>
              </div>
              <div className="d-flex justify-content-between py-1">
                <div className="mr-3">PRN Meds</div>
                <div className="ml-auto">{prnMeds}</div>
              </div>
            </div>
          )}
          {Array.isArray(text) ? (
            <div>
              {text.map((item, idx) => {
                if (idx !== text.length - 1) {
                  return (
                    <div className="primary-underline w-100 py-1" key={idx}>
                      {item}
                    </div>
                  );
                } else {
                  return (
                    <div className=" w-100 py-1" key={idx}>
                      {item}
                    </div>
                  );
                }
              })}
            </div>
          ) : (
            <div>
              {description && (
                <div className="primary-underline w-100 mb-1 pb-1 ">
                  Description
                </div>
              )}
              <div> {text}</div>
            </div>
          )}
        </div>
        <div className="d-flex py-1">
          {accept && <div className="mr-4">{accept}</div>}
          {reject && <div>{reject}</div>}
        </div>
      </PopoverBody>
    </Popover>
  );
};

MyPopover.propTypes = {
  target: PropTypes.string,
  text1: PropTypes.string,
  text2: PropTypes.string,
  title: PropTypes.string,
  accept: PropTypes.object,
  id: PropTypes.string,
  placement: PropTypes.string,
  scheduledMeds: PropTypes.string,
  activeMeds: PropTypes.string,
  prnMeds: PropTypes.string,
  description: PropTypes.bool,
  reject: PropTypes.string,
  dynamicPlacement: PropTypes.bool,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.element,
  ]),
  noHeader: PropTypes.bool,
};

export { MyPopover };
