import classnames from "classnames";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import { UseMobileOrTabletMediaQuery } from "../_helpers/media-queries";
import { useUserContext } from "../context/userContext";
import { useAlertContext } from "../context/alertContext";
import { supportService } from "../_services/support.service";
import PropTypes from "prop-types";

const Support = (props) => {
  const {
    title,
    text,
    tag,
    toggle,
    selectedIntegration,
    feature,
    publicVersion
  } = props;
  const { alertMethods } = useAlertContext();
  const is768Width = UseMobileOrTabletMediaQuery();

  const { userState } = useUserContext();
  const { user } = userState;
  const initialValues = {
    email: user?.email ? user.email : "",
    subject: selectedIntegration
      ? `Interest in ${selectedIntegration.label} Integration`
      : feature
      ? `Interest in ${feature}`
      : "",
    description: selectedIntegration
      ? `We are interested in integrating with the ${selectedIntegration.label} system.`
      : feature
      ? `We are interested in adding ${feature} to our organization's package`
      : ""
  };
  const emailRegExp = /^[A-Za-z0-9._+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .matches(emailRegExp, { message: "Email is invalid" })
      .required("Email is required"),
    subject: Yup.string().required(),
    description: Yup.string().required()
  });

  const navigateToLogin =
    '<a href="/account/login" class="btn btn-link">Return to login</a>';

  function onSubmit(
    { email, subject, description },
    { setSubmitting, resetForm }
  ) {
    alertMethods.clear();
    if (title === "Support") {
      supportService
        .beforeLogin(email, subject, description, tag)
        .then(() => {
          setSubmitting(false);
          resetForm();
          if (publicVersion) {
            alertMethods.success(
              `<div>Success! Your request has been submitted. Someone from our team will be in touch shortly`
            );
          } else
            alertMethods.success(
              `<div>Success! Your request has been submitted. Someone from our team will be in touch shortly.<br />
              <span>${navigateToLogin} or submit additional ticket below.</span></div>`
            );
        })
        .catch(() => {
          setSubmitting(false);
          let error = "Please try again.";
          alertMethods.error("<div class=''>An Error Occurred!</div>" + error);
        });
    } else {
      supportService
        .support(email, subject, description, tag)
        .then(() => {
          setSubmitting(false);
          resetForm();
          if (toggle) toggle();
          alertMethods.success(
            "<div class=''>Success!</div>Your request has been submitted. Someone from our team will be in touch shortly."
          );
        })
        .catch(() => {
          setSubmitting(false);
          let error = "Please try again.";
          alertMethods.error("<div class=''>An Error Occurred!</div>" + error);
        });
    }
  }

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ errors, touched, isSubmitting, isValid, dirty, values }) => (
          <div className="d-flex flex-column">
            {
              <Form data-testid="support-form">
                <h3 className="">{title}</h3>
                <p>{text}</p>
                <div className="form-group">
                  <label htmlFor="email">Your Email</label>
                  <Field
                    id="email"
                    placeholder="Please enter your email here"
                    name="email"
                    type="email"
                    autoComplete="username"
                    className={classnames(
                      { "is-invalid": errors.email && touched.email },
                      "form-control"
                    )}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="subject">Subject</label>

                  <Field
                    id="subject"
                    placeholder="What would you like to talk to us about today?"
                    name="subject"
                    type="text"
                    className={classnames(
                      { "is-invalid": errors.subject && touched.subject },
                      "form-control"
                    )}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="description">Description</label>

                  <Field
                    id="description"
                    placeholder="Please provide any details here."
                    name="description"
                    as="textarea"
                    rows="5"
                    type="text"
                    className={classnames(
                      {
                        "is-invalid": errors.description && touched.description
                      },
                      "form-control"
                    )}
                  />
                  <ErrorMessage
                    name="description"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
                <div className="form-row mt-4">
                  <div className="form-group col mt-4">
                    <button
                      type="submit"
                      disabled={
                        isSubmitting ||
                        !isValid ||
                        (tag !== "permissions" &&
                          tag !== "integrations" &&
                          !dirty)
                      }
                      className={classnames(
                        { "w-100 mb-4": is768Width },
                        { "w-25 mr-lg-5": !is768Width },
                        "btn btn-primary"
                      )}
                    >
                      {isSubmitting && (
                        <span className="spinner-border spinner-border-sm mr-1"></span>
                      )}
                      Submit
                    </button>
                    {toggle && (
                      <button
                        type="button"
                        className={classnames(
                          { "w-100": is768Width },
                          "btn btn-link text-secondary"
                        )}
                        onClick={toggle}
                      >
                        Cancel
                      </button>
                    )}
                  </div>
                </div>
              </Form>
            }
          </div>
        )}
      </Formik>
    </div>
  );
};

Support.propTypes = {
  title: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  tag: PropTypes.string,
  toggle: PropTypes.func,
  selectedIntegration: PropTypes.object,
  feature: PropTypes.string,
  publicVersion: PropTypes.bool
};

export { Support };
