import classNames from "classnames";
import React, { useContext, useEffect, useState } from "react";
import Moment from "react-moment";
import PropTypes from "prop-types";
import { Col, Collapse, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { CloseButton, MyTooltip, NewGTable } from "../../../_components";
import {
  changeTypeArray,
  isPRN,
  suggestionSources,
  timesOfDay
} from "../../../_helpers";
import { patientService } from "../../../_services";
import { useAlertContext } from "../../../context/alertContext";
import { PatientContext } from "../../../context/patientContext";
import { ReactComponent as ChevronDown } from "../../../images/chevron_down_small.svg";
import { ReactComponent as ChevronUp } from "../../../images/chevron_up_small.svg";
import { ReactComponent as Resend } from "../../../images/glyph_resend.svg";
import { sigCodes } from "../../../_helpers/Frequency";
import { prnReasons } from "../../../_helpers/prnreasons";
import { CustomInstructionText } from "../../../home/formComponents";
import classnames from "classnames";
import { quantityUnitsEnum } from "../../../_helpers/quantityUnits";

const medicationChangeStatuses = [
  { value: 0, label: "Pending" },
  { value: 1, label: "Accepted" },
  { value: 2, label: "Rejected", class: "text-danger" }
];

const headerFilters = [
  {
    column: "status",
    options: medicationChangeStatuses,
    name: "Status"
  }
];
const findGroupLabel = (sigCodes, optionLabel) => {
  for (const group of sigCodes) {
    for (const option of group.options) {
      if (option.label === optionLabel) {
        if (group.label !== "Other") return group.label;
      }
    }
  }
  return null; // Return null if the option label is not found in any group
};

const findPRN = (val) => {
  return prnReasons
    .flatMap((reason) => reason.options)
    .find((prn) => {
      return prn.value === val;
    });
};

const SuggestedMedInfo = ({ data, title }) => {
  const { medicationSchedule, status } = data;
  const {
    sigCode,
    doseQuantity,
    prnReason,
    prnReasonOther,
    medicationScheduleTimes,
    instructionText,
    quantityUnit,
    quantityUnitId,
    daysOn,
    daysOff,
    totalNumberOfCycles
  } = medicationSchedule;
  const foundPrn = findPRN(prnReason);
  console.log(quantityUnit, quantityUnitId);
  let qu =
    quantityUnit ||
    quantityUnitsEnum.find((unit) => unit.value === quantityUnitId)?.abbr;

  const sortedMedScheduleTimes = medicationScheduleTimes
    ?.filter((dose) => dose.time || dose.doseQuantity)
    .sort((a, b) => {
      const timeA = a.time?.split(":").map(Number) || [24, 0];
      const timeB = b.time?.split(":").map(Number) || [24, 0];

      if (timeA[0] !== timeB[0]) {
        return timeA[0] - timeB[0];
      } else {
        return timeA[1] - timeB[1];
      }
    });

  let cycleStr = "";

  if (sigCode === "Cycle") {
    cycleStr += `${daysOn} days on, ${daysOff} days off`;
    if (totalNumberOfCycles > 0) {
      cycleStr += `, repeat ${totalNumberOfCycles} times`;
    }
  }

  return (
    <Col sm="6">
      <h6 className="font-weight-bold">{title}</h6>
      <p className="mb-0">
        {status === 2 && <div>Paused</div>}
        {sigCode || ""}
        {isPRN(medicationSchedule) && sigCode !== "PRN"
          ? `${sigCode ? ", " : ""}PRN`
          : ""}{" "}
        {foundPrn?.label || prnReasonOther
          ? `(${prnReasonOther || foundPrn?.label})`
          : ""}
      </p>{" "}
      <div>
        {sigCode !== "Cycle"
          ? findGroupLabel(sigCodes, sigCode)
          : `(${cycleStr})`}
        {isPRN(medicationSchedule)
          ? " As Needed"
          : sigCode === "BIDTIW"
          ? ": (Mo-We-Fr)"
          : ""}
        <ul>
          {sortedMedScheduleTimes.map((dose, idx) => (
            <li key={`${dose.time}-${idx}`}>
              Take{" "}
              {dose.doseQuantity || doseQuantity ? (
                <>
                  {" "}
                  {dose.doseQuantity || doseQuantity} {(qu || "").toLowerCase()}
                </>
              ) : (
                <></>
              )}
              {dose.time ? (
                <span>
                  {" "}
                  at{" "}
                  <Moment format="h:mm A">
                    {new Date(`1/1/2020 ${dose.time}`)}
                  </Moment>
                </span>
              ) : dose.partOfDay || dose.partOfDay === 0 ? (
                <>
                  {dose?.partOfDay < 3 ? " in the " : " at "}
                  {
                    timesOfDay.find((item) => item.value === dose.partOfDay)
                      .label
                  }
                </>
              ) : (
                <> (no set time)</>
              )}
            </li>
          ))}
        </ul>
      </div>
      <div>
        Start Date:{" "}
        {medicationSchedule.startDate ? (
          <Moment format="M/D/YYYY">{medicationSchedule.startDate}</Moment>
        ) : (
          <>Not Set</>
        )}
      </div>
      <div>
        End Date:{" "}
        {medicationSchedule.endDate ? (
          <>
            <Moment format="M/D/YYYY">{medicationSchedule.endDate}</Moment>{" "}
          </>
        ) : (
          <>Not Set</>
        )}
      </div>
      <div className="mt-4">
        {instructionText && "Instructions: "}
        <CustomInstructionText instructionText={instructionText} />
      </div>
    </Col>
  );
};

const SuggestedMedsModal = ({
  suggestedMedModal,
  toggleSuggestedMedModal,
  selectedSuggestion,
  changeType
}) => {
  const {
    medicationName,
    data,
    suggestionCreatedDate,
    suggestionSource,
    status,
    rejectedDate,
    rejectionReason
  } = selectedSuggestion;

  return (
    <Modal
      isOpen={suggestedMedModal}
      toggle={toggleSuggestedMedModal}
      className="center-modal modal-md"
    >
      <ModalHeader
        toggle={toggleSuggestedMedModal}
        close={<CloseButton toggle={toggleSuggestedMedModal} />}
      >
        Suggestion details
      </ModalHeader>
      <ModalBody>
        <h6 className="font-weight-bold mb-2">
          {medicationName}, {data.new.medicationStrength.strength}{" "}
          {data.new.medicationStrength.form}
        </h6>
        <div className="mb-1">{changeType?.category}</div>
        <div className={classnames({ "text-danger": status === 2 })}>
          Status:{" "}
          {medicationChangeStatuses.find((st) => st.value === status)?.label}
        </div>
        <Row className="my-4">
          {data.existing && (
            <SuggestedMedInfo data={data.existing} title="Current" />
          )}
          {data.new &&
            !["Pause", "Remove", "Resume"].includes(changeType?.category) && (
              <SuggestedMedInfo data={data.new} title="Suggested" />
            )}
        </Row>
        <div className="mb-1">
          Suggestion Date:{" "}
          <Moment format="M/D/YYYY h:mm A">
            {`${suggestionCreatedDate}Z`}
          </Moment>{" "}
        </div>
        <div className="mb-1">
          Suggestion Source:{" "}
          {
            suggestionSources.find(
              (source) => source.value === suggestionSource
            )?.label
          }
        </div>
        {status === 2 ? (
          <div>
            <div className="mb-1">
              Rejected Date:{" "}
              <Moment format="M/D/YYYY h:mm A">{rejectedDate + "Z"}</Moment>{" "}
            </div>
            <div className="mb-1">Reason: {rejectionReason || "N/A"}</div>
          </div>
        ) : (
          <></>
        )}
      </ModalBody>
    </Modal>
  );
};

const ExistingMedCell = (props) => {
  const { value, row } = props;
  const { original } = row;
  let spaceIdx;
  if (value.indexOf(" ") > -1) {
    spaceIdx = value.indexOf(" ");
  } else spaceIdx = value.length;
  return (
    <div className={classnames({ "overflow-anywhere": spaceIdx > 15 })}>
      <div>{value}</div>
      {original.reasonToChange === 1 ||
      original.reasonToChange === 101 ||
      !original.data?.existing ? (
        <div className="text-secondary">
          {original.data?.new?.medicationStrength?.strength}{" "}
          {original.data?.new?.medicationStrength?.form}
        </div>
      ) : (
        <div className="text-secondary">
          {original.data?.existing?.medicationStrength?.strength}{" "}
          {original.data?.existing?.medicationStrength?.form}
        </div>
      )}
    </div>
  );
};

const StatusCell = ({ value, resendMed, patientId, id }) => {
  const [status, setStatus] = useState({});

  useEffect(() => {
    if (value || value === 0)
      setStatus(
        medicationChangeStatuses.find((status) => status.value === value)
      );
  }, [value]);

  return (
    <div className="d-flex text-nowrap">
      <div id={`resend-tooltip-${id}`} className={status?.class}>
        {status?.label}
        {status?.label === "Rejected" && (
          <>
            <Resend
              aria-label="resend-tooltip"
              tabIndex="0"
              className="ml-2"
              id="resend-glyph"
              onClick={() => {
                resendMed(patientId, id);
              }}
            />
            <MyTooltip
              target={`resend-tooltip-${id}`}
              text="Resend"
              placement="left"
            />
          </>
        )}
      </div>
    </div>
  );
};

const SuggestedMedCell = ({ value, row }) => {
  const { data } = row;
  const {
    new: { medicationStrength, medicationSchedule }
  } = data;
  const { strength, form } = medicationStrength;
  const { sigCode } = medicationSchedule;
  const [changeType, setChangeType] = useState({});
  const [suggestedMedModal, setSuggestedMedModal] = useState(false);

  const toggleSuggestedMedModal = () =>
    setSuggestedMedModal(!suggestedMedModal);

  useEffect(() => {
    if (value || value === 0)
      setChangeType(changeTypeArray.find((reason) => reason.value === value));
  }, [value]);

  return (
    <div className="word-break-normal">
      {changeType?.category}{" "}
      {changeType?.category === "Change Strength" && (
        <>
          to
          <div className="text-secondary">
            {strength} {form}
          </div>
        </>
      )}
      {changeType?.category === "Change Schedule" && (
        <>
          to
          <div className="text-secondary">
            {sigCode}
            {isPRN(medicationSchedule) && sigCode !== "PRN" ? ", PRN" : ""}
          </div>
        </>
      )}
      <div>
        {changeType?.category !== "No Change" ? (
          <button
            className="btn btn-link p-0"
            type="button"
            onClick={toggleSuggestedMedModal}
          >
            View
          </button>
        ) : (
          <></>
        )}
      </div>
      <SuggestedMedsModal
        suggestedMedModal={suggestedMedModal}
        toggleSuggestedMedModal={toggleSuggestedMedModal}
        selectedSuggestion={row}
        changeType={changeType}
      />
    </div>
  );
};

export const SuggestedMeds = ({
  version,
  dataChange,
  setDataChange,
  fetchSuggestedChanges,
  suggestedMedFilterApplied
}) => {
  const { alertMethods } = useAlertContext();
  const { state } = useContext(PatientContext);
  const {
    suggestedMeds,
    patientId,
    loadingSuggestedChanges,
    suggestedMedCount,
    suggestedMedPages
  } = state;

  const [isSuggestedMedsOpens, setIsSuggestedMedsOpens] = useState(!!version);
  const medlistTableErrorMsg = "No matching records found";

  const mounted = React.useRef(false);

  useEffect(() => {
    mounted.current = true; // Will set it to true on mount ...
    return () => {
      mounted.current = false;
    }; // ... and to false on unmount
  }, []);
  const toggleSuggestedMeds = () =>
    setIsSuggestedMedsOpens(!isSuggestedMedsOpens);

  const resendMed = React.useCallback(
    (id, medId) => {
      patientService
        .resendSuggestedMed(id, medId)
        .then(() => {
          setTimeout(() => {
            setDataChange(!dataChange);
          }, 10000);

          alertMethods.success(
            "<div class=''>Success!</div>Suggested change has been resent to patient"
          );
          //Wait for backend to update, then update suggested meds
        })
        .catch(() => {
          alertMethods.error(
            "<div class=''>An Error Occurred!</div>Please try again"
          );
        });
    },
    [alertMethods, dataChange, setDataChange]
  );

  const suggestedChangesColumns = React.useMemo(
    () => [
      {
        Header: "Date",
        accessor: "suggestionCreatedDate",
        Cell: (props) => {
          return (
            <Moment className="text-nowrap" format="M/D/YYYY">
              {`${props.value}Z`}
            </Moment>
          );
        }
      },
      {
        Header: "Existing Med",
        canGroupBy: true,
        accessor: "medicationName",
        Cell: (props) => {
          return <ExistingMedCell {...props} />;
        }
      },
      {
        Header: "Suggestion",
        accessor: "reasonToChange",
        canGroupBy: false,
        Cell: (props) => {
          return (
            <SuggestedMedCell value={props.value} row={props.row.original} />
          );
        }
      },
      {
        Header: "Status",
        canGroupBy: false,
        accessor: "status",
        Cell: (props) => {
          return (
            <StatusCell
              value={props.value}
              id={props.row.original.id}
              patientId={patientId}
              resendMed={resendMed}
            />
          );
        }
      }
    ],
    [patientId, resendMed]
  );
  return (
    <>
      {/* {suggestedMeds?.length > 0 && ( */}
      <div
        className={classNames(
          { "patient-alert-card card": !version },
          {
            "d-none":
              !suggestedMeds?.length &&
              !loadingSuggestedChanges &&
              !suggestedMedFilterApplied
          }
        )}
      >
        <div className={classNames({ "card-body": !version }, "px-0")}>
          {loadingSuggestedChanges &&
          suggestedMedCount < 1 &&
          !suggestedMedFilterApplied ? (
            <div className="ph-item border-0 px-5">
              <div className="ph-col-12">
                <div className="ph-row">
                  <div className="ph-col-6 big"></div>
                  <div className="ph-col-half empty"></div>
                  <div className="ph-col-10 big"></div>
                  <div className="ph-col-half empty"></div>
                  <div className="ph-col-4 big"></div>
                </div>
              </div>
            </div>
          ) : (
            <>
              {!version && (
                <span className="card-title px-6 d-flex">
                  Suggested Med Changes
                </span>
              )}
              <p className="mt-2 px-6">
                We’ve sent a medication update to the patient’s mobile app for
                their review. They can accept or reject the change in the mobile
                app.
              </p>
            </>
          )}
          <Collapse isOpen={isSuggestedMedsOpens}>
            <div className="suggested-meds-table">
              <NewGTable
                columns={suggestedChangesColumns}
                data={suggestedMeds}
                loading={loadingSuggestedChanges}
                emptyMsg={medlistTableErrorMsg}
                fetchData={fetchSuggestedChanges}
                pageCount={suggestedMedPages}
                totalCount={suggestedMedCount}
                noSearch={true}
                headerFilters={headerFilters}
                emptyColSpan={4}
                dataChange={dataChange}
                onCard={true}
                defaultPageSize={5}
              />
            </div>
          </Collapse>
          {!version && !loadingSuggestedChanges && suggestedMedCount > 0 ? (
            <>
              <button
                tabIndex="0"
                className="btn btn-link  px-6"
                onClick={toggleSuggestedMeds}
              >
                {isSuggestedMedsOpens && (
                  <span>
                    Close
                    <ChevronUp
                      aria-label="chevron up"
                      className="glyph-link-blue ml-1"
                    />
                  </span>
                )}
                {!isSuggestedMedsOpens && (
                  <span>
                    Review
                    <ChevronDown
                      aria-label="chevron down"
                      className="glyph-link-blue ml-1"
                    />
                  </span>
                )}
              </button>{" "}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      {/* )} */}
    </>
  );
};

SuggestedMedInfo.propTypes = {
  data: PropTypes.object,
  title: PropTypes.string
};

SuggestedMedsModal.propTypes = {
  suggestedMedModal: PropTypes.bool,
  toggleSuggestedMedModal: PropTypes.func,
  selectedSuggestion: PropTypes.object,
  changeType: PropTypes.object
};

ExistingMedCell.propTypes = {
  value: PropTypes.string,
  row: PropTypes.object
};

StatusCell.propTypes = {
  value: PropTypes.number,
  resendMed: PropTypes.func,
  patientId: PropTypes.string,
  id: PropTypes.string
};

SuggestedMedCell.propTypes = {
  value: PropTypes.number,
  id: PropTypes.string,
  row: PropTypes.object
};

SuggestedMeds.propTypes = {
  version: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  dataChange: PropTypes.bool,
  setDataChange: PropTypes.func,
  fetchSuggestedChanges: PropTypes.func,
  suggestedMedFilterApplied: PropTypes.bool
};
