import classnames from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import {
  AdhSummary,
  AdherencePlot,
  CustomMedPopover,
  LoadingCard,
  MyStaticTable,
  NewPatientAlert,
  findColor
} from "../../_components";
import { hideDuplicateIds } from "../../_helpers";
import {
  Use1280MediaQuery,
  UseMobileMediaQuery,
  UseTabletMediaQuery
} from "../../_helpers/media-queries";
import { PatientContext } from "../../context/patientContext";
import { useUserContext } from "../../context/userContext";
import { ReactComponent as Caution } from "../../images/caution.svg";
import {
  HealthTrackerCard,
  MyCircularProgressbar,
  NoUpdatesCard,
  OutreachCard,
  TaskCard
} from "./overviewComponents";
import PropTypes from "prop-types";
import { dummyMedClaims } from "./DummyClaimsData";
import { QualityCard } from "./overviewComponents/QualitySummary";
const headerTabs = [
  { name: "Claims", id: "claims" },
  { name: "Mobile App", id: "mobileApp" }
];

const SummaryCards = ({
  isNewPatient,
  updatesLoading,
  healthTrackers,
  outreach,
  incompleteTasks,
  healthTrackerFeatureEnabled,
  outreachFeatureEnabled,
  taskFeatureEnabled,
  patientExists
}) => {
  return (
    <>
      <NewPatient isNewPatient={isNewPatient} />
      {!updatesLoading ? (
        <>
          {healthTrackers?.length && healthTrackerFeatureEnabled ? (
            <HealthTrackerCard healthTrackers={healthTrackers} />
          ) : (
            <></>
          )}
          {outreach?.length && outreachFeatureEnabled ? (
            <OutreachCard outreach={outreach} />
          ) : (
            <></>
          )}
          {incompleteTasks?.length && taskFeatureEnabled ? (
            <TaskCard tasks={incompleteTasks} />
          ) : (
            <></>
          )}
          {((!healthTrackers.length && healthTrackerFeatureEnabled) ||
            (!outreach.length && outreachFeatureEnabled) ||
            (!incompleteTasks?.length && taskFeatureEnabled)) &&
          patientExists ? (
            <NoUpdatesCard
              hideHealthTrackers={
                healthTrackers.length || !healthTrackerFeatureEnabled
              }
              hideOutreach={outreach.length || !outreachFeatureEnabled}
              hideTasks={incompleteTasks?.length || !taskFeatureEnabled}
            />
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          <LoadingCard header="Health Tracker Summary" />
          <LoadingCard header="Last Outreach" />
          <LoadingCard header="Task Summary" />
        </>
      )}
    </>
  );
};

SummaryCards.propTypes = {
  isNewPatient: PropTypes.bool,
  updatesLoading: PropTypes.bool,
  healthTrackers: PropTypes.array,
  outreach: PropTypes.array,
  incompleteTasks: PropTypes.array,
  healthTrackerFeatureEnabled: PropTypes.bool,
  outreachFeatureEnabled: PropTypes.bool,
  taskFeatureEnabled: PropTypes.bool,
  patientExists: PropTypes.bool
};

const NewPatient = ({ isNewPatient }) => {
  return (
    <>
      {isNewPatient && (
        <div className="card patient-alert-card">
          <div className="card-body">
            <div className="d-flex">
              <Caution className="mr-4 glyph-warning" aria-label="caution" />
              <span className="card-title">User for Less than a Week</span>
            </div>
            <p className="mt-2">
              This user joined less than a week ago. Some items may not be an
              accurate indicator of adherence yet.
            </p>
          </div>
        </div>
      )}
    </>
  );
};
NewPatient.propTypes = {
  isNewPatient: PropTypes.bool
};

const CustomMedCell = (props) => {
  return (
    <div>
      {props.value ? (
        <div className="overview-glyph">
          <CustomMedPopover id={`overview${props.row.original.id}`} />
        </div>
      ) : (
        <div>&nbsp;&nbsp;</div>
      )}
    </div>
  );
};

CustomMedCell.propTypes = {
  value: PropTypes.bool,
  row: PropTypes.object
};

const ActiveMedsCell = ({ value, medicationStrength }) => {
  const { strength, form } = medicationStrength || {};
  return (
    <div className="">
      <span>
        {value}
        {strength || form ? (
          <>
            {", "}
            {strength} {form}
          </>
        ) : (
          <></>
        )}
      </span>
    </div>
  );
};
ActiveMedsCell.propTypes = {
  value: PropTypes.string,
  medicationStrength: PropTypes.object
};

const AdhCells = ({ value }) => {
  return <>{value || 0}%</>;
};
AdhCells.propTypes = {
  value: PropTypes.number
};

const TabOverview = ({
  patientId,
  isNewPatient,
  goToTab,
  loading,
  patientExists,
  activeTab,
  loadingMedications,
  chartRef,
  activeOption,
  setActiveOption,
  healthTrackerFeatureEnabled,
  outreachFeatureEnabled,
  taskFeatureEnabled,
  range,
  handleAdherenceSummaryFilterChange,
  toggleModal,
  incompleteTasks
}) => {
  const { state } = useContext(PatientContext);
  const {
    medications,
    adhSummary,
    adherence,
    healthTrackers,
    outreach,
    loadingHealthTrackers,
    loadingTasks,
    loadingOutreach
  } = state;
  const { userState } = useUserContext();
  const { user, tenantFeatures } = userState;
  const highContrast =
    document.body.getAttribute("data-theme") === "high-contrast";
  const is1280Width = Use1280MediaQuery();
  const is768Width = UseTabletMediaQuery();
  const isMobileWidth = UseMobileMediaQuery();
  const [updatesLoading, setUpdatesLoading] = useState(true);
  const [data, setData] = useState([]);
  const [activeHeaderTab, setActiveHeaderTab] = useState(0);

  const setMedData = React.useCallback(
    (type) => {
      if (type === "claims") {
        setData(dummyMedClaims);
      } else {
        setData(medications.filter((med) => med.status === 0));
      }
    },
    [medications]
  );
  const mounted = React.useRef(false);

  React.useEffect(() => {
    mounted.current = true; // Will set it to true on mount ...
    return () => {
      mounted.current = false;
    }; // ... and to false on unmount
  }, []);

  useEffect(() => {
    setMedData(tenantFeatures?.PayorPages ? "claims" : "mobileApp");
  }, [setMedData, tenantFeatures?.PayorPages]);

  useEffect(() => {
    if (loadingOutreach || loadingTasks || loadingHealthTrackers)
      setUpdatesLoading(true);
    else setUpdatesLoading(false);
  }, [loadingOutreach, loadingTasks, loadingHealthTrackers]);

  const activeMedicationsColumns = React.useMemo(
    () => [
      {
        Header: "",
        accessor: "isCustomMedication",
        Cell: (props) => {
          return <CustomMedCell {...props} />;
        }
      },
      {
        Header: () => <>{is768Width ? "Active Meds" : "Active Medications"}</>,
        accessor: "medicationName",
        Cell: (props) => {
          return (
            <ActiveMedsCell
              value={props.value}
              medicationStrength={props.row.original.medicationStrength}
            />
          );
        }
      }
      // {
      //   Header: "In EHR",
      //   accessor: "isInEhr",
      //   Cell: (props) => {
      //     return <EHRCell {...props} />;
      //   }
      // }
    ],
    [is768Width]
  );

  const adhColumns = React.useMemo(
    () => [
      {
        Header: "Medication",
        accessor: "name",
        Cell: (props) => {
          return (
            <div>
              {props.value}, {props.row.original.strength}
            </div>
          );
        }
      },
      {
        Header: "Adherence",
        accessor: "overallAdherencePercentage",
        Cell: (props) => {
          return <AdhCells value={props.value} />;
        }
      }
    ],
    []
  );

  // Render the UI for your table
  return (
    <div>
      <div>
        <Row>
          <Col xl="3" md="5">
            {isMobileWidth && (
              <div
                className={classnames(
                  { "alert-border": isNewPatient },
                  "card bg-white"
                )}
              >
                {<NewPatientAlert id={"tabO-7"} isNewPatient={isNewPatient} />}
                <div className="row card-body">
                  <Col sm="8" className="pl-sm-6">
                    <AdhSummary
                      patientId={patientId}
                      adherence={adhSummary}
                      isNewPatient={isNewPatient}
                      loadingAdhrence={loading}
                      handleAdherenceSummaryFilterChange={
                        handleAdherenceSummaryFilterChange
                      }
                      version="overview"
                      range={range}
                      activeOption={activeOption}
                      setActiveOption={setActiveOption}
                    />
                  </Col>
                  <Col sm="4" className="border-left mt-6">
                    <div className="d-flex flex-column align-items-center px-4 px-lg-7 px-xl-4">
                      <MyCircularProgressbar
                        average={adhSummary.percent}
                        color={
                          adhSummary.percent
                            ? findColor(adhSummary.percent, highContrast)
                            : "#edeeef"
                        }
                      />
                    </div>
                  </Col>
                </div>
                <div id="mobile-table">
                  <MyStaticTable
                    columns={adhColumns}
                    data={hideDuplicateIds(adherence)}
                    loading={loading}
                    emptyColSpan={3}
                    errorMsg="Nothing to see here! This patient has no adherence history in the selected time."
                  />
                </div>
              </div>
            )}
            {tenantFeatures?.PayorPages && <QualityCard />}
            <div className="card bg-white">
              <div className="card-body p-0 overview-table">
                <MyStaticTable
                  columns={activeMedicationsColumns}
                  activeTab={activeHeaderTab}
                  setActiveTab={setActiveHeaderTab}
                  smartColumnsToHide={["isInEhr"]}
                  headerTabs={tenantFeatures?.PayorPages ? headerTabs : null}
                  data={data}
                  setMedData={setMedData}
                  loading={loadingMedications}
                  dummyClaimsData={dummyMedClaims}
                  emptyColSpan={3}
                  errorMsg="Nothing to see here! This patient has no adherence history in the selected time."
                />
                <div className="d-flex justify-content-center text-center">
                  {!user?.isSmartSession && data !== dummyMedClaims && (
                    <button
                      className="btn btn-link  pt-1 pb-5"
                      onClick={() => {
                        toggleModal();
                        const btn = document.getElementById("add-medication");
                        btn.blur();
                      }}
                      id="add-medication"
                    >
                      + Add New Med
                    </button>
                  )}
                  {user?.isSmartSession && (
                    <button
                      className="btn btn-link pt-1 pb-5"
                      onClick={() => goToTab(1)}
                    >
                      Go to Med List
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div></div>
            {(is1280Width || is768Width) && (
              <SummaryCards
                incompleteTasks={incompleteTasks}
                isNewPatient={isNewPatient}
                updatesLoading={updatesLoading}
                healthTrackers={healthTrackers}
                outreach={outreach}
                healthTrackerFeatureEnabled={healthTrackerFeatureEnabled}
                outreachFeatureEnabled={outreachFeatureEnabled}
                taskFeatureEnabled={taskFeatureEnabled}
                patientExists={patientExists}
              />
            )}
          </Col>
          {!isMobileWidth && (
            <Col xl="6" md="7">
              <div
                className={classnames(
                  { "alert-border": isNewPatient },
                  "card bg-white"
                )}
              >
                {<NewPatientAlert id={"tabO-7"} isNewPatient={isNewPatient} />}
                <div className="card-body px-0">
                  {(medications.length > 0 || loading) && (
                    <AdherencePlot
                      isNewPatient={isNewPatient}
                      activeTab={activeTab}
                      patientId={patientId}
                      adhSummary={adhSummary}
                      loadingAdhrence={loading}
                      handleAdherenceSummaryFilterChange={
                        handleAdherenceSummaryFilterChange
                      }
                      range={range}
                      chartRef={chartRef}
                      activeOption={activeOption}
                      setActiveOption={setActiveOption}
                    />
                  )}

                  {!loading && !medications.length && (
                    <div>
                      This patient has no medications. Add a medication to show
                      adherence data.
                    </div>
                  )}
                </div>
                {medications.length > 0 && (
                  <div className="card-footer bg-white border-0">
                    <p className="extra-small text-secondary mb-0 mt-0">
                      *Inactive Meds include paused and deleted medications.
                      Adherence Charts do not include PRN meds.
                    </p>
                  </div>
                )}
              </div>
            </Col>
          )}
          {!is1280Width && !is768Width && (
            <Col xl="3" md="5" className="overflow-hidden">
              <SummaryCards
                incompleteTasks={incompleteTasks}
                isNewPatient={isNewPatient}
                updatesLoading={updatesLoading}
                healthTrackers={healthTrackers}
                outreach={outreach}
                healthTrackerFeatureEnabled={healthTrackerFeatureEnabled}
                outreachFeatureEnabled={outreachFeatureEnabled}
                taskFeatureEnabled={taskFeatureEnabled}
                patientExists={patientExists}
              />
            </Col>
          )}
        </Row>
      </div>
    </div>
  );
};
TabOverview.propTypes = {
  patientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isNewPatient: PropTypes.bool,
  goToTab: PropTypes.func,
  loading: PropTypes.bool,
  patientExists: PropTypes.bool,
  activeTab: PropTypes.number,
  loadingMedications: PropTypes.bool,
  chartRef: PropTypes.object,
  activeOption: PropTypes.string,
  setActiveOption: PropTypes.func,
  healthTrackerFeatureEnabled: PropTypes.bool,
  outreachFeatureEnabled: PropTypes.bool,
  taskFeatureEnabled: PropTypes.bool,
  range: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  handleAdherenceSummaryFilterChange: PropTypes.func,
  toggleModal: PropTypes.func
};

export { TabOverview };
