import moment from "moment";
import { taskTypeOptions } from "./constants";
import { healthTrackersEnum } from "./enums";

export function formatPhoneNumber(phoneNumberString) {
  let cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  let match = /^(1)?(\d{3})(\d{3})(\d{4})$/.exec(cleaned);
  if (match) {
    return ["(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
}

export function displayDob(date) {
  let dob = new Date(date);
  let userTimezoneOffset = dob.getTimezoneOffset() * 60000;
  return moment(dob.getTime() + userTimezoneOffset).format("M/D/YYYY");
}

export function convertUTCTimeToLocalTime(utcTime, twentyfourhour) {
  let hour = Number(utcTime?.slice(0, 2));
  let date = new moment.utc();
  date.set({
    hour: hour,
    minute: Number(utcTime?.slice(3, 5)),
    second: 0,
    millisecond: 0
  });
  let res;
  if (twentyfourhour) res = date.local().format("HH:mm");
  else res = date.local().format("h:mm A");
  return res;
}

export function convertLocalTimeToUTCTime(localTime) {
  let hour = Number(localTime.slice(0, 2));
  let min = Number(localTime.slice(3, 5));
  let date = moment().local();
  date.set({
    hour: hour,
    minute: min,
    second: 0,
    millisecond: 0
  });
  let res = date.utc().format("HH:mm");
  return res;
}

export function convertUTCDateTimeToLocalDateTime(date, time) {
  // Create a new Date object from the provided UTC date string
  let utcDate = new Date(date);
  // Extract the hours, minutes, and seconds from the time string
  const [hours, minutes, seconds] = time.split(":").map(Number);

  // Add the time components to the UTC date to get the local date and time
  utcDate.setUTCHours(utcDate.getUTCHours() + hours);
  utcDate.setUTCMinutes(utcDate.getUTCMinutes() + minutes);
  utcDate.setUTCSeconds(utcDate.getUTCSeconds() + seconds);

  // Create a new Date object using the ISO 8601 string representation of the UTC date
  let isoDate = new Date(utcDate.toISOString());
  // Return the local date as a string
  return isoDate;
}

export function toTitleCase(str) {
  return str?.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
  });
}

export const formatTime = (str) => {
  if (str) {
    let amPm = "AM";
    let minutes = str.slice(3, 5);
    let hour = str.slice(0, 2);

    if (Number(hour) === 12) {
      amPm = "PM";
    } else if (Number(hour) > 12) {
      hour = Number(hour) - 12;
      amPm = "PM";
    } else if (hour < 10) {
      hour = hour.slice(1, 2);
    }
    return `${hour}:${minutes} ${amPm}`;
  } else return "";
};

export function makeUSNumber(num) {
  if (num?.startsWith("+1")) return num;
  else if (num) return "+1" + num;
  else return "";
}

export function separateInstructionText(instructionText) {
  let idx = instructionText?.indexOf(";");
  if (idx < 0) {
    if (
      instructionText === "With Food" ||
      instructionText === "Before Food" ||
      instructionText === "After Food"
    )
      idx = instructionText.length;
  }
  return idx;
}
export const filterMeds = (inputValue, options) => {
  const medOptions = options.filter((op) => {
    return op.label.toLowerCase().includes(inputValue.toLowerCase());
  });
  return medOptions;
};

export const cantSubmitInvite = (values) => {
  return (
    (!values.phoneCheckbox &&
      !values.emailCheckbox &&
      !values.neitherCheckbox) ||
    (values.phoneCheckbox && !values.phoneNumber && !values.emailCheckbox) ||
    (values.emailCheckbox && !values.email && !values.phoneCheckbox)
  );
};

export function formatFilterValue(choice) {
  let fieldArray;
  if (!Array.isArray(choice)) {
    fieldArray = [choice];
  } else fieldArray = choice;

  // if the fields.choice[idx] is not an array, make it one

  return fieldArray.map((item) => {
    if (item.value || item.value === 0 || item.value === false) {
      return item.value;
    } else return item;
  });
}

function hexToRgb(hex, format) {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null;
}

export function getRGB(hex) {
  const r = parseInt(hex.substring(1, 3), 16);
  const g = parseInt(hex.substring(3, 5), 16);
  const b = parseInt(hex.substring(5, 7), 16);
  return `${r}, ${g}, ${b}`;
}
function luminance(r, g, b) {
  const a = [r, g, b].map(function (v) {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

export function calculateRatio(brandColor, white) {
  const brandColorRgb = hexToRgb(brandColor);
  const whiteColorRgb = hexToRgb(white);
  const brandColorLuminance = luminance(
    brandColorRgb.r,
    brandColorRgb.g,
    brandColorRgb.b
  );
  const whiteLuminance = luminance(
    whiteColorRgb.r,
    whiteColorRgb.g,
    whiteColorRgb.b
  );
  return (brandColorLuminance + 0.05) / (whiteLuminance + 0.05);
}

export function adjust(color, amount) {
  return (
    "#" +
    color.replace(/^#/, "").replace(/../g, (color) =>
      Math.min(255, Math.max(0, parseInt(color, 16) + amount))
        .toString(16)
        .padStart(2, "0")
        .slice(-2)
    )
  );
}

export const createAdherence = (params) => {
  let medTaken = 0;
  let medSkipped = 0;
  let medMissed = 0;
  let percentageTaken = 0;
  params.forEach((element) => {
    medTaken = medTaken + element.medicationsTaken;
    medSkipped = medSkipped + element.medicationsSkipped;
    medMissed = medMissed + element.medicationsMissed;
  });
  percentageTaken = medTaken / (medTaken + medSkipped + medMissed);
  const adh = {
    percent: Number.isNaN(percentageTaken)
      ? 0
      : Math.floor(Math.round(percentageTaken * 100)),
    taken: medTaken,
    skipped: medSkipped,
    missed: medMissed
  };
  return adh;
};

export function allAccess(permissions) {
  if (permissions?.indexOf(1000) > -1) return true;
}

export function fahrenheitToCelsius(fahrenheit) {
  const celsius = ((fahrenheit - 32) * 5) / 9;
  return celsius.toFixed(1);
}

export function convertMgPerDlToMmolPerLiter(glucoseMgPerDl) {
  const glucoseMmolPerLiter = glucoseMgPerDl / 18.01559;
  return glucoseMmolPerLiter.toFixed(1);
}

export function convertLbsToKgs(lbs) {
  const kgs = lbs / 2.2046;
  return kgs.toFixed(1);
}

export function deleteAllCookies() {
  if (!process.env.JEST_WORKER_ID) {
    let cookies = document.cookie.split(";");

    for (const element of cookies) {
      let cookie = element;
      let eqPos = cookie.indexOf("=");
      let name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }
}

// hide duplicate data
export function hideDuplicateIds(data) {
  const uniqueIds = {};

  const filteredData = data.filter((item) => {
    if (!uniqueIds[item.id]) {
      uniqueIds[item.id] = true;
      return true;
    }
    return false;
  });
  return filteredData;
}

export function clearLocalStorage() {
  deleteAllCookies();
  localStorage.setItem("CREDENTIALS_FLUSH", "true");

  localStorage.removeItem("addMedsList");
  localStorage.removeItem("numberOfMeds");
  // remove firstName
  localStorage.removeItem("firstName");
  // remove lastName
  localStorage.removeItem("lastName");
  // remove email
  localStorage.removeItem("patient-email");
  // remove phoneNumber
  localStorage.removeItem("phoneNumber");
  // remove dateOfBirth
  localStorage.removeItem("dateOfBirth");
  // remove gender
  localStorage.removeItem("gender");
  localStorage.removeItem("CREDENTIALS_FLUSH");
  localStorage.removeItem("LOGIN");
  sessionStorage.removeItem("user");
}

export function clearSmartTokens() {
  localStorage.removeItem("smartToken");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("refreshTokenExpiresAt");
}

export function isValidDate(dateStr) {
  if (dateStr && typeof dateStr === "string") {
    const yearPart = dateStr.substring(0, 4); // Get the first 4 characters (year part)
    return yearPart && yearPart !== "0001";
  } else if (dateStr) return true;
}

export const findType = (type) => {
  let found = taskTypeOptions.find((el) => el.value === type);
  return found?.label;
};

export function getTrackerInfo(no) {
  return healthTrackersEnum.find((tracker) => tracker.id === no);
}

export const valuesByType = (healthTrackersLog, name) => {
  return healthTrackersLog.reduce((result, log) => {
    let editedResult;
    log.healthTrackersLogDetails.forEach((detail) => {
      if (!result[detail.valueTypeDescription]) {
        result[detail.valueTypeDescription] = [];
      }
      result[detail.valueTypeDescription].push({
        logId: log.id,
        logDate: new Date(log.logDate).toLocaleDateString("en-US", {
          month: "numeric",
          day: "numeric"
        }),
        date: new Date(log.logDate),
        value: detail.value,
        notes: log.notes
      });
    });
    if (name === "Cholesterol") {
      editedResult = {
        HDL: result["HDL"],
        LDL: result["LDL"],
        Triglycerides: result["Triglycerides"],
        "Total Cholesterol": result["Total Cholesterol"]
      };
    }
    return name === "Cholesterol" ? editedResult : result;
  }, {});
};

export const year = new Date().getFullYear();

export const isPRN = (medicationSchedule) => {
  const { prnReason, prnReasonOther } = medicationSchedule;
  return (
    prnReason ||
    prnReason === 0 ||
    prnReasonOther ||
    medicationSchedule?.scheduleType === 1
  );
};

export function getOrdinalNumber(index) {
  const ordinals = [
    "First",
    "Second",
    "Third",
    "Fourth",
    "Fifth",
    "Sixth",
    "Seventh",
    "Eighth",
    "Ninth",
    "Tenth"
  ];

  if (index >= 0 && index < ordinals.length) {
    return ordinals[index];
  } else {
    return "Invalid index";
  }
}

export function deepCopy(obj) {
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  if (Array.isArray(obj)) {
    const arrCopy = [];
    for (let i = 0; i < obj.length; i++) {
      arrCopy[i] = deepCopy(obj[i]);
    }
    return arrCopy;
  }

  const objCopy = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      objCopy[key] = deepCopy(obj[key]);
    }
  }

  return objCopy;
}

export function findAndMoveToCommon(valueSet, sourceArray, commonGroup) {
  return sourceArray.reduce((acc, group) => {
    const foundIndex = group.options.findIndex((option) =>
      valueSet.has(option.value)
    );

    if (foundIndex !== -1) {
      const foundOption = group.options.splice(foundIndex, 1);
      commonGroup.options.push(foundOption[0]);
    }

    acc.push(group);
    return acc;
  }, []);
}

export function findDuplicates(meds, setTableMeds, setHasDuplicates) {
  const rxcuiMap = {};
  if (meds) {
    let dupeExists = false;
    setTableMeds(
      meds.map((med) => {
        const { medicationStrength, medicationApprovalStatus } = med;
        //  if medicationApprovalStatus is 2, do not include it in the rxcuiMap
        if (medicationApprovalStatus === 2) {
          med.isDuplicate = false;
          return med;
        } else {
          const { rxcui } = medicationStrength;
          if (rxcui) {
            // If rxcui is already present in the map, mark both current item and the first instance as duplicates
            if (rxcuiMap[rxcui] || rxcuiMap[rxcui] === 0) {
              med.isDuplicate = true;
              dupeExists = true;
              meds[rxcuiMap[rxcui]].isDuplicate = true;
            } else {
              med.isDuplicate = false;
              if (meds[rxcuiMap[rxcui]])
                meds[rxcuiMap[rxcui]].isDuplicate = false;
              // Otherwise, add the rxcui to the map with the index of the current item
              rxcuiMap[rxcui] = meds.indexOf(med);
            }
          }
          return med;
        }
      })
    );
    setHasDuplicates(dupeExists);
  }
}

export const createCriteriaValidationMessage = (value) => {
  if (Array.isArray(value)) {
    if (value.length === 2 && value.some((val) => typeof val === "string")) {
      if (!value.every((val) => val !== null && val !== undefined)) {
        return "Both fields are required.";
      } else if (
        !value.every((val) => Number(val) >= 0 && Number(val) <= 120)
      ) {
        return "Values must be between 0 and 120.";
      } else if (Number(value[0]) >= Number(value[1])) {
        return "First value should be less than second value.";
      }
    } else {
      if (
        value.length > 0 &&
        !value.every((val) => val !== null && val !== undefined)
      ) {
        return "Both fields are required.";
      }
    }
  } else if (value === null || value === "" || value === undefined) {
    return "This field is required.";
  } else if (Number(value) && (Number(value) < 0 || Number(value) > 120)) {
    return "Value must be between 0 and 120.";
  }
  return true;
};

export const createCriteriaValidatonTest = (value) => {
  if (Array.isArray(value)) {
    if (value.length === 2 && value.some((val) => typeof val === "string")) {
      // hack to get around the fact that impossible to check if both fields are touched and setting to empty string sets to undefined
      if (value[0] === "not set" || value[1] === "not set") {
        return true;
      } else
        return (
          value.every(
            (val) =>
              val !== null &&
              val !== undefined &&
              Number(val) >= 0 &&
              Number(val) <= 120
          ) && Number(value[0]) < Number(value[1])
        );
    } else {
      return (
        value.length > 0 &&
        value.every((val) => val !== null && val !== "" && val !== undefined)
      );
    }
  } else {
    if (Number(value) && (Number(value) < 0 || Number(value) > 120))
      return false;
    return value !== null && value !== "" && value !== undefined;
  }
};

export const getTrimmedString = (str = "") =>
  str
    .trim()
    .split(" ")
    .filter((item) => item)
    .join(" ");

export const isIOS = () => {
  const userAgent = window.navigator.userAgent;
  return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
};

const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
export const isIE = msie > 0 || !!/Trident.*rv:11\./.exec(navigator.userAgent);
