import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import PropTypes from "prop-types";

const ExcelViewer = ({ byteArray, numberOfFilters }) => {
  const [excelData, setExcelData] = useState([]);
  const [headerInfo, setHeaderInfo] = useState([]);
  const [subHeaderInfo, setSubHeaderInfo] = useState([]); // [headerRow, subHeaderRow
  const [headerRow, setHeaderRow] = useState([]);
  const [noData, setNoData] = useState(false); // [headerRow, subHeaderRow

  const parseExcel = (blob) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      import("xlsx").then((XLSX) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0]; // Assuming we want data from the first sheet
        const worksheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(worksheet, {
          header: 1,
          blankrows: false,
          defval: "N/A"
        });

        setHeaderInfo(parsedData.slice(0, 1));
        setSubHeaderInfo(parsedData.slice(1, numberOfFilters + 1));
        if (parsedData.length > numberOfFilters + 1)
          setHeaderRow(parsedData[numberOfFilters + 1]);
        if (parsedData.length > numberOfFilters + 2)
          setExcelData(parsedData.slice(numberOfFilters + 2));
        else setNoData(true);
      });
    };
    reader.readAsArrayBuffer(blob);
  };

  // Trigger parsing when byteArray changes
  useEffect(() => {
    if (byteArray) {
      const blob = new Blob([byteArray], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      });
      parseExcel(blob);
    }
  }, [byteArray]);

  return (
    <div className="excel-table">
      {headerInfo.map((row, rowIndex) => (
        <div key={`${row?.length}-${rowIndex}`}>
          {row.map((cell, cellIndex) => {
            const key = `${rowIndex}-${cellIndex}`;
            if (cell !== "N/A") return <h3 key={key}>{cell}</h3>;
            else return <span key={key}> </span>;
          })}
        </div>
      ))}
      {subHeaderInfo.map((row, rowIndex) => (
        <div key={`${row?.length}-${rowIndex}`}>
          {row.map((cell, cellIndex) => {
            const key = `${rowIndex}-${cellIndex}`;
            if (cell !== "N/A") return <h6 key={key}>{cell}</h6>;
            else return <span key={key}> </span>;
          })}
        </div>
      ))}
      {noData && <p className="mt-4">No data found to match the criteria</p>}
      <Table>
        <thead>
          <tr>
            {headerRow.map((cell, cellIndex) => {
              const key = `${headerRow?.length}-${cellIndex}`;
              if (cell !== "N/A")
                return (
                  <th key={key} className="">
                    {cell}
                  </th>
                );
              else return <th key={key}> </th>;
            })}
          </tr>
        </thead>
        <tbody>
          {excelData.map((row, rowIndex) => (
            <tr key={`${row?.length}-${rowIndex}`}>
              {row.map((cell, cellIndex) => {
                const key = `${rowIndex}-${cellIndex}`;
                return (
                  <td key={key} className="word-break-normal">
                    {cell}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

ExcelViewer.propTypes = {
  byteArray: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  numberOfFilters: PropTypes.number
};

export { ExcelViewer };
